// import {} from '@tma.js/sdk'
import Vue, { Ref, getCurrentInstance } from 'vue';
// import { TonConnectUI } from '@tonconnect/ui';
import { generateUUID } from '@/common/utils';

interface Window {
    google?: any;
    dataLayer?: any[];
}

let tgInviteLink: string = 'https://t.me/+CP084ol7tohmYmFl';

export const isTMA = () => {
    const locationHash = location?.hash?.toString() || '';
    return locationHash.includes('tgWebAppData');
};

// 页面挂载执行
export const initTg = (onloaded: () => void) => {
    // const tgScript = document.head.querySelector('script[src="https://telegram.org/js/telegram-web-app.js"]') as HTMLScriptElement;
    if (!isTMA()) {
        return;
    }
    const scriptAllLoaded = () => {
        const { ready } = useTelegram();
        ready();
        onloaded();
        console.log('tg webApp: ', 'tg script is loaded');
    };

    const tgScript = document.createElement('script');
    tgScript.src = 'https://telegram.org/js/telegram-web-app.js';
    // const tonconectScript = document.createElement('script');
    // tonconectScript.src = 'https://unpkg.com/@tonconnect/ui@latest/dist/tonconnect-ui.min.js';

    document.head.appendChild(tgScript);
    // document.head.appendChild(tonconectScript);
    if ((window as any).Telegram !== undefined) {
        const { ready } = useTelegram();
        ready();
        onloaded();
        console.log('tg webApp: ', 'ts script loaded when initialization');
        return;
    }
    let tgScriptLoaded = false;
    const tonconectScriptLoaded = false;
    tgScript.onload = () => {
        tgScriptLoaded = true;
        // if (tonconectScriptLoaded) {
        //     scriptAllLoaded();
        // }
        scriptAllLoaded();
    };
    // tonconectScript.onload = () => {
    //     tonconectScriptLoaded = true;
    //     if (tgScriptLoaded) {
    //         scriptAllLoaded();
    //     }
    // };
};

export const tgNetworkHelper = () => {
    const { proxy } = getCurrentInstance() as { proxy: any };
    const tgWebAppStartup = async () => {
        const { user } = useTelegram();
        if (!user?.id) {
            console.log('tg webApp: ', 'upload ts startup but user-id is nil');
            return;
        }
        const { result, errorMsg, data } = await proxy.$axios.$$post('overseaAPI::/rest/o/w/pwa/tg/startup', {
            telegramUid: user?.id || '',
            // telegramUid: "5007755080"
        });

        if (result === 1) {
            if (data?.telegramGroupInviteLink) {
                tgInviteLink = data?.telegramGroupInviteLink;
            }
            console.log('tg webApp: ', 'startup interface success');
        }
    };

    const tgPraiseAmountList = async () => {
        console.log('tg webApp: ', 'tg fetch praise amount list');
        await proxy.$store.dispatch('initTgPraiseAmounts', { $axios: proxy.$axios, route: proxy.$route });
    };

    const createTgWalletOrder = async (amount: string) => {
        const { user, openTelegramLink } = useTelegram();
        // curl -X POST -H "Wpay-Store-Api-Key: SbKrJeCONjgU9jI7TC4L6dtU7qUpWINVBXdz" -H "Content-Type: application/json" -H "Accept:application/json" -d '{"amount":{"amount":"0.1","currencyCode":"USD"},"autoConversionCurrency":"USDT","externalId":"te","timeoutSeconds":10800,"description":"VPN for 1 month","returnUrl":"https://t.me/wallet","failReturnUrl":"https://t.me/wallet","customData":"unknown","customerTelegramUserId":"5007755080"}' https://pay.wallet.tg/wpay/store-api/v1/order
        const headers = {
            // 'Wpay-Store-Api-Key': walletKey,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };
        const bodyData = {
            amount: { amount, currencyCode: 'USD' },
            autoConversionCurrency: 'USDT',
            externalId: `ORD-${generateUUID()}`,
            timeoutSeconds: 10800,
            description: 'Create a wallet order',
            customData: 'unknown',
            customerTelegramUserId: user?.id,
        };

        // https://pay.wallet.tg/wpay/store-api/v1/orde 实际由服务端请求这个接口
        const { status, message, data } = await proxy.$axios.$$post('overseaAPI::/rest/o/group/tg/order/create', bodyData, { headers });
        console.log('tg webApp: ', `create tg order result: ${status}${message}${data}`);
        if (status === 'SUCCESS') {
            if (data?.directPayLink) {
                openTelegramLink(data?.directPayLink);
            }
        }
    };

    const tonapiExchangeUSDToTon = async (usd: string) => {
        console.log('tg webApp: ', 'tonapiExchange USD To Ton');
        const { rates } = await proxy.$axios.$$get('https://tonapi.io/v2/rates?tokens=usd&currencies=ton,rub');
        if (rates?.USD?.prices?.TON) {
            return (parseFloat(rates.USD.prices.TON) * parseFloat(usd) * 1000000000).toFixed(0);
        }
    };

    /*
    curl -X POST https://api.telegram.org/bot7672511021:AAFEnAhXfxQbIk53tUjZmaWvJJAu4dJBBBE/createInvoiceLink \
    -d "title=Praise" \
    -d "description=Praise%20creator" \
    -d "payload=123e4567-e89b-12d3-a456-426614174000" \
    -d "provider_token=7672511021:AAFEnAhXfxQbIk53tUjZmaWvJJAu4dJBBBE" \
    -d "currency=XTR" \
    -d "prices=[{\"label\":\"商品1\",\"amount\":1}]"
    */
    const createTgInvoiceLink = async (amount: number) => {
        const { user } = useTelegram();
        // curl -X POST -H "Wpay-Store-Api-Key: SbKrJeCONjgU9jI7TC4L6dtU7qUpWINVBXdz" -H "Content-Type: application/json" -H "Accept:application/json" -d '{"amount":{"amount":"0.1","currencyCode":"USD"},"autoConversionCurrency":"USDT","externalId":"te","timeoutSeconds":10800,"description":"VPN for 1 month","returnUrl":"https://t.me/wallet","failReturnUrl":"https://t.me/wallet","customData":"unknown","customerTelegramUserId":"5007755080"}' https://pay.wallet.tg/wpay/store-api/v1/order
        const headers = {
            // 'Wpay-Store-Api-Key': walletKey,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };
        const payload = generateUUID();
        const prices = [{ label: '商品', amount }];
        const bodyData = {
            title: 'Praise',
            description: 'Praise creator',
            payload,
            currency: 'XTR',
            prices,
            customData: 'unknown',
            customerTelegramUserId: user?.id,
        };

        // https://api.telegram.org/bot{token}/createInvoiceLink 实际由服务端请求这个接口
        const { result } = await proxy.$axios.$$post('overseaAPI::/rest/o/group/tg/invoice/create', bodyData, { headers });
        console.log('tg webApp: ', `create tg invoice result: ${result}`);
        // res：{"ok":true,"result":"https://t.me/$UEoM2GBE8VV6AQAARPPem4y6fHs"}
        return result;
    };

    return { tgWebAppStartup, createTgWalletOrder, tgPraiseAmountList, tonapiExchangeUSDToTon, createTgInvoiceLink };
};

const parseJsonSatefy = <T = unknown>(str?: string) => {
    try {
        if (typeof str !== 'string') {
            return undefined;
        }
        return JSON.parse(str) as T;
    } catch {
        return undefined;
    }
};

// tg快捷实例
export const useTelegram = () => {
    const webApp = (window as any).Telegram?.WebApp;
    const webView = (window as any).Telegram?.WebView;
    console.log('tg webApp:  \n', `webApp${JSON.stringify(webApp)}\n webView${JSON.stringify(webView)}`);

    let user = webApp?.initDataUnsafe?.user;
    if (user === undefined || user === null) {
        // note: %7B%22id%22%3A5007755080%2C%22first_name%22%3A%22Qi%22%2C%22last_name%22%3A%22Xin%22%2C%22language_code%22%3A%22zh-hans%22%2C%22allows_write_to_pm%22%3Atrue%7D
        if (webView?.initParams?.user) {
            const userStr = decodeURIComponent(webView?.initParams?.user);
            if (userStr !== undefined) {
                user = parseJsonSatefy<string>(userStr);
                console.log('tg webApp: ', `parse user info from webview: ${userStr}`);
            }
        }
    }
    /**
     * Informs the Telegram app that the Web App is ready to be displayed.
     */
    const ready = () => {
        webApp?.ready();
        webApp?.expand();
        webApp?.enableClosingConfirmation();
    };

    const openLink = (url: string) => {
        console.log('tg webApp: ', 'Telegram webapp Open URL: {url}');
        webApp?.openLink(url, { try_instant_view: true });
    };

    const openTelegramLink = (url: string) => {
        console.log('tg webApp: ', 'Telegram webapp Open Telegram URL: {url}');
        webApp?.openTelegramLink(url);
    };

    const openGroup = () => {
        console.log('tg webApp: ', `tglog openGroup${tgInviteLink}`);
        webApp?.openTelegramLink(tgInviteLink);
    };

    return {
        user,
        isTMA,
        ready,
        openLink,
        openTelegramLink,
        openGroup,
        parseJsonSatefy,
    };
};

let TonConnectUI_: any;
export const tonConnectUI = async () => {
    if (TonConnectUI_) {
        return TonConnectUI_;
    }
    const { TonConnectUI } = await import('@tonconnect/ui');
    TonConnectUI_ = new TonConnectUI({
        manifestUrl: `${window.location.origin}/tonconnect-manifest.json`,
        // buttonRootId: 'connectWalletId',
    });
    TonConnectUI_.uiOptions = { actionsConfiguration: { twaReturnUrl: 'https://t.me/KwaiPro_Bot' } };
    return TonConnectUI_ as typeof TonConnectUI;
};
