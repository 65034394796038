/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Module } from 'vuex';

export type KConfMoudleType = {
    kConf?: Record<string, any>;
};

const defaultKConfModule: KConfMoudleType = {
    kConf: {},
};

export const kConfModule: Module<KConfMoudleType, any> = {
    namespaced: true,
    state: defaultKConfModule,
    actions: {},
    mutations: {
        setKConf(state, data: Record<string, any>) {
            state.kConf = {
                ...(state.kConf || {}),
                ...(data || {}),
            };
        },
    },
};
