
import { radarLog } from '@/common/radar';
import { callApp } from '@/common/call-app';
import { initDeviceFingerprint } from '@/common/layout-common';
import { getOneLinkUrl, getTargetUrlFromWebOrDesktop, isTiny } from '@/common/utils';

export default {
    components: {},
    layout: 'default',
    // eslint-disable-next-line vue/require-prop-types
    props: ['error'],
    head() {
        return {};
    },
    computed: {
        // 计算属性的 getter
        openKwaiText() {
            return (this as any).$root.$t('k_344289').replace('${0}', (this as any).$appName);
        },
    },
    mounted: () => {
        radarLog({
            name: 'PWA_SSR_ERROR_PAGE',
            event_type: 'show',
            category: isTiny() ? 'KWAI_TINY' : 'KWAI_SHARE',
            message: `PWA SSR error页面`,
            src: window.location.pathname,
        });
        initDeviceFingerprint();
    },
    methods: {
        handleConfirm: () => {
            if (isTiny()) {
                return;
            }
            const callAppPath = 'home';

            const targetUrl = getTargetUrlFromWebOrDesktop();

            const oneLinkUrl = getOneLinkUrl(targetUrl);

            callApp({
                path: `${callAppPath}?target_url=${targetUrl}`,
                onelink: oneLinkUrl,
            });
        },
    },
};
