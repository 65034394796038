import { UIAIStore } from '../stores/UIAIStore';
import { AIMessageModel, AIConversationID, AIMessageID, AIUserID, AIMessageType } from './AIModel';

export class AIMessageModelImpl<K = any> implements AIMessageModel<K> {
    id: AIMessageID;

    conversationId: AIConversationID;

    senderId: AIUserID;

    content: string;

    messageType: AIMessageType;

    timestamp: Date;

    attachment?: K;

    constructor(id: string, conversationId: string, senderId: string, content: string, messageType: AIMessageType, timestamp: Date, attachment: K) {
        this.id = id;
        this.conversationId = conversationId;
        this.senderId = senderId;
        this.content = content;
        this.messageType = messageType;
        this.timestamp = timestamp;
        this.attachment = attachment;
    }

    async deleteMessage(): Promise<boolean> {
        await UIAIStore.chatStore.deleteMessage(this);
        console.log('social-ai-im', `Deleted message  with ID: ${this.id}`);
        return true;
    }

    getAttachment(): K {
        return this.attachment as K;
    }

    getMessageContent(): string {
        return this.content;
    }

    getSenderId(): string {
        return this.senderId;
    }
}

export default AIMessageModelImpl;
