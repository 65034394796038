// eslint-disable-next-line import/no-extraneous-dependencies
import { Module } from 'vuex';

export enum AIBotGenderType {
    Male = 1,
    Female = 2,
    Nonbinary = 3,
}

export enum PermissionType {
    Public = 1,
    Private = 2,
}

export interface AICreateMoudleType {
    avatar?: string;
    gender?: AIBotGenderType;
    name?: string;
    intro?: string;
    greeting?: string;
    permission?: PermissionType;
    background?: string;
    agreePP?: boolean;
    selectedTags?: Array<{ tagId: string; tagCategory: string; tagName: string }>;

    pushCreatePage?: boolean;
}

const defaultState: () => AICreateMoudleType = () => ({
    avatar: '',
    gender: AIBotGenderType.Male,
    name: '',
    intro: '',
    greeting: '',
    permission: PermissionType.Public,
    background: '',
    agreePP: true,
    selectedTags: [],
    pushCreatePage: false,
});

export const aiCreateMoudle: Module<AICreateMoudleType, any> = {
    namespaced: true,
    state: defaultState,
    mutations: {
        setAvatar(state, data) {
            state.avatar = data;
        },
        setName(state, data) {
            state.name = data;
        },
        setGender(state, data) {
            state.gender = data;
        },
        setIntro(state, data) {
            state.intro = data;
        },
        setGreeting(state, data) {
            state.greeting = data;
        },
        setPermission(state, data) {
            state.permission = data;
        },
        setBackground(state, data) {
            state.background = data;
        },
        setAgreePP(state, data) {
            state.agreePP = data;
        },
        setSelectedTags(state, data) {
            state.selectedTags = data;
        },
        setClear(state) {
            Object.assign(state, defaultState());
        },
        setPushCreatePage(state, data) {
            state.pushCreatePage = data;
        },
    },
    actions: {},
};
