export enum AIMessageType {
    TEXT,
    IMAGE,
    AUDIO,
}

export type AIConversationID = string;

export type AIMessageID = string;

export type AIUserID = string;

export interface AIConversationModel<T = any> {
    id: AIConversationID;
    participants: AIUserID[];
    // eslint-disable-next-line no-use-before-define
    lastMessage?: AIMessageModel;
    updatedAt: Date;
    attachment?: T;
    getAttachment(): T;

    // eslint-disable-next-line no-use-before-define
    getLastMessage(): AIMessageModel<any> | undefined;
    getParticipants(): AIUserID[];
}

export interface AIMessageModel<T = any> {
    id: AIMessageID;
    conversationId: AIConversationID;
    senderId: AIUserID;
    content: string;
    messageType: AIMessageType;
    timestamp: Date;
    attachment?: T;
    getAttachment(): T;

    getMessageContent(): string;
    getSenderId(): string;
}

export interface AIUserModel<T = any> {
    id: AIUserID;
    name: string;
    createdAt: Date;
    like: boolean;
    attachment?: T;
    getAttachment(): T;
}
