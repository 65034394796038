// eslint-disable-next-line import/no-extraneous-dependencies
import { Module } from 'vuex';
import { AIFeed, AIFeedLiked } from '@/api/ai-feed';

export enum AIGenderType {
    female = 'Female',
    male = 'Male',
    all = 'All',
}

export enum AIFeedPageEnum {
    AIForyou = 'ai-foryou',
    AIInbox = 'ai-inbox',
    AIChatRoom = 'ai-chatroom',
    AIDiscover = 'ai-discover',
    AIChatBigCard = 'ai-card-guide',
    AIHistory = 'ai-history',
}

export type AIFeedListOriginType = Partial<Record<AIFeedPageEnum, Array<AIFeed>>>;

interface AIFeedMoudleType {
    feedListOrigin?: AIFeedListOriginType;
    isMuted?: boolean;
    hasServerFetchedData?: boolean;
    currentFeed?: AIFeed;
    genderPrefer?: AIGenderType;
}

const defaultState: () => AIFeedMoudleType = () => ({
    feedListOrigin: {
        [AIFeedPageEnum.AIForyou]: [],
        [AIFeedPageEnum.AIChatRoom]: [],
    },
    isMuted: true,
    hasServerFetchedData: false, // 标识是否在 node 端请求过数据，如果请求过数据，则不在 feedList 组件的 onMounted 中重新请求数据
    genderPrefer: AIGenderType.all,
});

export const aiFeedMoudle: Module<AIFeedMoudleType, any> = {
    namespaced: true,
    state: defaultState,
    mutations: {
        setFeedList(state, newFeedOrigin: AIFeedListOriginType) {
            const newFeedListOrigin = Object.keys(newFeedOrigin || {}).reduce<AIFeedListOriginType>((res, currentKey) => {
                const existingFeeds = state?.feedListOrigin?.[currentKey as AIFeedPageEnum] || [];
                const newFeeds = (newFeedOrigin[currentKey as AIFeedPageEnum] || []).map(feed => ({
                    ...feed,
                    liked: AIFeedLiked.UnLike, // 添加 like 属性，默认值为 false
                    index: 0,
                }));
                return {
                    ...res,
                    [currentKey]: [...existingFeeds, ...newFeeds],
                };
            }, {});

            state.feedListOrigin = {
                ...state.feedListOrigin,
                ...newFeedListOrigin,
            };
        },

        resetFeedList(state, feedPage?: AIFeedPageEnum) {
            if (!feedPage) {
                const _state = defaultState();
                state.feedListOrigin = _state.feedListOrigin;
                return;
            }
            state.feedListOrigin = {
                ...state.feedListOrigin,
                [feedPage]: [],
            };
        },

        setMuted(state, isMuted?: boolean) {
            state.isMuted = isMuted;
        },

        setHasServerFetchedData(state, isFetched?: boolean) {
            state.hasServerFetchedData = !!isFetched;
        },

        setCurrentFeed(state, currentFeed?: AIFeed) {
            state.currentFeed = currentFeed;
        },
        setGenderPrefer(state, genderPrefer?: AIGenderType) {
            state.genderPrefer = genderPrefer;
        },
    },
    actions: {},
};
