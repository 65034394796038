import cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { Context } from '@nuxt/types';
import { getCurrentInstance } from 'vue';
import { isTMA } from '../hooks/useTelegram';
import { radarLog } from './radar';
import { checkSnack } from '@/common/seo';
import { getKpn } from '@/common/common-params';
import { SafetyLocalStorage } from '@/common/storage';

// share项目
const UNIQUE_ID_COOKIE_KEY = 'web_uq';
const P_STR_COOKIE_KEY = 'web_pstr';
// 生成onelink 和targetUrl时，url中没有share_id时的默认的值
const defaultShareId = 'ANDROID_e0f3886421c986d7_1649757887990';

export const getQuery = function () {
    const url = location.search; // 获取url中"?"符后的字串
    const theRequest = Object.create(null);
    if (url.includes('?')) {
        const str = url.substr(1);
        const strs = str.split('&');
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
        }
    }
    return theRequest;
};

export const getVideoClipType = (videoWidth: number, videoHeight: number): number => {
    if (videoWidth === 0 && videoHeight === 0) {
        return 0; // 全屏cover
    }
    if (typeof window !== 'undefined') {
        const h = videoHeight / (videoWidth / window.innerWidth);
        if (h >= window.innerHeight) {
            return 0; // 全屏cover
        }
        if (h >= window.innerHeight - 90 * 2) {
            return 1; // 切底cover
        }
        return 2; // 全屏contain
    }
    return 2;
};

// 将http转换称https  不让转了 废掉这个方法的转换逻辑
export const formatUrls = function (url: string) {
    const regex = /(http):\/\/(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})/;
    if (url?.startsWith('http:') && regex.test(url)) {
        radarLog({
            name: 'HTTP_IP_URLS',
            event_type: 'pwa',
            category: 'KWAI_SHARE',
            message: `${url}`,
            src: window.location.pathname,
        });
        return url;
    }
    // if (url?.startsWith('http:')) {
    //   return url.replace(/http:/, 'https:');
    // }
    return url;
};

export function toFixedWithOutRounding(val: number, n: number = 1) {
    const re = new RegExp(`^\\d+(?:\\.\\d{0,${n}})?`);

    return Number(val.toString().match(re));
}
// <10000 正常展示  10000<=x <1000000    >= 100万 m单位  10亿 b 不四舍五入
export function formateNum(num: number, fixedNumber?: number) {
    if (num >= 1000000000) {
        return `${toFixedWithOutRounding(num / 1000000000, fixedNumber)}B`;
    }
    if (num >= 1000000) {
        return `${toFixedWithOutRounding(num / 1000000, fixedNumber)}M`;
    }
    if (num >= 1000) {
        return `${toFixedWithOutRounding(num / 1000, fixedNumber)}K`;
    }
    return num || 0;
}
// 带四舍五入的
export function roundingNum(num: number, reserveNum = 2, toFixedNumber = 1) {
    if (num >= 1000000000) {
        return `${Number(toFixedWithOutRounding(num / 1000000000, reserveNum).toFixed(toFixedNumber))}B`;
    }
    if (num >= 1000000) {
        return `${Number(toFixedWithOutRounding(num / 1000000, reserveNum).toFixed(toFixedNumber))}M`;
    }
    if (num >= 1000) {
        return `${Number(toFixedWithOutRounding(num / 1000, reserveNum).toFixed(toFixedNumber))}K`;
    }
    return num || 0;
}

export function isPwa() {
    const { pwa_source } = getQuery();
    return ['fullscreen'].some(displayMode => window.matchMedia(`(display-mode: ${displayMode})`).matches) || pwa_source === 'desktop';
}

export function genCheckPwaParams() {
    const { pwa_source } = getQuery();
    return {
        fullscreen: window.matchMedia('(display-mode: fullscreen)').matches,
        standalone: window.matchMedia('(display-mode: standalone)').matches,
        'minimal-ui': window.matchMedia('(display-mode: minimal-ui)').matches,
        pwa_source: pwa_source === 'desktop',
    };
}

export function getNetworkType() {
    let connection: any;
    if ('connection' in navigator) {
        connection = navigator.connection;
    } else if ('mozConnection' in navigator) {
        connection = navigator.mozConnection;
    } else if ('webkitConnection' in navigator) {
        connection = navigator.webkitConnection;
    }
    return connection?.type || connection?.effectiveType;
}

/**
 * 获取ikwai协议的target url
 * @param href
 * @returns
 */
export const getTargetUrl = (href: string, targetUrlExtraParams?: Record<string, any>) => {
    const { share_id } = getQuery();
    const deviceId = (cookies.get('_did') || '') as string;
    const uId = (cookies.get(UNIQUE_ID_COOKIE_KEY) || '') as string;
    const pstrId = (cookies.get(P_STR_COOKIE_KEY) || '') as string;

    const shareId = share_id || defaultShareId;
    genFirstReferrer();
    const { referrer, fReferrer } = getReferrers();

    let url = href;
    let p = '&';
    if (!href.includes('?')) {
        p = '?';
    }
    url = `${href + p}web_did=${deviceId}`;

    if (uId) {
        url += `&web_uq=${uId}`;
    }
    if (pstrId) {
        url += `&web_pstr=${pstrId}`;
    }

    if (!url.includes('share_id')) {
        url += `&share_id=${shareId}`;
    }

    if (referrer) {
        url += `&referrer=${encodeURIComponent(referrer)}`;
    }

    if (fReferrer) {
        url += `&fReferrer=${encodeURIComponent(fReferrer)}`;
    }

    // 额外的那些参数拼接到targeturl
    if (targetUrlExtraParams) {
        const extraParams = [];
        for (const p in targetUrlExtraParams) {
            // eslint-disable-next-line no-prototype-builtins
            if (targetUrlExtraParams.hasOwnProperty(p) && targetUrlExtraParams[p]) {
                extraParams.push(`${p}=${encodeURIComponent(targetUrlExtraParams[p])}`);
            }
        }
        url += `&${extraParams.join('&')}`;
    }
    return encodeURIComponent(url);
};

export const getTargetUrlFromWebOrDesktop = (targetUrlExtraParams?: Record<string, any>) => {
    const isPwaOpen = isPwa();
    const { href } = window.location;
    let targetUrl;
    if (isPwaOpen) {
        // href 为当前拉app的链接
        const savehref = `${href}&customPullAppType=pwa_desktop&pwa_install_source=${SafetyLocalStorage.getItem('cachePwaSource')}`;
        targetUrl = getTargetUrl(savehref, targetUrlExtraParams);
    } else {
        targetUrl = getTargetUrl(href, targetUrlExtraParams);
    }
    return targetUrl;
};

export const getTgTargetUrl = (targetUrlExtraParams?: Record<string, any>) => {
    const isPwaOpen = isPwa();
    const { href } = window.location;
    const hrefURL = new URL(href);
    hrefURL.hash = '';
    const tgHref = hrefURL.toString();
    let targetUrl;
    if (isPwaOpen) {
        // href 为当前拉app的链接
        const savehref = `${tgHref}&customPullAppType=pwa_desktop&pwa_install_source=${SafetyLocalStorage.getItem('cachePwaSource')}`;
        targetUrl = getTargetUrl(savehref, targetUrlExtraParams);
    } else {
        targetUrl = getTargetUrl(tgHref, targetUrlExtraParams);
    }
    return targetUrl;
};

export const generateUUID = () => {
    return uuidv4();
};

// 没有就生成 有就拿值
// export const genWebDidAndSessionId = () => {
//   let webDid = SafetyLocalStorage.getItem('webDid');
//   if (!webDid) {
//     webDid = generateUUID();
//     SafetyLocalStorage.setItem('webDid', webDid);
//   }
//   cookies.set('webDid', webDid)
//   cookies.set('did', webDid)
//   // 每次打开设置sessionId
//   let sessionId = sessionStorage.getItem('sessionId');
//   if (!sessionId) {
//     sessionId = generateUUID();
//     sessionStorage.setItem('sessionId', sessionId);
//   }
//   cookies.set('sessionId', sessionId)
//   return {
//     webDid,
//     sessionId
//   }
// };
export const genWebDidAndSessionId = () => {
    let webDid = SafetyLocalStorage.getItem('webDid') || cookies.get('webDid');

    if (!webDid) {
        webDid = generateUUID();
        SafetyLocalStorage.setItem('webDid', webDid);
    }

    cookies.set('webDid', webDid, { expires: 365 });
    const { $isAppEnvironment } = getCurrentInstance()?.proxy || {};
    if (!isTiny() && !$isAppEnvironment) {
        cookies.set('did', webDid, { expires: 365 });
    }

    // 每次打开设置sessionId
    let sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
        sessionId = generateUUID();
        sessionStorage.setItem('sessionId', sessionId);
    }
    cookies.set('sessionId', sessionId);
    return {
        webDid,
        sessionId,
    };
};

// 记录以session维度的第一次进入页面时的referrer
export const genFirstReferrer = () => {
    let fReferrer = sessionStorage.getItem('fReferrer');
    if (fReferrer) return;
    fReferrer = document?.referrer || 'null';
    sessionStorage.setItem('fReferrer', fReferrer);
};

export const getReferrers = () => {
    return {
        fReferrer: sessionStorage.getItem('fReferrer'),
        referrer: document?.referrer,
    };
};

export const getOneLinkUrl = (targetUrl: string, callAppPath?: string) => {
    const { pwa_source, share_id } = getQuery();

    let oneLinkUrl = 'https://go.onelink.me/iGO2';

    const { isSnack } = checkSnack();

    const kpn = getKpn({ bucket: sessionStorage.getItem('bucket') || '', host: window.location.host });

    // todo 后续要条件是否为kwai-me
    if (isSnack) {
        oneLinkUrl = 'https://sharefor2.onelink.me/Z4b7';
    }

    if (kpn === 'KWAI.ME') {
        oneLinkUrl = 'https://kwaime.onelink.me/V16i';
    }

    const af_sub1 = JSON.stringify({
        shareId: share_id || defaultShareId,
        shareInfo: {
            target_url: targetUrl,
        },
    });
    let oneLink = `${oneLinkUrl}?pid=kwai_pwa&af_sub1=${af_sub1}&af_channel=${pwa_source}`;

    if (callAppPath) {
        const deep_link_value = encodeURIComponent(`ikwai://${callAppPath}?target_url=${targetUrl}`);
        oneLink = `${oneLink}&deep_link_value=${deep_link_value}`;
    }

    return oneLink;
};

export const getOneLinkUrlNew = (targetUrl: string, callAppPath?: string) => {
    const { pwa_source, share_id } = getQuery();

    let oneLinkUrl = 'https://kpro.onelink.me/vrlH';

    const { isSnack } = checkSnack();

    const kpn = getKpn({ bucket: sessionStorage.getItem('bucket') || '', host: window.location.host });

    // todo 后续要条件是否为kwai-me
    if (isSnack) {
        oneLinkUrl = 'https://sck.onelink.me/UxxP';
    }

    if (kpn === 'KWAI.ME') {
        oneLinkUrl = 'https://kme.onelink.me/KtRN';
    }

    const af_sub1 = JSON.stringify({
        shareId: share_id || defaultShareId,
        shareInfo: {
            target_url: targetUrl,
        },
    });

    const queryStr = `&is_retargeting=true&af_force_deeplink=true&land_info=%7B%22linkParams%22%3A%7B%22primary_directory%22%3A%22SHARE%22%2C%22secondary_directory%22%3A%22bra_abtest%22%2C%22link_id%22%3A%22kwai_pwa_1697788105802%22%2C%22item_type%22%3A%22none%22%2C%22item_info%22%3A%22%22%7D%7D&deep_link_sub1=pid%253Dkwai_pwa`;
    let oneLink = `${oneLinkUrl}?pid=kwai_pwa&af_sub1=${af_sub1}&af_channel=${pwa_source}${queryStr}`;

    if (callAppPath) {
        const deep_link_value = encodeURIComponent(`ikwai://${callAppPath}?target_url=${targetUrl}`);
        oneLink = `${oneLink}&deep_link_value=${deep_link_value}&af_dp=${deep_link_value}`;
    }
    return oneLink;
};

export const getTgOneLinkUrl = (targetUrl: string, callAppPath?: string) => {
    const { pwa_source, share_id } = getQuery();
    let oneLinkUrl = 'https://go.onelink.me/iGO2';
    const { isSnack } = checkSnack();
    const kpn = getKpn({ bucket: sessionStorage.getItem('bucket') || '', host: window.location.host });
    // todo 后续要条件是否为kwai-me
    if (isSnack) {
        oneLinkUrl = 'https://sharefor2.onelink.me/Z4b7';
    }
    if (kpn === 'KWAI.ME') {
        oneLinkUrl = 'https://kwaime.onelink.me/V16i';
    }
    const af_sub1 = JSON.stringify({
        shareId: share_id || defaultShareId,
        shareInfo: {
            target_url: targetUrl,
        },
    });
    let oneLink = `${oneLinkUrl}?pid=kwai_pwa&af_sub1=${af_sub1}&af_channel=${pwa_source}&af_force_deeplink=true`;
    if (callAppPath) {
        const deep_link_value = `${encodeURIComponent(`ikwai://${callAppPath}`)}?target_url=${targetUrl}`;
        oneLink = `${oneLink}&deep_link_value=${deep_link_value}&af_dp=${deep_link_value}`;
    }
    return oneLink;
};

// 需要node client 都用
export const getUrlKwaiId = (ctx?: Context) => {
    if (!process.server) {
        const uerName = window.location.href.match(/@(.*?)(\/|\?|$)/);
        return uerName && `@${uerName[1]}`;
    }
    return `@${ctx?.params?.pathMatch}`;
};

const getAppInfo = () => {
    return {
        pkg: cookies.get('pkg'),
        appver: cookies.get('appver'),
    };
};

export const commonPvParams = ({ defaultPageSource }: { defaultPageSource: string }) => {
    const { webDid, sessionId } = genWebDidAndSessionId();
    const { pwa_source, page_source, _p, bundle_id, click_id, traffic_from, bidid } = getQuery();
    genFirstReferrer();
    const { referrer, fReferrer } = getReferrers();
    const { pkg, appver } = getAppInfo();

    return {
        share_chain: window.location.href,
        session_id: sessionId,
        web_did: webDid,
        did: cookies.get('did'),
        pwa_source,
        page_source: page_source || defaultPageSource,
        pwa_install_source: SafetyLocalStorage.getItem('cachePwaSource'),
        open_mode: isPwa() ? 'desktop' : 'other',
        check_pwa_params: genCheckPwaParams(),
        network_type: getNetworkType(),
        anther_id: getUrlKwaiId(),
        extra_source: _p,
        referrer,
        fReferrer,
        is_kwai_tiny: isTiny() ? 1 : 0,
        is_telegram: isTMA(),
        pkg,
        appver,
        bundle_id,
        click_id,
        traffic_from,
        bidid,
    };
};

export const isTiny = () => {
    return !!cookies.get('isKwaiTiny');
};

export const getInterestExperimentStr = () => {
    return cookies.get('InterestExperimentGroup') || '';
};

export const getPageSource = (str: string) => {
    const expStr = getInterestExperimentStr();
    let res = `${str}-exp2`;
    if (expStr) res = `${str}-${expStr}`;
    console.log('getPageSource->', res);
    return res;
};

export const getFullPath = function (ctx: Context) {
    if (process.server) {
        return ctx.req.headers.referer?.split('?')[0] || '';
    }
    return window.location.href?.split('?')[0] || '';
};
// 根据 name 合并 content
export const mergeArrays = function (a: Array<any>, b: Array<any>) {
    return a.map(objA => {
        const objB = b.find(obj => obj.name === objA.name);
        if (objB && objB.content) {
            return {
                ...objA,
                content: objB.content,
            };
        }
        return objA;
    });
};

export const checkSeoReferrer = () => {
    const r = /google/g;
    const fReferrer = sessionStorage.getItem('fReferrer')?.toLocaleLowerCase() || '';
    return r.test(fReferrer);
};

export const checkTMAReferrer = () => {
    return isTMA();
};

export function isAndroid() {
    const ua: string = navigator.userAgent || navigator.vendor;
    return ua.includes('Android') || ua.includes('Adr');
}

export const serialize = function (obj: Record<string, any>) {
    const str = [];
    for (const p in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(p)) {
            str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
        }
    }
    return str.join('&');
};

export function objectToCookieString(obj: Record<string, any>) {
    return Object.entries(obj)
        .map(([key, value]) => {
            // 处理布尔值，将其转换成 'true' 或 'false'
            if (typeof value === 'boolean') {
                value = value ? 'true' : 'false';
            }
            // 对于其他非字符串类型，转换成字符串
            else if (typeof value !== 'string') {
                value = String(value);
            }
            // 对于字符串类型，需要进行编码
            value = encodeURIComponent(value);

            return `${encodeURIComponent(key)}=${value}`;
        })
        .join('; ');
}

export const getMilSeconds = (min: number) => {
    return min * 60 * 1000;
};

export const storageData = (name: string, time: string, redisTime: number) => {
    // 当前时间戳
    const timestamp = new Date().getTime();
    // 上次时间戳
    const prevTimestamp = SafetyLocalStorage.getItem(time) ?? 0;
    const data = SafetyLocalStorage.getItem(name);
    return {
        data: data || '',
        isExpired: prevTimestamp ? timestamp - +prevTimestamp > getMilSeconds(redisTime) : true,
    };
};

export const getStatusHT = () => {
    const regex = /StatusHT\/(\d+)/;
    const match = window?.navigator?.userAgent.match(regex);
    let statusht_value = 0;
    if (match != null && match[1] != null) {
        statusht_value = Number(match[1] || 0);
    } else {
        console.log('StatusHT value not found in user agent string');
    }
    return statusht_value;
};

export const getiOSVersion = () => {
    const ua = navigator.userAgent;
    const isIPhone = /iPhone/.test(ua);
    const isIPad = /iPad/.test(ua);

    // 提取 iOS 系统版本
    const iosVersionMatch = ua.match(/OS (\d+)_(\d+)_?(\d+)?/);
    const iosVersion = iosVersionMatch ? `${iosVersionMatch[1]}.${iosVersionMatch[2]}` : 'Unknown';

    console.log('device type', `设备类型: ${isIPhone ? 'iPhone' : isIPad ? 'iPad' : '未知'}`);
    console.log('device version', `系统版本: iOS ${iosVersion}`);
    return iosVersion;
};
export const addHashtagLinks = (str: string, isAd = false) => {
    str = str.replace(/\([^()]*\)/g, '');
    const hashtagRegex = /#[\p{L}\p{Mn}\p{Pc}]+/gu;
    // 将匹配到的 hashtag 替换为带a标签的字符串,如果是广告的话，不跳转
    const replacedStr = isAd
        ? str.replace(hashtagRegex, `<span class='hast-tag' style="color: rgba(0, 65, 130, 1);">$&</span>`)
        : str.replace(hashtagRegex, `<a class='hast-tag' style="color: rgba(0, 65, 130, 1);" href="/discover/$&">$&</a>`);
    // 将 a 标签的 href 属性中的 "#" 符号删除
    const replacedStrNoHashLink = replacedStr.replace(/href="(.+?)#(.+?)"/g, 'href="$1$2"');
    // 返回带 a 标签的字符串，但是 a 标签的跳转链接中的 "#" 符号均被删除
    return replacedStrNoHashLink;
};

// 格式化时间 gpt
export const timeStampToDisplayString = (timestamp: number) => {
    // 获取当前时间戳以及一天的时间戳
    const now = Date.now();
    const oneDay = 24 * 60 * 60 * 1000;

    // 计算时间戳和当前时间之间的差距
    const timeDiff = now - timestamp;

    // 判断是否在24小时内
    if (timeDiff < oneDay) {
        return '刚刚发布';
    }

    // 判断是否在七天内
    if (timeDiff < 7 * oneDay) {
        // 计算相差的天数
        const diffDays = Math.floor(timeDiff / oneDay);
        return `${diffDays}天前`;
    }
    // 7天外
    return '7天前';
};

// 版本对比
// vStr1 > vStr2 return 1
// vStr1 < vStr2 return -1
// vStr1 = vStr2 return 0
export function compareVersion(vStr1: string, vStr2: string) {
    const v1 = vStr1.split('.');
    const v2 = vStr2.split('.');
    const len = Math.max(v1.length, v2.length);

    while (v1.length < len) {
        v1.push('0');
    }
    while (v2.length < len) {
        v2.push('0');
    }

    for (let i = 0; i < len; i++) {
        const num1 = parseInt(v1[i], 10);
        const num2 = parseInt(v2[i], 10);

        if (num1 > num2) {
            return 1;
        }

        if (num1 < num2) {
            return -1;
        }
    }
    return 0;
}

// 复制字符串到剪切板并弹出提示
export function copyToClipboard(str: string) {
    // 创建一个临时的输入元素，设置其值为网页链接
    const tempInput = document.createElement('input');
    tempInput.value = str;
    document.body.appendChild(tempInput);

    // 选中该输入元素的文本内容，并执行复制到剪切板的操作
    tempInput.select();
    document.execCommand('copy');

    // 将该输入元素从文档中移除
    document.body.removeChild(tempInput);

    // 如果想要给出复制成功或失败的提示，可以使用以下代码：
    document.execCommand('copy');
}

export function objToQueryString(obj: Record<string, any>, isEncode: boolean) {
    if (typeof obj !== 'object') {
        return '';
    }
    const str = [];
    for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
            if (obj[p] === '') continue;
            if (isEncode) {
                str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
            } else {
                str.push(`${p}=${obj[p]}`);
            }
        }
    }
    return str.join('&');
}

export function replacePath(path: string, params?: object) {
    const query = { ...getQuery(), ...(params || {}) };
    const querystring = objToQueryString(query, true);
    history?.replaceState(null, '', `${path}${querystring ? `?${querystring}` : ''}`);
}

export async function replaceRoutePhotoId(proxy: NonNullable<ReturnType<typeof getCurrentInstance>>['proxy'] | undefined) {
    return new Promise(resolve => {
        const route = proxy?.$route;

        const { $isAppEnvironment } = proxy || {};

        if ($isAppEnvironment || !route?.meta?.replacePhotoId) {
            resolve(true);
            return;
        }

        const path = route?.path || '';
        const pathArr = path.split('/').filter(Boolean);
        const lastIdx = pathArr.length - 1;
        const pathPhotoId = pathArr[lastIdx];
        const currentFeedPhotoId = proxy?.$store?.state?.feedMoudle?.currentFeed?.photo_id_str;

        if (pathPhotoId && currentFeedPhotoId && pathPhotoId !== currentFeedPhotoId) {
            pathArr[lastIdx] = currentFeedPhotoId;
            const newPath = pathArr.join('/');
            proxy?.$router?.replace(`/${newPath}`).then(() => {
                resolve(true);
            });
        } else {
            resolve(true);
        }
    });
}

export const parseJsonSatefy = <T = unknown>(str?: string) => {
    try {
        if (typeof str !== 'string') {
            return undefined;
        }
        return JSON.parse(str) as T;
    } catch {
        return undefined;
    }
};
