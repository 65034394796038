import cookies from 'js-cookie';
import cloneDeep from 'lodash/cloneDeep';
import { objectToCookieString } from '@/common/utils';

export type KpnType = 'KWAI' | 'KWAI_BULLDOG' | 'KWAI.ME';

export const kwaiMeBucket = ['egy'];

export const getKpn = ({ bucket, host }: { bucket: string; host: string | undefined }) => {
    const kpnMap = {
        Kwai: 'KWAI',
        SnackVideo: 'KWAI_BULLDOG',
        Kwaime: 'KWAI.ME',
    };

    let kpn = '';

    if (host === 'www.kwai.com' || host === 'wwwt.kwai.com') {
        if (!kwaiMeBucket.includes(bucket)) {
            kpn = kpnMap.Kwai;
        } else {
            kpn = kpnMap.Kwaime;
        }
    } else if (host && host.includes('snack')) {
        kpn = kpnMap.SnackVideo;
    } else {
        kpn = kpnMap.Kwai;
    }

    if (kpn.toLowerCase() === 'snack') {
        kpn = 'KWAI_BULLDOG';
    }

    // 防止输入错误的kpn
    if (!['KWAI', 'KWAI.ME', 'KWAI_BULLDOG'].includes(kpn)) {
        kpn = kpn.includes('KWAI_BULLDOG') ? 'KWAI_BULLDOG' : 'KWAI';
    }

    return kpn;
};

export const paramsMap: any = {
    KWAI: {
        kpn: 'KWAI',
        apptype: 41,
        sys: 'KWAI',
        client_type: 3001,
    },
    KWAI_BULLDOG: {
        kpn: 'KWAI_BULLDOG',
        apptype: 43,
        sys: 'KWAI_BULLDOG',
        client_type: 3003,
    },
    'KWAI.ME': {
        kpn: 'KWAI.ME',
        apptype: 44,
        sys: 'KWAI_ME',
        client_type: 3004,
    },
};
export const setCommonParamsCookiesInServer = ({
    bucket,
    host,
    ctx,
    countryInfo,
}: {
    bucket: string;
    host: string | undefined;
    ctx: any;
    countryInfo?: string | undefined;
}) => {
    const kpn = getKpn({ bucket, host });
    const commonParams = cloneDeep(paramsMap[kpn] || paramsMap.KWAI);
    commonParams.bucket = bucket;
    commonParams.client_key = '65890b29';
    commonParams.i18n_redirected = ctx.query?.lang || '';

    if (countryInfo) {
        commonParams.countryInfo = countryInfo;
    }

    const did = ctx.req.serverMiddlewareDid;
    const webDidWithTime = ctx.req.serverMiddlewareWebDidWithTime;

    const serverCookie = ((ctx.req?.headers?.cookie || '') as string).split(';').reduce((res, item) => {
        const [name, value] = item.split('=').map(c => c.trim());
        return {
            ...res,
            [name]: value,
        };
    }, {} as Record<string, string>);
    const isAppEnvironment = !!serverCookie?.pkg && !!serverCookie?.appver;

    if (did) {
        commonParams.webDid = did;

        if (!isAppEnvironment) {
            commonParams.did = did;
        }
    }

    if (webDidWithTime) {
        commonParams.webDidWithTime = webDidWithTime;
    }

    const oneYear = 365 * 24 * 60 * 60 * 1000; // 一年的时间长度，以毫秒为单位
    const expirationDate = new Date(Date.now() + oneYear);

    const cookiesArr = Object.keys(commonParams).map(item => {
        if (item === 'did' || item === 'webDid' || item === 'webDidWithTime') {
            return `${item}=${commonParams[item]}; Path=/; Expires=${expirationDate.toUTCString()}`;
        }
        if (item === 'i18n_redirected') {
            return `${item}=${commonParams[item]}; Path=/;`;
        }

        return `${item}=${commonParams[item]}; Path=/; HttpOnly`;
    });

    ctx.res.setHeader('Set-Cookie', cookiesArr);
};
export const getCommonParamsByKpn = (kpn: KpnType, startup: Record<string, any>) => {
    const connection = (navigator as any)?.connection || (navigator as any)?.mozConnection || (navigator as any)?.webkitConnection;
    const net = connection?.type || connection?.effectiveType;

    const param: any = {
        did: cookies.get('webDid'),
        kpn,
        apptype: paramsMap[kpn].apptype,
        client_key: '65890b29',
        sys: paramsMap[kpn].sys,
        client_type: paramsMap[kpn].client_type,
        userAgent: navigator.userAgent,
        c: 'PWA',
        //  网络
        net,
        //  机型
        mod: navigator.userAgent,
        language: navigator.language,
        countryInfo: cookies.get('countryInfo') || startup.countryInfo,
        country_code: cookies.get('country_code') || startup.country_Code,
        bucket: cookies.get('bucket') || startup.bucket,
    };

    return param;
};

// llsid: long long search id
export const getLlsidFromExptag = (str?: string) => {
    if (typeof str !== 'string') {
        return;
    }

    return str.replace(/(\w+\/)(\d+)(\w+)/, '$2');
};
