import CallApp from '@ks/call-app';
import { radarLog } from './radar';
import { checkSeoReferrer, checkTMAReferrer, getQuery } from '@/common/utils';
import { getPwaSource } from '@/common/ids-utils';

interface CallOption {
    onelink: string;
    protocol?: string;
    host?: string;
    path?: string;
    param?: Record<string, any>;
    timeout?: number;
}

// path: 不加协议
export function callApp(option: CallOption) {
    const { shareBucket } = getQuery();
    const { protocol = 'ikwai', host, onelink, path, param, timeout = 3000 } = option;

    const callApp = new CallApp({
        scheme: {
            protocol,
            host,
        },
        appstore: onelink,
        fallback: onelink,
        timeout,
    });

    radarLog({
        name: 'PWA_CALL_APP',
        event_type: 'show',
        category: 'KWAI_SHARE',
        message: `protocal: ${protocol}, oneLink: ${onelink}`,
        extra_info: JSON.stringify({
            queryBucket: shareBucket || '',
            serverBucket: sessionStorage.getItem('bucket') || '',
            onelink,
        }),
        src: window.location.href,
    });

    callApp.open({
        path,
        param,
    });
}

export function evokeByTagA(uri: string, delay?: number) {
    const tagA = document.createElement('a');
    tagA.setAttribute('href', uri);
    tagA.style.display = 'none';
    document.body.appendChild(tagA);
    tagA.addEventListener('click', e => {
        e.stopPropagation();
    });

    setTimeout(() => {
        tagA.click();
    }, delay || 500);
}

export function autoLaunchAppWarp(uri: string) {
    // 如果是来自seo的来源 不自动拉
    if (checkSeoReferrer()) return;
    // 如果是来气Telegram的来源 不自动拉
    if (checkTMAReferrer()) return;
    radarLog({
        name: 'PWA_AUTO_LAUNCH',
        event_type: 'show',
        category: 'KWAI_SHARE',
        message: `auto_launch`,
        src: window.location.href,
    });
    evokeByTagA(uri);
}

export const tokenCollectedCallApp = (proxy: any) => {
    if (getPwaSource() !== 'token_collect') {
        return;
    }
    try {
        proxy.$bus.emit('launchApp', {
            callAppPath: 'home?need_intercept_uri=true',
        });
        // autoLaunchAppWarp('ikwai://home?need_intercept_uri=true');
    } catch (e) {
        console.log('launch app error: ', e);
    }
};
