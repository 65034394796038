export enum AIStoreType {
    Conversation = 'conversation',
    Chat = 'chat',
    User = 'user',
}

export enum AIActionType {
    Add = 'add',
    Update = 'update',
    Delete = 'delete',
}
