/* eslint-disable import/named */
import { NuxtAxiosInstance } from 'vue/types/vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AxiosRequestConfig } from 'axios';
import { ResponseBase } from './base';
import { AIChatBeginResEventContentType, AIChatResponseBase } from '~/pages/aichat/scripts/ai-chat-handler';
import axios from './request';

/**
 * Api AIChatApiService
 *
 */

// create chat
interface AIChatCreateRequest {
    botId?: string;
    userId?: string;
}

interface AIChatTextAudioRequest {
    botId?: string;
    content?: string;
}

export interface AIChatTextAudioResponse extends ResponseBase {
    data: {
        audioUrl?: string;
        audio_base64?: string;
    };
}

// speech synthesis
interface AIChatSpeechSynReq {
    botId?: string;
    content?: string;
}

interface AIChatSpeechSynRes extends ResponseBase {
    data: {
        audioUrl?: string;
    };
}

export interface AIChatBeginResEventExtra {
    toolName?: string;
    toolArgs?: string;
    toolResponse?: string;
    toolCallId?: string;
    timeCost?: number;
    inputTokens?: number;
    outputTokens?: number;
}

export enum AIChatBeginResEventType {
    UNKNOWN_EVENT = 'UNKNOWN_EVENT',
    CHAT_MESSAGE_GENERATED = 'CHAT_MESSAGE_GENERATED',
    CHAT_MESSAGE_GENERING = 'CHAT_MESSAGE_GENERING',
    FUNCTION_CALLING = 'FUNCTION_CALLING',
    FUNCTION_CALLED = 'FUNCTION_CALLED',
    CHAT_STREAM_END = 'CHAT_STREAM_END',
    KNOWLEDGE_QUERYING = 'KNOWLEDGE_QUERYING',
    KNOWLEDGE_QUERIED = 'KNOWLEDGE_QUERIED',
    OPENING_DIALOG = 'OPENING_DIALOG',
}

export interface AIChatBeginResEvent {
    type: AIChatBeginResEventType;
    messageId: number;
    runId: number;
    sessionId: number;
    senderId: string;
    contentType: AIChatBeginResEventContentType;
    content: string;
    extraInfo?: AIChatBeginResEventExtra;
}

export interface AIChatBeginResEventList {
    chatEvent: AIChatBeginResEvent[];
}

export interface AIChatBeginResEventMap {
    chatEventTypeMap: { [key: string]: AIChatBeginResEventList };
}

interface AIChatCreateResponseData {
    conversationId?: string;
    name?: string;
    botId?: string;
    userId?: string;
    channel?: string;
    createTime?: string;
}

interface AIChatCreateResponse extends AIChatResponseBase {
    data?: AIChatCreateResponseData;
}

export interface AIChatBeginResponse extends AIChatResponseBase {
    data?: string;
}

interface AiBotsReportRequest {
    botId?: string;
    reason?: string;
    content?: string;
}

export interface TagListItem {
    tagCategory?: string;
    tags?: Array<{
        tagName?: string;
        tagId?: string;
        icon?: string;
    }>;
}

export interface ProfilePostItem {
    auditId?: string;
    botId?: string;
    status?: number;
    botName?: string;
    intro?: string;
    botBgUrl?: string;
    compliance_mark_show?: boolean;
}

export interface GetProfilePostResponse extends ResponseBase {
    data?: {
        list?: Array<ProfilePostItem>;
    };
}

export interface GetTagListResponse extends ResponseBase {
    data?: {
        list?: Array<TagListItem>;
    };
}

interface AiGetToneLisRequest {
    toneCategory?: string;
    pageSize?: number;
    offset?: number;
}

interface AiGetMyprofileRequest {
    pageSize?: number;
    offset?: number;
}

interface AiDeleteMyBotRequest {
    botId?: string;
}

interface AiCreateRequest {
    bgImg?: string;
    avatar?: string;
    botName?: string;
    gender?: number;
    botIntroduction?: string;
    greeting?: string;
    toneId?: string;
    permission?: number;
    background?: string;
    tags?: string;
}

interface AiUploadAvatarRequest {
    img?: File;
}

interface AiUploadAvatarResponse extends ResponseBase {
    url?: string;
}

interface AiLeadChatRequest {
    botId?: string;
}

interface AiLeadChatResponse extends ResponseBase {
    greetingList?: string[];
}

export interface ToneListItem {
    toneId?: string;
    toneCategory?: string;
    toneName?: string;
    toneTags?: Array<string>;
    audioUrl?: string;
}

export interface GetToneListResponse extends ResponseBase {
    data?: {
        list?: Array<ToneListItem>;
    };
}

export type AiBotsReportResponse = ResponseBase;

export interface GetBotAutomaticChatResponse extends ResponseBase {
    data?: {
        status?: string;
        nextRequestSecond?: number; // seconds
        greetingList?: {
            // key: bot id
            [key: string]: {
                gt?: string; // greeting text
                ts?: number; // timestamp
            };
        };
    };
}

export class AIChatApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        this.$axios = axios;
    }

    // create AI Chat
    public async createAIChat(data: AIChatCreateRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<AIChatCreateResponse>(`/rest/o/w/pwa/aifeed/bots/getConversationId`, data, config);
        return res;
    }

    // ai speech
    public async aiSpeechSynthesis(data: AIChatSpeechSynReq, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<AIChatSpeechSynRes>(`/rest/o/w/pwa/aifeed/speechSynthesis`, data, config);
        return res;
    }

    // ai audio player
    public async aiChatTextAudio(data: AIChatTextAudioRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<AIChatTextAudioResponse>(`/rest/o/w/pwa/aifeed/speechSynthesis`, data, { timeout: 20000 });
        return res;
    }

    // 举报接口
    async reportAiBots(data: AiBotsReportRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<AiBotsReportResponse>(`/rest/o/w/pwa/aifeed/bots/report`, data, config);
        return res;
    }

    // bot 主动打招呼
    async getBotAutomaticChat(data: Record<string, any>, config?: AxiosRequestConfig) {
        // const date = new Date();
        // const time = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        // const Mockconversations: GetBotAutomaticChatResponse = {
        //     data: {
        //         status: 'success',
        //         greetingList: {
        //             '721342653757674524': {
        //                 greet: `${time} this is a test message `,
        //             },

        //             '721342653757674608': {
        //                 greet: `${time} this is a test message `,
        //             },
        //         },
        //     },
        // };

        // return {
        //     ...Mockconversations,
        //     result: 1,
        // };
        const res = await this.$axios?.$$post?.<GetBotAutomaticChatResponse>(`rest/o/w/pwa/aifeed/bots/greeting`, data, config);
        return res;
    }

    // 获取机器人Tag接口
    async getTagList(config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<GetTagListResponse>(`/rest/o/w/pwa/bot/w/ugc/getTagList`, config);
        return res;
    }

    // 获取机器人音色接口
    async getToneList(data: AiGetToneLisRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<GetToneListResponse>(`/rest/o/w/pwa/bot/w/ugc/getToneList`, data, config);
        return res;
    }

    // 获取个人主态P页数据
    async getMyProfileFeed(data: AiGetMyprofileRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<GetProfilePostResponse>(`/rest/o/w/pwa/bot/w/ugc/myprofile`, data, config);
        return res;
    }

    // 删除创建的机器人
    async deleteMyBot(data: AiDeleteMyBotRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<ResponseBase>(`/rest/o/w/pwa/bot/w/ugc/del`, data, config);
        return res;
    }

    // 上传图片生成CDN地址接口
    async uploadAvatar(data: AiUploadAvatarRequest) {
        const toFormData = (data: AiUploadAvatarRequest): FormData => {
            const formData = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                if (value !== undefined && value !== null) {
                    formData.append(key, value);
                }
            });
            return formData;
        };

        const formData = toFormData(data);
        const res = await this.$axios?.$$post?.<AiUploadAvatarResponse>(`/rest/o/w/pwa/bot/w/ugc/upload/img`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            timeout: 30000,
        });
        return res;
    }

    // 创建机器人接口
    async createBot(data: AiCreateRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<ResponseBase>(`/rest/o/w/pwa/bot/w/ugc/create`, data, config);
        return res;
    }

    // 引导对话接口
    async getGreeting(data: AiLeadChatRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$get?.<AiLeadChatResponse>('/rest/o/w/pwa/aifeed/bots/encourage/greet', {
            ...config,
            params: data,
        });
        return res;
    }
}

export const AIChatApi = new AIChatApiService();
