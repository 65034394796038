
import { defineComponent, SetupContext, getCurrentInstance, onUnmounted, computed, onMounted, ref, reactive, defineAsyncComponent } from 'vue';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, deleteToken } from 'firebase/messaging';
import cookies from 'js-cookie';
import { onLCP, onCLS, onTTFB, onFCP, onINP, onFID, Metric, ReportOpts } from 'web-vitals';
import Loading from '@/components/loading/index.vue';
import { radarLog, sendLog } from '@/common/radar';
import { genWebDidAndSessionId } from '@/common/utils';
import { launchApp, initDeviceFingerprint } from '@/common/layout-common';
import { ComponentNameMap, FirebaseConfig } from '@/constants/base';
import { ReportFirebaseTokenRequest, UserApiService } from '@/api/user';
import device from '@/common/device-info';
import { filmRedirect } from '@/common/client_redirect';
import { useWebToApp, OpenPageStatus } from '~/hooks/useWebToApp';
import { buildIDsSystem } from '@/common/ids-mapping-sys';
import { installPwaFun, isContinue, continueMap } from '@/common/pwa';
import { checkSnack } from '@/common/seo';

import {
    FirebaseDefaultPublicKey,
    FirebaseSWFileName,
    FirebaseSWScope,
    FirebaseMessageDBName,
    FirebaseMessageDBStoreName,
    FirebaseMessageStoreKey,
    PushPublicKey,
    FirebaseProjectName,
} from '@/common/web-push';
import { DBControllerInstance } from '@/common/indexed-db';
import { SafetyLocalStorage } from '@/common/storage';
import { useAiBotAutomaticMessage } from '@/hooks/useAiBotAutomaticMessage';
import { useLogin } from '~/hooks/useLogin';

const firebaseApp = initializeApp(FirebaseConfig);

const LightThemePageList = ['discover-content', 'playlist-detail', 'profile', 'topic-content', 'account-management', 'article-content', 'channel'];

const IconMap = {
    PushIcon: 'https://cdn-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/push-icon.png',
    SharePopupTopIcon: 'https://aws-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/share-popup-top-icon.png',
    KwaiIcon: 'https://cdn-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/kwai-icon.png',
    SnackIcon: 'https://cdn-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/snack-icon.png',
};

/**
 * @zh 通知权限状态
 */
enum OpenStatus {
    UnSupported = 0, // 不支持通知：1、系统不支持 2、firebase 不支持(如防火墙限制等)
    Close = 1, // 未开启
    Opend = 2, // 已开启
    Opening = 3, // 本次开启
    Closing = 4, // 本次关闭
    MaybeLater = 5,
    EnableNow = 6,
}

const PopupType = {
    installPwa: 'installPwa',
    openPwa: 'openPwa',
};

/**
 * @en type copy from firebase-js-sdk
 */
interface MessagingService {
    vapidKey?: string;
    swRegistration?: ServiceWorkerRegistration;
}

export default defineComponent({
    components: {
        Loading,
        FooterNav: defineAsyncComponent(() => import(/* webpackChunkName: "components/footer-nav" */ '@/components/footer-nav/index.vue')),
        AppReferralPromptPopup: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "components/web-to-app-popup/app-referral-popup.vue" */ '@/components/web-to-app-popup/app-referral-popup.vue'
                )
        ),
        ActionOpenPwa: defineAsyncComponent(
            () => import(/* webpackChunkName: "components/action-open-pwa/index.vue" */ '@/components/action-open-pwa/index.vue')
        ),
        LiteSharePopup: defineAsyncComponent(
            () => import(/* webpackChunkName: "components/lite-share-popup/index.vue" */ '@/components/lite-share-popup/index.vue')
        ),
        // Popup: defineAsyncComponent(() => import(/* webpackChunkName: "'vant/lib/popup" */ 'vant/lib/popup')),
        TopTabs: defineAsyncComponent(
            () => import(/* webpackChunkName: "aichat/components/ai-top-tabs" */ '@/pages/aichat/components/ai-top-tabs/index.vue')
        ),
    },
    setup(_: unknown, ctx: SetupContext) {
        const { proxy }: any = getCurrentInstance() || {};
        const startupData: any = proxy.$store.state.startup;
        const UserApi = new UserApiService();
        const showBtn = ref(true);
        const { isIOS = false } = device();
        const startTime = Date.now();

        const popupState: Record<string, any> = reactive({
            isFirstOpen: false,
            showOpenPwaBtn: false,
            type: PopupType.openPwa,
        });
        const visible = ref(false);

        const showFooter = computed(() => {
            return proxy?.$route?.path === '/old-foryou';
        });

        const { webToAppPopupVisible, handleWebToAppPopupConfirm, handleWebToAppPopupClose, checkIfInstalled, installPwa, isPwaSupported } =
            useWebToApp();

        const showTopTabs = computed(() => {
            // snackvideo不出social ai tab
            const { isSnack } = checkSnack();
            if (isSnack) return false;

            const currentPath = proxy?.$route?.path;
            const regexForyouVideo = /^\/((foryou)$|@[\w-]+\/video(\/[\w-]+)?)$/;
            // 仅仅在倒流时候生效
            if (currentPath === '/' || regexForyouVideo.test(currentPath)) {
                // 实验不命中不出
                const canShow = proxy?.$store.state.startup?.AIfriend === 'true';
                if (!canShow) return false;

                // 浏览器语言不是英语/葡语不出
                const lang = proxy?.$root?.$i18n?.locale || 'en';
                const isEnglishOrPortuguese = lang.includes('en') || lang.includes('pt');
                if (!isEnglishOrPortuguese) {
                    console.log('not_show_ai_top_tabs lang', lang);
                    return false;
                }

                // 非巴西或者英语桶不出
                const bucket = proxy?.$store?.state?.startup?.bucket || 'br';
                if (bucket !== 'br' && bucket !== 'en') {
                    console.log('not_show_ai_top_tabs bucket', bucket);
                    return false;
                }
            }
            const { conversationModuleInitialized } = proxy?.$store?.state?.conversationModule;
            const regex = /^\/((foryou|aichat(\/history|\/chatlist|\/discover|\/bot\/[\w-]+)?)$|@[\w-]+\/video(\/[\w-]+)?)$/;
            return currentPath === '/' || (regex.test(currentPath) && conversationModuleInitialized);
        });

        const isAIchat = computed(() => {
            // aichat 首次弹窗已经非常多，这里不弹窗开kwailite
            const currentPath = proxy?.$route?.path;
            return currentPath.includes('aichat');
        });

        const appName = computed(() => {
            const name = (getCurrentInstance()?.proxy as { $appName?: string })?.$appName;
            return name || '';
        });

        const loading = ref(false);

        const setLoading = (isLoading: boolean) => {
            loading.value = isLoading;
        };

        const getCurrentPermissionStatus = async (): Promise<OpenStatus> => {
            const isSupport = await isSupported();

            if (!isSupport) {
                radarLog({
                    name: 'Unsupport_Firebase',
                    category: 'KWAI_SHARE',
                    event_type: 'click',
                });
            }

            if (typeof window?.Notification?.permission === 'undefined' || !isSupport) {
                return OpenStatus.UnSupported;
            }

            return window?.Notification?.permission === 'granted' ? OpenStatus.Opend : OpenStatus.Close;
        };

        const reportToken = async ({ token = '', extraParams = {}, pushSubscription = {} }) => {
            const bucket = startupData.bucket || cookies.get('bucket');

            const data: ReportFirebaseTokenRequest = {
                push_token: token,
                subscription: (pushSubscription as Record<string, any>).endpoint ? pushSubscription : undefined,
                timestamp: Date.now(),
                domain: window.location.origin,
                url: window.location.href,
                ua: window.navigator.userAgent,
                /**
                 * @en `default_web_did_` as default prefix
                 * @en can be analyzed by the prefix
                 */
                web_did: cookies.get('webDid') || `default_web_did_${Date.now()}`,
                kwai_id: proxy?.$store?.state?.userModule?.userInfo?.user?.kwai_id,
                user_id_str: cookies.get('user_id'),
                session_id: cookies.get('sessionId'),
                countryInfo: startupData.countryInfo || cookies.get('countryInfo'),
                bucket,
                extra_params: {
                    ...(extraParams || {}),
                },
            };

            if (!token) {
                radarLog({
                    name: 'Get_Firebase_Token_Error',
                    category: 'KWAI_SHARE',
                    event_type: 'click',
                    extra_info: JSON.stringify(data),
                });

                return;
            }

            await UserApi.reportFirebaseToken(data);
        };

        const sendNotificationPermissionLog = (status: OpenStatus) => {
            sendLog({
                type: 'click',
                name: 'PWA_AUTHORITY',
                value: {
                    status,
                },
            });
        };

        const tryUpdateSW = async (registration: ServiceWorkerRegistration) => {
            try {
                // From fiebase SDK:
                // The timing when browser updates sw when sw has an update is unreliable by my experiment. It
                // leads to version conflict when the SDK upgrades to a newer version in the main page, but sw
                // is stuck with the old version.
                await registration.update();
            } catch {
                /* it is non blocking and we don't care if it failed */
            }
        };

        // eslint-disable-next-line no-undef
        const registerSW = async (swPath: string, options?: RegistrationOptions) => {
            try {
                const registration = await navigator.serviceWorker.register(`${window?.location?.origin}/${swPath}`, options);
                await tryUpdateSW(registration);

                return registration;
            } catch {
                return undefined;
            }
        };

        const registerPushMessaging = async () => {
            try {
                const isSupport = await isSupported();

                if (!window?.navigator?.serviceWorker || !isSupport) {
                    return;
                }

                /**
                 * @zh 在注册 firebase 前先判断是否已经开启
                 */
                const hasOpened = window.Notification?.permission === 'granted';

                const registerFirebaseSW = await registerSW(FirebaseSWFileName, {
                    scope: FirebaseSWScope,
                });
                if (!registerFirebaseSW) {
                    return;
                }
                const message = getMessaging(firebaseApp);

                const firebaseDBData = await DBControllerInstance.dbGet<{
                    createTime?: number;
                    subscriptionOptions?: {
                        auth?: string;
                        endpoint?: string;
                        p256dh?: string;
                        vapidKey?: string;
                        swScope?: string;
                    };
                    token?: string;
                }>({
                    dbName: FirebaseMessageDBName,
                    storeName: FirebaseMessageDBStoreName,
                    storeKey: FirebaseMessageStoreKey,
                });

                const oldVapidKey = firebaseDBData?.subscriptionOptions?.vapidKey;
                if (oldVapidKey === FirebaseDefaultPublicKey) {
                    await deleteToken(message);
                }

                const token = await getToken(message, {
                    vapidKey: PushPublicKey,
                    serviceWorkerRegistration: registerFirebaseSW,
                });
                const { vapidKey: newVapidKey, swRegistration: newSwRegistration } = (message || {}) as MessagingService;

                const subscription = await newSwRegistration?.pushManager?.getSubscription?.();
                const subscriptionInfo = subscription?.toJSON?.();
                console.log('==== push info ====', newVapidKey, subscriptionInfo, token);
                await reportToken({
                    token,
                    pushSubscription: {
                        endpoint: subscriptionInfo?.endpoint,
                        expirationTime: subscriptionInfo?.expirationTime ? `${subscriptionInfo?.expirationTime}` : undefined,
                        auth: subscriptionInfo?.keys?.auth,
                        p256dh: subscriptionInfo?.keys?.p256dh,
                    },
                    extraParams: {
                        app_name: FirebaseProjectName,
                    },
                });

                if (!hasOpened) {
                    /**
                     * @zh 之前 “未开启” 才上报 “本次开启”
                     */
                    sendNotificationPermissionLog(OpenStatus.Opening);
                }
            } catch (e) {
                if (window.Notification?.permission === 'default') {
                    // 用户手动拒绝
                    sendNotificationPermissionLog(OpenStatus.Closing);
                }
            }
        };

        // const registerCacheSW = async () => {
        //     const registerCache = await registerSW('cache-sw.js');
        //     console.log('sw cache register success', registerCache);
        // };

        const changeShow = (isShow: boolean) => {
            popupState.showOpenPwaBtn = isShow;
        };

        // 展示 使用pwa打开弹窗
        const openWithPwaDiadog = () => {
            changeShow(true);
            popupState.type = PopupType.openPwa;
        };

        const pwaGlobalClick = () => {
            const { open_guide_seconds } = proxy?.$store?.state?.abTestModule?.abTestParams;
            // 命中时间 实验
            if (open_guide_seconds) {
                const nowTime = Date.now();
                if (nowTime - startTime <= Number(open_guide_seconds) * 1000) return;
                installPwaFun(installPwa, 'agree_interaction');
            } else {
                installPwaFun(installPwa, 'agree_interaction');
            }
        };

        // 进入页面pwa 埋点上报
        const pwaStatusLog = (pwaStatus: number, isSupport: boolean) => {
            let logType = 'uninstalled';
            if (pwaStatus === OpenPageStatus.noPwaInWeb) {
                // 未安装
                logType = 'uninstalled';
            } else if ([OpenPageStatus.hasPwaInWeb, OpenPageStatus.pwa].includes(pwaStatus)) {
                // 已安装
                logType = 'installed';
            } else if (!isSupport) {
                // 不支持
                logType = 'not_support';
            }
            sendLog({
                type: 'click',
                name: 'ADD_TO_DESKTOP_STATUS',
                value: {
                    status: logType,
                },
            });
        };
        // pwa 实验逻辑
        const pwaExp = (pwaStatus: number, isSupport: boolean) => {
            pwaStatusLog(pwaStatus, isSupport);
            // 已安装 非pwa 模式 & 一天一次
            if (pwaStatus === OpenPageStatus.hasPwaInWeb) {
                isContinue('openPwa').then(res => {
                    if (res === continueMap.first) {
                        changeShow(true);
                        popupState.type = PopupType.openPwa;
                    }
                });
            } else if (pwaStatus === OpenPageStatus.noPwaInWeb) {
                window.addEventListener('click', pwaGlobalClick);
            }
        };

        const handleCancel = () => {
            visible.value = false;
        };

        const checkPopupConditions = () => {
            const { isIOS } = device() || {};
            if (!isIOS && !isAIchat.value) {
                const url = window?.location?.href;
                const params = new URLSearchParams(new URL(url).search);
                const KWAI_LITE_SHARE = 'kwai_lite_share';
                const KWAI_LITE_VIDEO = 'kwai.lite.video';
                const pwa_source = params.get('pwa_source');
                const app_package_name = params.get('app_package_name');
                const lastPopupTime = SafetyLocalStorage.getItem('kwai_lite_lastPopupTime');
                if (pwa_source === KWAI_LITE_SHARE && app_package_name === KWAI_LITE_VIDEO) {
                    const currentTime = Date.now();
                    if (lastPopupTime) {
                        const lastTime = parseInt(lastPopupTime);
                        const hoursDifference = (currentTime - lastTime) / (1000 * 60 * 60);
                        // 大于24h
                        if (hoursDifference > 24) {
                            visible.value = true;
                            SafetyLocalStorage.setItem('kwai_lite_lastPopupTime', currentTime.toString());
                            sendLog({
                                type: 'show',
                                name: 'KWAI_LITE_POP',
                            });
                        }
                    } else {
                        // 第一次弹窗
                        SafetyLocalStorage.setItem('kwai_lite_lastPopupTime', currentTime.toString());
                        visible.value = true;
                        sendLog({
                            type: 'show',
                            name: 'KWAI_LITE_POP',
                        });
                    }
                }
            }
        };

        // 安装完成回调
        const onappinstalledCb = () => {
            const { openPwaafterInstall } = proxy?.$store?.state?.abTestModule?.abTestParams || {};
            setTimeout(() => {
                changeShow(true);
                popupState.type = PopupType.openPwa;
                popupState.isFirstOpen = true;
            }, openPwaafterInstall * 1000);
        };

        const getUserInfo = async () => {
            const res = await UserApi.getUserInfo({});
            if (res?.result === 1 && !!res?.user) {
                proxy?.$store?.commit?.('userModule/setUserInfo', {
                    user: res.user,
                });
                proxy?.$store?.commit?.('setIsLogin', true);
            }
        };

        const reportCustomMetrics = () => {
            try {
                const reportMetrics = (metric: Metric) => {
                    const duration = Number(metric.value || metric.delta);
                    if (!metric.name || !duration) {
                        return;
                    }
                    radarLog({
                        name: `${metric.name}_NUXT_OLD`,
                        event_type: 'show',
                        category: 'KWAI_SHARE',
                        // message: ``,
                        src: window.location.pathname,
                        duration,
                    });
                };

                const reportOptions: ReportOpts = {
                    reportAllChanges: false,
                };
                onFCP(reportMetrics, reportOptions);
                onLCP(reportMetrics, reportOptions);
                onTTFB(reportMetrics, reportOptions);
                onCLS(reportMetrics, reportOptions);
                onINP(reportMetrics, reportOptions);
                onFID(reportMetrics, reportOptions);
            } catch {
                // do nothing
            }
        };

        onMounted(async () => {
            reportCustomMetrics();
            genWebDidAndSessionId();
            initDeviceFingerprint();
            registerPushMessaging();
            getUserInfo();

            proxy?.$store?.commit?.('conversationModule/initialize');
            const bucket = proxy?.$store?.state?.startup?.bucket;
            // launchApp调用时获取不到this 无法拿到bucket值
            sessionStorage.setItem('bucket', bucket);
            // 全局注册拉起方法
            (proxy as Record<string, any>)?.$bus?.on?.('launchApp', launchApp);
            (proxy as Record<string, any>)?.$bus?.on?.('isLoad', setLoading);
            (proxy as Record<string, any>)?.$bus?.on?.('openWithPwaDiadog', openWithPwaDiadog);

            filmRedirect(proxy, true);
            buildIDsSystem(proxy, true);
            const { if_open_guide_to_desktop, open_guide_seconds, install_pwa_no_limit, install_pwa_touch, openPwaafterInstall } =
                proxy?.$store?.state?.abTestModule?.abTestParams;
            // 检测是否安装pwa
            const pwaStatus = checkIfInstalled();
            // 是否支持 pwa
            const isSupport = isPwaSupported();
            pwaStatusLog(pwaStatus, isSupport);
            // 开启 pwa 逻辑
            if (if_open_guide_to_desktop && !isIOS && bucket === 'br' && !isAIchat.value) {
                //
                pwaExp(pwaStatus, isSupport);

                // 监听pwa 安装完成，需要延迟 5s
                window.onappinstalled = onappinstalledCb;
            }
            checkPopupConditions();

            // if (proxy?.$store?.state?.abTestModule?.abTestParams.cache_exp) {
            //     registerCacheSW();
            // }

            if (proxy?.$store?.state?.startup?.redirectDuration) {
                console.log('radar report redirect duration', proxy?.$store?.state?.startup?.redirectDuration);
                radarLog({
                    name: 'REDIRECT_TIME_COST',
                    duration: proxy?.$store?.state?.startup?.redirectDuration,
                });
            }
        });

        const bgColor = computed(() => {
            const isLightTheme = LightThemePageList.includes(proxy?.$route?.name || '');
            if (isLightTheme) {
                return '#fff';
            }
            return 'black';
        });

        onUnmounted(() => {
            (proxy as Record<string, any>)?.$bus?.off?.('launchApp', launchApp);
            (proxy as Record<string, any>)?.$bus?.off?.('isLoad', setLoading);
            (proxy as Record<string, any>)?.$bus?.off?.('openWithPwaDiadog', openWithPwaDiadog);
            window.removeEventListener('click', pwaGlobalClick);
        });

        useAiBotAutomaticMessage();

        return {
            bgColor,
            showFooter,
            showTopTabs,
            loading,
            appName,
            IconMap,
            ComponentNameMap,
            webToAppPopupVisible,
            isAIchat,
            visible,
            showBtn,
            popupState,

            handleWebToAppPopupConfirm,
            handleWebToAppPopupClose,
            changeShow,
            handleCancel,
        };
    },
    head() {
        // const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
        const host = (this as any).$host;
        const languagemap = this.$i18n.localeCodes.map((el: string) => {
            return {
                rel: 'alternate',
                hreflang: el,
                href: `https://${host + decodeURI(this.$route.path)}?lang=${el}`,
            };
        });

        return {
            link: [
                {
                    rel: 'canonical',
                    href: `https://${host + decodeURI(this.$route.path)}`,
                },
                ...languagemap,
            ],
        };
    },
    // watch: {
    // eslint-disable-next-line func-names
    // '$route.path': function (newValue, oldValue, ...args) {
    //     console.log(newValue, oldValue, '----args----');
    //     try {
    //         const { $isAppEnvironment } = this?.$nuxt?.context || {};
    //         if (newValue !== oldValue && $isAppEnvironment) {
    //             window.$yoda?.invoke?.('webview.open', {
    //                 url: `${window?.location?.origin}${newValue}`,
    //             });
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // },
    // },
});
