import { EventEmitter } from 'events';
import { AIUserModel } from '../models/AIModel';
import getDB from '../utils/index';
import { AIStoreType, AIActionType } from './AIStoreType';

export class AIUserStore extends EventEmitter {
    private users: AIUserModel[] = [];

    async initialize() {
        await this.loadUsersFromDB();
    }

    async loadUsersFromDB(): Promise<boolean> {
        const db = await getDB();
        return new Promise((resolve, reject) => {
            const tx = db.transaction('users', 'readonly');
            const store = tx.objectStore('users');
            const request = store.getAll();
            request.onsuccess = () => {
                this.users = request.result;
                resolve(true);
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }

    // Public
    public async addUser(user: AIUserModel) {
        this._addUserToMemory(user);
        await this._addUserToDB(user);
    }

    public async deleteUser(userId: string) {
        this._deleteUserFromMemory(userId);
        await this._deleteUserFromDB(userId);
    }

    public async updateUser(user: AIUserModel) {
        this._updateToMemory(user);
        await this._updateUserInDB(user);
    }

    // Private
    private _updateToMemory(user: AIUserModel) {
        const index = this.users.findIndex(u => u.id === user.id);
        if (index !== -1) {
            this.users[index] = user;
            this.emit('change', { type: AIStoreType.User, action: AIActionType.Update, user });
        }
    }

    private async _updateUserInDB(user: AIUserModel): Promise<boolean> {
        try {
            const db = await getDB();
            return new Promise((resolve, reject) => {
                const tx = db.transaction('users', 'readwrite');
                const store = tx.objectStore('users');
                const request = store.put(user);
                request.onsuccess = () => {
                    resolve(true);
                };
                request.onerror = () => {
                    reject(request.error);
                };
            });
        } catch (error) {
            console.log('update user error', error);
            return false;
        }
    }

    private _addUserToMemory(user: AIUserModel) {
        this.users.push(user);
        this.emit('change', { type: AIStoreType.User, action: AIActionType.Add, user });
        console.log('social-ai-im', 'add user to memory');
    }

    private async _addUserToDB(user: AIUserModel): Promise<boolean> {
        try {
            const db = await getDB();
            return new Promise((resolve, reject) => {
                const tx = db.transaction('users', 'readwrite');
                const store = tx.objectStore('users');
                const request = store.add(user);
                request.onsuccess = () => {
                    console.log('social-ai-im', 'add user to db success');
                    resolve(true);
                };
                request.onerror = () => {
                    console.log('social-ai-im', 'add user to db with error', request.error);
                    reject(request.error);
                };
            });
        } catch (error) {
            console.log('add user error', error);
            return false;
        }
    }

    private async _deleteUserFromMemory(userId: string) {
        this.users = this.users.filter(u => u.id !== userId);
        this.emit('change', { type: AIStoreType.User, action: AIActionType.Delete, user: this.getUserById(userId) });
        console.log('social-ai-im', 'delete user to memory');
    }

    private async _deleteUserFromDB(userId: string): Promise<boolean> {
        try {
            const db = await getDB();
            return new Promise((resolve, reject) => {
                const tx = db.transaction('users', 'readwrite');
                const store = tx.objectStore('users');
                const request = store.delete(userId);
                request.onsuccess = () => {
                    resolve(true);
                    console.log('social-ai-im', 'delete user to db');
                };
                request.onerror = () => {
                    reject(request.error);
                };
            });
        } catch (error) {
            console.log('delete user error', error);
            return false;
        }
    }

    getUsers() {
        return this.users;
    }

    getUserById(userId: string): AIUserModel | undefined {
        return this.users.find(u => u.id === userId);
    }

    watch(storeType: AIStoreType, callback: (event: { type: AIStoreType; action: AIActionType; user: AIUserModel }) => void) {
        if (storeType === AIStoreType.User) {
            this.on('change', callback);
        }
    }

    unwatch(storeType: AIStoreType, callback: (event: { type: AIStoreType; action: AIActionType; user: AIUserModel }) => void) {
        if (storeType === AIStoreType.User) {
            this.removeListener('change', callback);
        }
    }
}

export default AIUserStore;
