// eslint-disable-next-line import/no-extraneous-dependencies
import { Module } from 'vuex';

export type PopupModuleType = {
    showAIInfo?: boolean;
    installPWA?: boolean;
    showOver18?: boolean;
    showGenderPrefer?: boolean;
    showAdsPopup?: boolean;
    showSideBar?: boolean;
    loginPopupFlag?: number;
    logoutPopupFlag?: number;
};

export const defaultState: PopupModuleType = {
    showAIInfo: false,
    installPWA: false,
    showOver18: false,
    showGenderPrefer: false,
    showAdsPopup: false,
    showSideBar: false,
    loginPopupFlag: 0,
    logoutPopupFlag: 0,
};

export const popupModule: Module<PopupModuleType, any> = {
    namespaced: true,
    state: defaultState,
    mutations: {
        setShowAIInfo(state, showAIInfo: boolean) {
            state.showAIInfo = showAIInfo;
        },
        setInstallPWA(state, installPWA: boolean) {
            state.installPWA = installPWA;
        },
        setshowOver18(state, showOver18: boolean) {
            state.showOver18 = showOver18;
        },
        setShowGenderPrefer(state, showGenderPrefer: boolean) {
            state.showGenderPrefer = showGenderPrefer;
        },
        setShowAdsPopup(state, showAdsPopup: boolean) {
            state.showAdsPopup = showAdsPopup;
        },
        setShowSideBar(state, showShowSideBar: boolean) {
            state.showSideBar = showShowSideBar;
        },
        triggerLoginPopup(state) {
            state.loginPopupFlag = (state.loginPopupFlag || 0) + 1;
        },
        triggerLogoutPopup(state) {
            state.logoutPopupFlag = (state.logoutPopupFlag || 0) + 1;
        },
    },
    actions: {},
};
