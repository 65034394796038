import dayjs from 'dayjs';
import { sendLog } from '@/common/radar';
import { SafetyLocalStorage } from '@/common/storage';

export enum continueMap {
    noLimit = 3, // 无频率限制
    limit = 2, // 达到频率
    first = 1, // 首次弹窗
    error = -1, // 报错
    refuse = 0, // 拒绝
}

// 判断pwa频率
/**
 *
 * @param key 判断字段
 * @param isLimit 是否限制
 * @returns 1: 首次弹窗 0:达到频率 3:无频率限制, 继续执行
 */
export const isContinue = (key: 'install' | 'openPwa', isLimit = true) => {
    if (!isLimit) return Promise.resolve(continueMap.noLimit);
    const date = dayjs().format('YYYY-MM-DD');
    const pwaDataStr =
        SafetyLocalStorage.getItem('pwaData') ||
        JSON.stringify({
            install: '',
            openPwa: '',
        });
    const pwaData = JSON.parse(pwaDataStr);
    return new Promise((resolve, reject) => {
        // 日期相同 || 本地没有安装标记
        if (pwaData[key] === date || (key === 'install' && SafetyLocalStorage.getItem('uninstallApp') !== 'true')) {
            resolve(continueMap.limit);
        } else {
            pwaData[key] = date;
            SafetyLocalStorage.setItem('pwaData', JSON.stringify(pwaData));
            resolve(continueMap.first);
        }
    });
};

// 执行安装pwa
/**
 *
 * @param installPwa 系统安装pwa 弹窗
 * @param logType 埋点类型
 * @param isLimit 是否有频控
 * @returns 1: 安装成功 0:取消安装 -1: 报错捕获 2:达到频率控制,继续执行
 */
export const installPwaFun = async (installPwa: () => Promise<unknown>, logType: string, isLimit = true) => {
    if (isLimit) {
        const res = await isContinue('install', isLimit);
        // 频率控制
        if (res === continueMap.limit)
            return Promise.resolve({
                status: 2,
            });
    }
    sendLog({
        type: 'show',
        name: 'ADD_TO_DESKTOP',
        value: {
            install_reason: logType,
        },
    });
    return installPwa()
        .then((res: any) => {
            sendLog({
                type: 'click',
                name: 'ADD_TO_DESKTOP',
                value: {
                    install_reason: logType,
                    is_success: res ? 1 : 0,
                },
            });
            return Promise.resolve({
                status: res ? 1 : 0,
            });
        })
        .catch(() => {
            return Promise.resolve({
                status: -1,
                message: 'install err',
            });
        });
};
