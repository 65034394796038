import { getCurrentInstance } from 'vue';

export enum AppPkgName {
    BOMDIA_APP = 'bom.dia.video.imagen.frases.download',
    AI_CHAT_APP = 'ai.questionai.chat.bot',
}

export const checkIfAppEnvironment = () => {
    const { proxy }: any = getCurrentInstance() || {};
    return proxy?.$isAppEnvironment;
};

export const checkIfBomdiaApp = () => {
    const { proxy }: any = getCurrentInstance() || {};
    return proxy?.$isAppEnvironment && proxy?.$appConfig?.pkg === AppPkgName.BOMDIA_APP;
};

export const checkIfAiChatApp = () => {
    const { proxy }: any = getCurrentInstance() || {};
    return proxy?.$isAppEnvironment && proxy?.$appConfig?.pkg === AppPkgName.AI_CHAT_APP;
};
