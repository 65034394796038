import Vue from 'vue';

function getRuntimeEnv() {
    return this?.$pwaLayout || 'default';
}

Vue.prototype.$getRuntimeEnv = getRuntimeEnv;

export default ({ app }, inject) => {
    inject('$getRuntimeEnv', getRuntimeEnv);
};
