import Vue from 'vue';

import ActionSheet from 'vant/lib/action-sheet';
import Dialog from 'vant/lib/dialog';
import Toast from 'vant/lib/toast';
import Search from 'vant/lib/search';
import Skeleton from 'vant/lib/skeleton';
import Field from 'vant/lib/field';
import Tab from 'vant/lib/tab';
import Tabs from 'vant/lib/tabs';
import { Tabbar, TabbarItem } from 'vant';
// import List from 'vant/lib/list';

Vue.use(ActionSheet);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Search);
Vue.use(Skeleton);
Vue.use(Field);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tabbar);
Vue.use(TabbarItem);
// Vue.use(List);
