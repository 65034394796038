import { Module } from 'vuex';

type SingleSchema = {
    [componentName: string]: {
        [seoOrAppName: string]: Record<string, any>;
    };
};

type AllSchema = {
    [pageRouteName: string]: SingleSchema;
};

type SchemaState = {
    allSchema?: AllSchema;
    currentSchema?: SingleSchema;
};

const defaultState: () => SchemaState = () => ({
    allSchema: undefined,
    currentSchema: undefined,
});

export const schemaModule: Module<SchemaState, any> = {
    namespaced: true,
    state: defaultState,
    mutations: {
        setAllSchema(state, data: AllSchema) {
            state.allSchema = data;
        },
        setCurrentSchema(state, data: string) {
            const currentSchema = state.allSchema?.[data] || {};
            state.currentSchema = currentSchema;
        },
    },
    actions: {},
};
