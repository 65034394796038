import { AIMessageModelImpl } from '../models/UIAIMessageModel';
import { AIChatStore } from '../stores/AIChatStore';
import { AIMessageModel, AIMessageType } from '../models/AIModel';
import generateUUID from '../utils/aiTools';
import { UIAIStore } from '../stores/UIAIStore';
import { UIAIConversationService } from './UIAIConversationService';

export class AIChatService {
    private chatStore: AIChatStore;

    constructor(chatStore: AIChatStore) {
        this.chatStore = chatStore;
    }

    async sendTextMessage<T>(conversationId: string, senderId: string, content: string, attachment: T) {
        const messageId = this._createC2CMessageId();
        this._sendMessage(messageId, conversationId, senderId, content, AIMessageType.TEXT, attachment);
        console.log('social-ai-im', `send me message: ${messageId}`);
    }

    getMessageListByConversationId(conversationId?: string): AIMessageModel[] {
        if (!conversationId) return [];
        return this.chatStore.getMessageById(conversationId);
    }

    async clearMessagesByConversationId(conversationId?: string): Promise<boolean> {
        if (!conversationId) return false;
        await this.chatStore.clearMessagesByConversationId(conversationId);
        console.log('social-ai-im', `No messages to clear for conversation with ID: ${conversationId}`);
        return false;
    }

    private async _sendMessage<T>(id: string, conversationId: string, senderId: string, content: string, messageType: AIMessageType, attachment: T) {
        const message: AIMessageModel<T> = new AIMessageModelImpl(id, conversationId, senderId, content, messageType, new Date(), attachment);
        this.chatStore.addMessage(message);
        UIAIConversationService.updateLastMessage(conversationId, message);
    }

    private _createC2CMessageId(userId?: string) {
        return `Message${generateUUID()}`;
    }
}

export const UIAIChatService = new AIChatService(UIAIStore.chatStore);
