import cookies from 'js-cookie';
import axios from 'axios';
import Toast from 'vant/lib/toast';
import { getCommonParamsByKpn, getKpn } from '@/common/common-params';
import { isTiny } from '@/common/utils';
import { UserApi } from '@/api/user';
import { SafetyLocalStorage } from '@/common/storage';

export const kwai_client_id = '21144182060-h4jroop36kitfgjd8v6bl48otppponc2.apps.googleusercontent.com';
export const snack_client_id = '1085491065542-umvch4l14qrm5np356v5ft2t4dnljpuk.apps.googleusercontent.com';
export const topick_client_id = '436181982591-kkqcs8ie8tfdt2o87h9419rfr0rvl0ij.apps.googleusercontent.com';

export enum KpnClient {
    KWAI = 'KWAI',
    KWAI_BULLDOG = 'KWAI_BULLDOG',
    KWAI_ME = 'KWAI_ME',
    TOPICK = 'TOPICK',
}

export const kpnClientMap: Record<KpnClient, string> = {
    [KpnClient.KWAI]: kwai_client_id,
    [KpnClient.KWAI_BULLDOG]: snack_client_id,
    [KpnClient.KWAI_ME]: kwai_client_id,
    [KpnClient.TOPICK]: topick_client_id,
};

export const did = cookies.get('webDid');
export const getLoginPara = function ({ startup, kpn }: Record<string, any>) {
    const param = getCommonParamsByKpn(kpn, startup);

    const formData = new FormData();
    for (const key in param) {
        formData.append(key, param[key]);
    }
    return formData;
};

export const isLogin = async function () {
    const res = await UserApi.getUserInfo({});
    setCurrentUserHeadUrl(res);
    if (res?.result === 1) return true;
    return false;
};

export const setLoginCookie = function (params: any) {
    // for(let i in params.user) {
    //   cookies.set(i, i === 'headurls'? JSON.stringify(params[i]): params[i])
    // }
    cookies.set('userId', params?.user?.user_id, { expires: 30, path: '' });
    cookies.set('user_id', params?.user?.user_id, { expires: 30, path: '' });
    //   if (!isTiny()) {
    // cookies.set('did', cookies.get('webDid') || '', { expires: 30, path: '' })
    //   }
};

export const removeLoginCookie = function () {
    cookies.remove('userId');
    cookies.remove('user_id');
};

export const setCurrentUserHeadUrl = function (params: any) {
    if (params?.user?.headurls?.[0]?.url) {
        SafetyLocalStorage.setItem('CURRENT_USER_HEADURL', params?.user?.headurls?.[0]?.url ?? '');
    } else {
        SafetyLocalStorage.removeItem('CURRENT_USER_HEADURL');
    }
};

// export const tinyLoginNew = async (proxy:any) => {
//   const res = await window.$yoda?.invoke('component.login')
//   console.log('component.login', res);
//   if(res.businessCode === 1) {
//     const loginStatus = await isLogin()
//     if(loginStatus) {
//       Toast({
//         message: proxy.$root.$t('k_326761'),
//       })
//     } else {
//       Toast({
//         message: proxy.$root.$t('k_327320'),
//       })
//     }
//     proxy.$store.commit('setIsLogin', loginStatus);
//   }
//   return res
// }

export const tinyLoginMessage = async (proxy: any) => {
    window.$yoda?.invoke('event.addEventListener', {
        type: 'pwa_login',
        listener: (res: any) => {
            console.log('event.addEventListener', res);
            tinyLogiNextStep(proxy, { message: res?.token });
        },
    });
};

export const tinyLogin = async (proxy: any) => {
    proxy.$bus.emit('isLoad', true);
    try {
        const res = await window.$yoda?.invoke('ug.tinyAuthLogin', { authType: 'google' });
        proxy.$bus.emit('isLoad', false);
        if (res.businessCode === 1) return await tinyLogiNextStep(proxy, res);
        if (res.businessCode === -1001) {
            Toast({
                message: proxy.$root.$t('k_401901'),
            });
            return false;
        }
        console.log('err', res);
        Toast({
            message: res?.message,
        });
        return false;
    } catch (error) {
        proxy.$bus.emit('isLoad', false);
    }

    // .then(async (res: any) => {
    //   console.log('result: ', res);
    //   if (res.result === 1) return await tinyLogiNextStep(proxy, res);
    // }).catch((err: any) => {
    //   console.log('err', err)
    //   Toast({
    //     message: err?.message,
    //   })
    //   return false;
    // })
};
export const tinyLogiNextStep = async (proxy: any, para: any) => {
    const did = cookies.get('webDid');
    proxy.$bus.emit('isLoad', true);
    // const params = getLoginPara(proxy.$store.state.startup);

    const params = getLoginPara({
        startup: proxy.$store.state.startup,
        kpn: getKpn({ bucket: proxy.$store.state.startup.bucket, host: window.location.host }),
    });

    const res = await proxy.$axios.$$post(
        `loginApi::/rest/o/w/user/thirdPlatformLogin?access_token=${para.message || ''}&platform=google&did=${did}`,
        params
    );
    setLoginCookie(res);

    proxy.$bus.emit('isLoad', false);
    // 服务端service token
    if (res.result === 1 && res['kwaipro.pwa_st']) {
        await proxy.$axios.$$post(`loginApi::/rest/infra/sts`, {
            authToken: res['kwaipro.pwa.at'],
            sid: 'kwaipro.pwa',
            followUrl: location.href,
            setRootDomain: true,
        });
        const loginStatus = await isLogin();
        window.$yoda?.invoke('ug.tinySetCookies', {
            cookies: {
                'kwaipro.pwa_st': res['kwaipro.pwa_st'],
                'kwaipro.pwa_ph': cookies.get('kwaipro.pwa_ph'),
                userId: res.user.user_id,
                user_id: res.user.user_id,
            },
            host: location.hostname,
        });
        if (loginStatus) {
            Toast({
                message: proxy.$root.$t('k_326761'),
            });
        } else {
            Toast({
                message: proxy.$root.$t('k_327320'),
            });
        }
        proxy.$store.commit('setIsLogin', loginStatus);
    } else {
        Toast({
            message: proxy.$root.$t('k_327320'),
        });
        proxy.$store.commit('setIsLogin', await isLogin());
    }
    proxy.$bus.emit('sendPv');
};
