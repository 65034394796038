export const FirebaseSWScope = '/firebase-cloud-messaging-push-scope';
export const FirebaseSWFileName = 'firebase-messaging-sw.js';
export const FirebaseMessageDBName = 'firebase-messaging-database';
export const FirebaseMessageDBStoreName = 'firebase-messaging-store';
export const FirebaseMessageStoreKey = '1:35760235920:web:9359fdd864b26388ff7f15';
/**
 * @en firebase default public key.
 * @en if we don't provide a public key, firebase will use the default key, but we don't have private key.
 * @en we need to provide a new public key, so that we have own private key to send push by `subscrption`.
 */
export const FirebaseDefaultPublicKey = 'BDOU99-h67HcA6JeFXHbSNMu7e2yNNu3RzoMj8TM4W88jITfq7ZmPvIM1Iv-4_l2LxQcYwhqby2xGpWwzjfAnG4';
/**
 * @en push public key in this project
 */
export const PushPublicKey = 'BJ7iE3pkqYCQug2kSi-45D7yZW9TLE15tB1NtDROKp1bV5f9H-jZmYAk8-fRPxppbjCgBilnBm2HK2lT8wkIFnY';
/**
 * @en the new firebase project name.
 * @en We have 2 firebase projects now.
 * @en 1. KWAI_WEB/2. KWAI_WEB_PWA.
 */
export const FirebaseProjectName = 'KWAI_WEB_PWA';

export function uint8ArrayToBase64(arrBuffer?: ArrayBuffer | null) {
    if (!arrBuffer) {
        return;
    }

    return btoa(String.fromCharCode.apply(null, new Uint8Array(arrBuffer) as unknown as Array<number>));
}

export function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        // eslint-disable-next-line no-useless-escape
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

// eslint-disable-next-line no-undef
export const getSubscriptionInfo = async (swUrl: string, options?: RegistrationOptions) => {
    try {
        const pushRegister = await navigator.serviceWorker.register(swUrl, options);
        const subscription = await pushRegister.pushManager.getSubscription();
        const isExist = !!subscription;

        if (isExist) {
            const existSubscriptionInfo = subscription.toJSON();
            return {
                endpoint: existSubscriptionInfo?.endpoint,
                expirationTime: existSubscriptionInfo?.expirationTime ? `${existSubscriptionInfo?.expirationTime}` : undefined,
                auth: existSubscriptionInfo?.keys?.auth,
                p256dh: existSubscriptionInfo?.keys?.p256dh,
            };
        }

        const pushRegisterSubscription = await pushRegister?.pushManager?.subscribe?.({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(PushPublicKey),
        });
        const pushSubscriptionInfo = pushRegisterSubscription.toJSON();

        return {
            endpoint: pushSubscriptionInfo?.endpoint,
            expirationTime: pushSubscriptionInfo?.expirationTime ? `${pushSubscriptionInfo?.expirationTime}` : undefined,
            auth: pushSubscriptionInfo?.keys?.auth,
            p256dh: pushSubscriptionInfo?.keys?.p256dh,
        };
    } catch (e) {
        return {};
    }
};
