export const ComponentNameMap = {
    VideoDetail: 'video-detail',
    Foryou: 'foryou',
    DiscoverSearch: 'discover-search',
    AIForyou: 'ai-foryou',
    AIChatRoom: 'ai-chatroom',
    AIChatDiscover: 'ai-discover',
    AIChatInbox: 'ai-inbox',
    AIChatHistory: 'ai-history',
    AIChatCreate: 'ai-create',
    AIChatCreateVoice: 'ai-create-voice',
    AIChatMoreVoice: 'ai-more-voice',
    AIChatProfile: 'ai-profile',
};

export enum MobilePageSource {
    // foryou 页
    PwaForyou = 'pwa-foryou',
    // 视频详情页
    PwaVideoDetail = 'pwa-video-detail',
    // Discover Card
    PwaDiscoverCategoryCard = 'pwa-discover-category-card',
    // Discover User Video
    PwaDiscoverUserVideo = 'pwa-discover-uservideo',
    // 分享过来进入的视频详情页
    PwaVideoDetailShare = 'pwa-video-detail-share',
    // 搜搜内流专题页
    PwaDiscoverSearch = 'pwa-video-detail-search',
    // AI foryou 页
    PWAAIForyou = 'pwa-ai-foryou',

    PWAAIInbox = 'pwa-ai-inbox',

    PWAAChatRoom = 'pwa-ai-chatroom',

    PWAAChatDiscover = 'pwa-ai-discover',

    PWAAChatCardGuide = 'pwa-ai-card-guide',

    PWAAChatHistoryTab = 'pwa-ai-history-tab',
}

export enum MobilePageCode {
    SharePwaPageH5 = 'SHARE_PWA_PAGE_H5',
    PwaDetailPage = 'PWA_DETAIL_PAGE',
    SeoResearchResultPage = 'SEO_RESEARCH_RESULT_PAGE',
    PwaDetailPageShare = 'PWA_DETAIL_PAGE_SHARE',
    PwaDiscoverSearch = 'PWA_DISCOVER_SEARCH',
    SharePwaAIPageH5 = 'SHARE_PWA_AI_PAGE_H5',
}

// from https://docs.corp.kuaishou.com/k/home/VE-Rw2SbwSZc/fcABjJvLKKpUBYPAip008KlfD#section=h.ij8a74bzl0oi
export const MobilePageSourceCodeMap: Record<MobilePageSource, MobilePageCode | undefined> = {
    [MobilePageSource.PwaForyou]: MobilePageCode.SharePwaPageH5,
    [MobilePageSource.PwaVideoDetail]: MobilePageCode.PwaDetailPage,
    [MobilePageSource.PwaDiscoverCategoryCard]: MobilePageCode.SeoResearchResultPage,
    [MobilePageSource.PwaDiscoverUserVideo]: MobilePageCode.SeoResearchResultPage,
    [MobilePageSource.PwaVideoDetailShare]: MobilePageCode.PwaDetailPageShare,
    [MobilePageSource.PwaDiscoverSearch]: MobilePageCode.PwaDiscoverSearch,
    [MobilePageSource.PWAAIForyou]: MobilePageCode.SharePwaAIPageH5,
    [MobilePageSource.PWAAIInbox]: MobilePageCode.SharePwaAIPageH5,
    [MobilePageSource.PWAAChatRoom]: MobilePageCode.SharePwaAIPageH5,
    [MobilePageSource.PWAAChatDiscover]: MobilePageCode.SharePwaAIPageH5,
    [MobilePageSource.PWAAChatCardGuide]: MobilePageCode.SharePwaAIPageH5,
    [MobilePageSource.PWAAChatHistoryTab]: MobilePageCode.SharePwaAIPageH5,
};

// Firebase Application Config
// export const FirebaseConfig = {
//     apiKey: 'AIzaSyDV7GYYsg5pESC9yTNMZLlmGsmUoWm7Q-A',
//     authDomain: 'kwaipwazjq.firebaseapp.com',
//     projectId: 'kwaipwazjq',
//     storageBucket: 'kwaipwazjq.appspot.com',
//     messagingSenderId: '558015386050',
//     appId: '1:558015386050:web:eaa812fe4cf65b1047c65b',
//     measurementId: 'G-C36685GWCE',
// };

export const FirebaseConfig = {
    apiKey: 'AIzaSyCJ-Xaa_0x_m_UKLWh60v-Mg3wVbdVQchY',
    authDomain: 'kwaiwebpwa.firebaseapp.com',
    projectId: 'kwaiwebpwa',
    storageBucket: 'kwaiwebpwa.firebasestorage.app',
    messagingSenderId: '35760235920',
    appId: '1:35760235920:web:9359fdd864b26388ff7f15',
    measurementId: 'G-QNGYR155ZV',
};

/**
 * @zh 本项目统一使用一个 key
 */
export const PWAKConfKey = 'kibtFrontend.share.ug_web_fe_pwa_kconf';
