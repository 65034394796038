// eslint-disable-next-line import/no-extraneous-dependencies
import { Module } from 'vuex';

interface ABTestMoudleType {
    /**
     * @zh 从接口中获取实验参数
     */
    abTestParams?: {
        /**
         * @zh 每刷从接口获取的 feed 数量
         */
        FetchFeedListDefaultCount?: number;

        /**
         * @zh 保留上滑的 video 数量
         */
        HoldPreVideoCount?: number;

        /**
         * @zh 下滑 feed 最小剩余量
         */
        NextFeedListMinCount?: number;

        /**
         * @zh 下滑预加载的最大数量
         */
        NextPreLoadVideoCount?: number;

        /**
         * @zh 拉端弹窗延迟的ms
         */
        isDropLaunchApp?: boolean;
        /**
         * @zh 开启pwa
         */
        if_open_guide_to_desktop?: string;
        /**
         * @zh 自动引导安装pwa时间 秒
         */
        open_guide_seconds?: string;
        /**
         * @zh 放开安装弹窗频率
         */
        install_pwa_no_limit?: string;
        /**
         * @zh 上下滑实验
         */
        install_pwa_touch?: string;
        /**
         * @zh 安装后打开pwa 时间
         */
        openPwaafterInstall?: string;

        /**
         * @zh discover seo 标签改造实验
         */
        discover_seo_html_tag?: boolean;

        /**
         * @zh 切换 discover 新接口
         */
        new_discover_api?: boolean;

        /**
         * @zh discover 用 keywords 内联的个数实验
         */
        discover_keywords_length?: number;

        /**
         * @zh IOS feed 预加载优化实验
         */
        isNewIOSFeedStrategy?: boolean;

        /**
         * @zh 激励挂件入口展示图标 cdn
         */
        web_fission_enrty_2?: string;

        /**
         * @zh feed 场景是否命中全屏实验
         */
        is_full_screen?: boolean;

        /**
         * @zh ai 场景是否命中全屏实验
         */
        is_ai_full_screen?: boolean;

        /**
         * @xq ai 开启ai-follow
         */
        ai_follow_tab?: boolean;

        /**
         * @zh 开启 message feedback 按钮
         */
        ai_message_feedback_action?: boolean;

        /**
         * @zh 主动对话 chat tab
         */
        a_chat_tab_entry?: boolean;
    };
}

export const defaultFeedListPreLoadParams = {
    FetchFeedListDefaultCount: 4,
    HoldPreVideoCount: 1,
    NextFeedListMinCount: 1,
    NextPreLoadVideoCount: 2,
};

const defaultABTestModule: ABTestMoudleType = {
    abTestParams: {
        ...defaultFeedListPreLoadParams,
        isDropLaunchApp: false,
    },
};

export const abTestModule: Module<ABTestMoudleType, any> = {
    namespaced: true,
    state: defaultABTestModule,
    actions: {},
    mutations: {
        setABTestParams(state, data: ABTestMoudleType['abTestParams']) {
            state.abTestParams = {
                ...(state.abTestParams || {}),
                ...(data || {}),
            };
        },
    },
};
