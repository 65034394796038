
import { defineComponent, getCurrentInstance, onUnmounted, computed, onMounted, ref } from 'vue';
import Loading from '@/components/loading/index.vue';
import { genWebDidAndSessionId } from '@/common/utils';
import { launchApp, injectTabStrategyToAppIfNeeded } from '@/common/layout-common';
import { ComponentNameMap } from '@/constants/base';
import { checkIfAiChatApp, checkIfBomdiaApp } from '~/common/app';
import TopTabs from '@/pages/aichat/components/ai-top-tabs/index.vue';
import AITTabBar from '@/pages/aichat/components/ai-tabbar/index.vue';
import useAppEnvironment from '~/pages/aichat/hooks/useAppEnvironment';

const LightThemePageList = ['discover-content', 'playlist-detail', 'profile', 'topic-content', 'account-management', 'article-content'];

export default defineComponent({
    components: {
        Loading,
        TopTabs,
        AITTabBar,
    },
    setup() {
        const { proxy }: any = getCurrentInstance() || {};
        const loading = ref(false);

        const bgColor = computed(() => {
            const isLightTheme = LightThemePageList.includes(proxy?.$route?.name || '');
            if (isLightTheme) {
                return '#fff';
            }
            return 'black';
        });

        const setLoading = (isLoading: boolean) => {
            loading.value = isLoading;
        };

        const showTopTabs = computed(() => {
            const currentPath = proxy?.$route?.path;
            const regexForyouVideo = /^\/((foryou)$|@[\w-]+\/video(\/[\w-]+)?)$/;

            if (currentPath === '/' || regexForyouVideo.test(currentPath)) {
                return false;
            }

            if (checkIfBomdiaApp()) {
                return false;
            }

            const { isIOS } = useAppEnvironment();
            if (isIOS.value && currentPath !== '/aichat') {
                return false;
            }

            const { conversationModuleInitialized } = proxy?.$store?.state?.conversationModule;
            const regex = /^\/((foryou|aichat(\/history|\/discover|\/bot\/[\w-]+)?)$|@[\w-]+\/video(\/[\w-]+)?)$/;

            return currentPath === '/' || (regex.test(currentPath) && conversationModuleInitialized);
        });

        const showTabbar = computed(() => {
            if (checkIfBomdiaApp()) {
                return false;
            }
            const { isIOS } = useAppEnvironment();
            if (!isIOS.value) {
                return false;
            }
            const { conversationModuleInitialized } = proxy?.$store?.state?.conversationModule;
            const regex = /^\/((foryou|aichat(\/chatlist|\/inbox|\/history|\/discover|\/bot\/[\w-]+)?)$|@[\w-]+\/video(\/[\w-]+)?)$/;
            const currentPath = proxy?.$route?.path;
            return currentPath === '/' || (regex.test(currentPath) && conversationModuleInitialized);
        });

        onMounted(async () => {
            genWebDidAndSessionId();
            injectTabStrategyToAppIfNeeded();

            proxy?.$store?.commit?.('conversationModule/initialize');
            // launchApp调用时获取不到this 无法拿到bucket值
            sessionStorage.setItem('bucket', proxy?.$store?.state?.startup?.bucket);
            // 全局注册拉起方法
            (proxy as Record<string, any>)?.$bus?.on?.('launchApp', launchApp);
            (proxy as Record<string, any>)?.$bus?.on?.('isLoad', setLoading);
        });

        onUnmounted(() => {
            (proxy as Record<string, any>)?.$bus?.off?.('launchApp', launchApp);
            (proxy as Record<string, any>)?.$bus?.off?.('isLoad', setLoading);
        });

        return {
            bgColor,
            loading,
            ComponentNameMap,
            showTopTabs,
            showTabbar,
        };
    },
});
