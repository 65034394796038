import { AIConversationModel, AIConversationID, AIMessageID, AIUserID, AIMessageModel } from './AIModel';
import { UIAIStore } from '../stores/UIAIStore';

export class AIConversationModelImpl<T = any> implements AIConversationModel<T> {
    id: AIConversationID;

    participants: AIUserID[];

    lastMessage?: AIMessageModel;

    updatedAt: Date;

    attachment?: T;

    constructor(id: AIConversationID, participants: AIUserID[], updatedAt: Date, attachment: T) {
        this.id = id;
        this.participants = participants;
        this.updatedAt = updatedAt;
        this.attachment = attachment;
    }

    getAttachment(): T {
        return this.attachment as T;
    }

    getLastMessage(): AIMessageModel<any> | undefined {
        return this.lastMessage;
    }

    getParticipants(): AIUserID[] {
        return this.participants;
    }

    async deleteConversation(): Promise<boolean> {
        await UIAIStore.conversationStore.deleteConversation(this.id);
        console.log('social-ai-im', `Deleted conversation and related messages with ID: ${this.id}`);
        return true;
    }
}

export default AIConversationModelImpl;
