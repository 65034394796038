/* eslint-disable import/no-extraneous-dependencies */
import { NuxtAxiosInstance } from 'vue/types/vue';
import { AxiosRequestConfig } from 'axios';
import { CdnWithUrl, PWAFromPage, ResponseBase } from './base';

import axiosInstance from './request';

/**
 * @zh 用户基础信息
 */
export interface UserInfo {
    following?: boolean;
    user_id?: number;
    user_name?: string;
    user_sex?: string;
    user_text?: string;
    headurl?: string;
    headurls?: CdnWithUrl[];
    verified?: boolean;
    kwai_id?: string;
    eid?: string;
}

/**
 * @zh 通过登录接口获取到的聚合过的用户信息
 */
export interface UserInfoByLogin {
    user?: UserInfo;
    user_id?: number | string;
    userId?: number | string;
    is_new?: boolean;
    user_name?: string;
    headurl?: string;
    user_sex?: string;
    user_profile_bg_url?: string;
    user_text?: string;
    /**
     * @zh 安全风控相关字段
     */
    'kwaipro.pwa_ph'?: string;
    'kwaipro.pwa_st'?: string;
    'kwaipro.pwa.at'?: string;
}

type GetUserInfoRequest = {};

interface GetUserInfoResponse extends ResponseBase {
    user?: UserInfo;
}

export interface ReportFirebaseTokenRequest {
    push_token?: string;
    timestamp?: number;
    domain?: string;
    url?: string;
    ua?: string;
    web_did?: string;
    kwai_id?: string;
    user_id_str?: string;
    kpn?: string;
    session_id?: string;
    countryInfo?: string;
    bucket?: string;
    subscription?: {
        endpoint?: string;
        auth?: string;
        p256dh?: string;
        expirationTime?: string;
    };
    extra_params?: Record<string, any>;
}

type ReportFirebaseTokenResponse = ResponseBase;

interface GetProfileFeedRequest {
    userId?: string;
    albumId?: string;
    fromPage?: PWAFromPage;
    beforePage?: string;
    pcursor?: string;
    count?: number;
}

interface GetProfileInfoRequest {
    userId?: string;
    mcc?: any;
    deviceId?: string;
    fromPhoto?: boolean;
}

interface GetFeedAlbumRequest {
    albumId?: string;
    pcursor?: string;
    count?: number;
}

interface GetFeedAlbumResponse extends ResponseBase {
    album?: {
        albumId?: number;
        albumIdStr?: string;
        authorId?: number;
        authorName?: string;
        averagePlayTime?: number;
        count?: number;
        firstPhotoCaption?: string;
        firstPhotoCover?: Array<CdnWithUrl>;
        lastUpdateTime?: number;
        subscribed?: boolean;
        subscribedCount?: number;
        totalPlayTime?: number;
        type?: number;
        viewCount?: number;
    };
    cursor?: string;
    feeds?: Array<Record<string, any>>;
}

export class UserApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        this.$axios = axiosInstance;
    }

    async getUserInfo(data: GetUserInfoRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<GetUserInfoResponse>('/rest/o/w/user/login/visitor/info', data, config);

        return res;
    }

    async reportFirebaseToken(data: ReportFirebaseTokenRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<ReportFirebaseTokenResponse>('/rest/o/w/pwa/token/report', data, config);
        return res;
    }

    async getFeedAlbum(data: GetFeedAlbumRequest, config?: AxiosRequestConfig) {
        const path = `${process.server ? 'overseaAPI::' : ''}/rest/o/w/pwa/feed/album`;
        const res = await this.$axios?.$$post?.<GetFeedAlbumResponse>(path, data, config);
        return res;
    }

    async getPWAProfileFeed(data: GetProfileFeedRequest, config?: AxiosRequestConfig) {
        const path = `${process.server ? 'overseaAPI::' : ''}/rest/o/w/pwa/feed/profile`;
        /**
         * @zh 历史逻辑，先 Record<string, any>
         */
        const res = await this.$axios?.$$post?.<Record<string, any>>(path, data, config);
        return res;
    }

    async getPWAProfileInfo(data: GetProfileInfoRequest, config?: AxiosRequestConfig) {
        const path = `${process.server ? 'overseaAPI::' : ''}/rest/o/w/pwa/profile`;
        /**
         * @zh 历史逻辑，先 Record<string, any>
         */
        const res = await this.$axios?.$$post?.<Record<string, any>>(path, data, config);
        return res;
    }
}

export const UserApi = new UserApiService();
