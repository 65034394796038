import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c34c169c = () => interopDefault(import('../pages/account-management/index.vue' /* webpackChunkName: "pages/account-management/index" */))
const _6a56820f = () => interopDefault(import('../pages/aichat/index.vue' /* webpackChunkName: "pages/aichat/index" */))
const _4a33092c = () => interopDefault(import('../pages/channel/index.vue' /* webpackChunkName: "pages/channel/index" */))
const _e235b990 = () => interopDefault(import('../pages/direct/index.vue' /* webpackChunkName: "pages/direct/index" */))
const _828fe99a = () => interopDefault(import('../pages/discover-search/index.vue' /* webpackChunkName: "pages/discover-search/index" */))
const _2e8b9489 = () => interopDefault(import('../pages/e-commerce-seo-search/index.vue' /* webpackChunkName: "pages/e-commerce-seo-search/index" */))
const _00a251c5 = () => interopDefault(import('../pages/foryou/index.vue' /* webpackChunkName: "pages/foryou/index" */))
const _0fdded24 = () => interopDefault(import('../pages/message/index.vue' /* webpackChunkName: "pages/message/index" */))
const _b78fbad6 = () => interopDefault(import('../pages/node-service-stability/index.vue' /* webpackChunkName: "pages/node-service-stability/index" */))
const _966e55a2 = () => interopDefault(import('../pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _3e7b02ab = () => interopDefault(import('../pages/old-foryou/index.vue' /* webpackChunkName: "pages/old-foryou/index" */))
const _19567090 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _34af4374 = () => interopDefault(import('../pages/pwa-share-new/index.vue' /* webpackChunkName: "pages/pwa-share-new/index" */))
const _104571ab = () => interopDefault(import('../pages/aichat/chat.vue' /* webpackChunkName: "pages/aichat/chat" */))
const _1de6e1c9 = () => interopDefault(import('../pages/aichat/chatlist.vue' /* webpackChunkName: "pages/aichat/chatlist" */))
const _082e0a8f = () => interopDefault(import('../pages/aichat/create.vue' /* webpackChunkName: "pages/aichat/create" */))
const _5737cff4 = () => interopDefault(import('../pages/aichat/create-voice.vue' /* webpackChunkName: "pages/aichat/create-voice" */))
const _431f5f88 = () => interopDefault(import('../pages/aichat/discover.vue' /* webpackChunkName: "pages/aichat/discover" */))
const _329a3791 = () => interopDefault(import('../pages/aichat/history.vue' /* webpackChunkName: "pages/aichat/history" */))
const _119a81c3 = () => interopDefault(import('../pages/aichat/inbox.vue' /* webpackChunkName: "pages/aichat/inbox" */))
const _2cd73cf8 = () => interopDefault(import('../pages/aichat/message.vue' /* webpackChunkName: "pages/aichat/message" */))
const _3eb901cd = () => interopDefault(import('../pages/aichat/more-voice.vue' /* webpackChunkName: "pages/aichat/more-voice" */))
const _a5c3988c = () => interopDefault(import('../pages/aichat/myprofile.vue' /* webpackChunkName: "pages/aichat/myprofile" */))
const _158d03c5 = () => interopDefault(import('../pages/aichat/policy.vue' /* webpackChunkName: "pages/aichat/policy" */))
const _29afe2c7 = () => interopDefault(import('../pages/aichat/report.vue' /* webpackChunkName: "pages/aichat/report" */))
const _06278594 = () => interopDefault(import('../pages/discover/discoverContent.vue' /* webpackChunkName: "pages/discover/discoverContent" */))
const _1d16ac8f = () => interopDefault(import('../pages/pwa-share-new/type.ts' /* webpackChunkName: "pages/pwa-share-new/type" */))
const _4ca00d00 = () => interopDefault(import('../pages/pwa-share-new/utils.ts' /* webpackChunkName: "pages/pwa-share-new/utils" */))
const _c2fe8212 = () => interopDefault(import('../pages/aichat/components/ai-actions-bar/index.vue' /* webpackChunkName: "pages/aichat/components/ai-actions-bar/index" */))
const _85bfaf7a = () => interopDefault(import('../pages/aichat/components/ai-ads-popup/index.vue' /* webpackChunkName: "pages/aichat/components/ai-ads-popup/index" */))
const _3f0d5e34 = () => interopDefault(import('../pages/aichat/components/ai-age-confirm/index.vue' /* webpackChunkName: "pages/aichat/components/ai-age-confirm/index" */))
const _25151c9a = () => interopDefault(import('../pages/aichat/components/ai-audio-player/index.vue' /* webpackChunkName: "pages/aichat/components/ai-audio-player/index" */))
const _09b12d9a = () => interopDefault(import('../pages/aichat/components/ai-base-layout/index.vue' /* webpackChunkName: "pages/aichat/components/ai-base-layout/index" */))
const _0e76123a = () => interopDefault(import('../pages/aichat/components/ai-chat/index.vue' /* webpackChunkName: "pages/aichat/components/ai-chat/index" */))
const _4005fa0b = () => interopDefault(import('../pages/aichat/components/ai-discover/index.vue' /* webpackChunkName: "pages/aichat/components/ai-discover/index" */))
const _27a6a0e1 = () => interopDefault(import('../pages/aichat/components/ai-feed-list/index.vue' /* webpackChunkName: "pages/aichat/components/ai-feed-list/index" */))
const _7d1ce05e = () => interopDefault(import('../pages/aichat/components/ai-full-screen/index.vue' /* webpackChunkName: "pages/aichat/components/ai-full-screen/index" */))
const _41d3f082 = () => interopDefault(import('../pages/aichat/components/ai-gender-popup/index.vue' /* webpackChunkName: "pages/aichat/components/ai-gender-popup/index" */))
const _2ebbb010 = () => interopDefault(import('../pages/aichat/components/ai-info/index.vue' /* webpackChunkName: "pages/aichat/components/ai-info/index" */))
const _373aee2f = () => interopDefault(import('../pages/aichat/components/ai-info-popup/index.vue' /* webpackChunkName: "pages/aichat/components/ai-info-popup/index" */))
const _1d803e08 = () => interopDefault(import('../pages/aichat/components/ai-input-box/index.vue' /* webpackChunkName: "pages/aichat/components/ai-input-box/index" */))
const _4d30b59d = () => interopDefault(import('../pages/aichat/components/ai-lead-chat/index.vue' /* webpackChunkName: "pages/aichat/components/ai-lead-chat/index" */))
const _52563200 = () => interopDefault(import('../pages/aichat/components/ai-tabbar/index.vue' /* webpackChunkName: "pages/aichat/components/ai-tabbar/index" */))
const _aaf831ea = () => interopDefault(import('../pages/aichat/components/ai-to-desktop-popup/index.vue' /* webpackChunkName: "pages/aichat/components/ai-to-desktop-popup/index" */))
const _5882d1cf = () => interopDefault(import('../pages/aichat/components/ai-top-bar/index.vue' /* webpackChunkName: "pages/aichat/components/ai-top-bar/index" */))
const _2a8676d8 = () => interopDefault(import('../pages/aichat/components/ai-top-tabs/index.vue' /* webpackChunkName: "pages/aichat/components/ai-top-tabs/index" */))
const _893a7dd0 = () => interopDefault(import('../pages/aichat/components/ai-watermark/index.vue' /* webpackChunkName: "pages/aichat/components/ai-watermark/index" */))
const _6e65f142 = () => interopDefault(import('../pages/aichat/components/left-menu/index.vue' /* webpackChunkName: "pages/aichat/components/left-menu/index" */))
const _a1852b82 = () => interopDefault(import('../pages/aichat/components/load/index.vue' /* webpackChunkName: "pages/aichat/components/load/index" */))
const _f102f518 = () => interopDefault(import('../pages/aichat/components/message-action-popup/index.vue' /* webpackChunkName: "pages/aichat/components/message-action-popup/index" */))
const _142ef3c3 = () => interopDefault(import('../pages/aichat/components/message-feedback-popup/index.vue' /* webpackChunkName: "pages/aichat/components/message-feedback-popup/index" */))
const _fbc32f94 = () => interopDefault(import('../pages/aichat/components/side-bar/index.vue' /* webpackChunkName: "pages/aichat/components/side-bar/index" */))
const _7447e424 = () => interopDefault(import('../pages/aichat/hooks/useAppEnvironment.ts' /* webpackChunkName: "pages/aichat/hooks/useAppEnvironment" */))
const _638875e1 = () => interopDefault(import('../pages/aichat/hooks/useInstallPWA.ts' /* webpackChunkName: "pages/aichat/hooks/useInstallPWA" */))
const _2ecd0d92 = () => interopDefault(import('../pages/aichat/scripts/ai-chat.ts' /* webpackChunkName: "pages/aichat/scripts/ai-chat" */))
const _9fdd9e16 = () => interopDefault(import('../pages/aichat/scripts/ai-chat-handler.ts' /* webpackChunkName: "pages/aichat/scripts/ai-chat-handler" */))
const _910eabdc = () => interopDefault(import('../pages/aichat/scripts/discoverLogger.ts' /* webpackChunkName: "pages/aichat/scripts/discoverLogger" */))
const _64c6e7ce = () => interopDefault(import('../pages/aichat/scripts/inboxLogger.ts' /* webpackChunkName: "pages/aichat/scripts/inboxLogger" */))
const _3dee69fb = () => interopDefault(import('../pages/aichat/scripts/logger.ts' /* webpackChunkName: "pages/aichat/scripts/logger" */))
const _dfbf23d8 = () => interopDefault(import('../pages/article/components/article-action/index.vue' /* webpackChunkName: "pages/article/components/article-action/index" */))
const _1c81626f = () => interopDefault(import('../pages/article/components/article-detail/index.vue' /* webpackChunkName: "pages/article/components/article-detail/index" */))
const _544cfedc = () => interopDefault(import('../pages/article/models/main.ts' /* webpackChunkName: "pages/article/models/main" */))
const _967c2254 = () => interopDefault(import('../pages/direct/components/direct-open-app/index.vue' /* webpackChunkName: "pages/direct/components/direct-open-app/index" */))
const _555dab40 = () => interopDefault(import('../pages/discover/components/ai-content-item/index.vue' /* webpackChunkName: "pages/discover/components/ai-content-item/index" */))
const _7fde13f5 = () => interopDefault(import('../pages/discover/components/app-whatsapp-status/index.vue' /* webpackChunkName: "pages/discover/components/app-whatsapp-status/index" */))
const _c95703c8 = () => interopDefault(import('../pages/discover/components/caption-item/index.vue' /* webpackChunkName: "pages/discover/components/caption-item/index" */))
const _7d403a38 = () => interopDefault(import('../pages/discover/components/expand-words/index.vue' /* webpackChunkName: "pages/discover/components/expand-words/index" */))
const _e825e3c0 = () => interopDefault(import('../pages/discover/components/list/index.vue' /* webpackChunkName: "pages/discover/components/list/index" */))
const _29cbe6e2 = () => interopDefault(import('../pages/discover/components/picture-action-item/index.vue' /* webpackChunkName: "pages/discover/components/picture-action-item/index" */))
const _75710fc1 = () => interopDefault(import('../pages/discover/components/picture-card/index.vue' /* webpackChunkName: "pages/discover/components/picture-card/index" */))
const _086bf392 = () => interopDefault(import('../pages/discover/components/user-info-item/index.vue' /* webpackChunkName: "pages/discover/components/user-info-item/index" */))
const _1c895ae8 = () => interopDefault(import('../pages/discover/components/video-action-item/index.vue' /* webpackChunkName: "pages/discover/components/video-action-item/index" */))
const _5a1c4738 = () => interopDefault(import('../pages/discover/components/video-card/index.vue' /* webpackChunkName: "pages/discover/components/video-card/index" */))
const _514493a0 = () => interopDefault(import('../pages/e-commerce-seo-search/components/header/index.vue' /* webpackChunkName: "pages/e-commerce-seo-search/components/header/index" */))
const _18fbaf00 = () => interopDefault(import('../pages/foryou/components/swiperable-tabs/index.vue' /* webpackChunkName: "pages/foryou/components/swiperable-tabs/index" */))
const _0c741c10 = () => interopDefault(import('../pages/picture/scripts/app.ts' /* webpackChunkName: "pages/picture/scripts/app" */))
const _17e1b63c = () => interopDefault(import('../pages/picture/scripts/seo.ts' /* webpackChunkName: "pages/picture/scripts/seo" */))
const _38ae0a8a = () => interopDefault(import('../pages/playlist/components/card/index.vue' /* webpackChunkName: "pages/playlist/components/card/index" */))
const _c2ebd26e = () => interopDefault(import('../pages/playlist/components/list/index.vue' /* webpackChunkName: "pages/playlist/components/list/index" */))
const _8e8700a0 = () => interopDefault(import('../pages/playlist/hooks/useList.ts' /* webpackChunkName: "pages/playlist/hooks/useList" */))
const _28e3a84c = () => interopDefault(import('../pages/pwa-share-new/components/action-bar/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/action-bar/index" */))
const _c029e20c = () => interopDefault(import('../pages/pwa-share-new/components/action-list-popup/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/action-list-popup/index" */))
const _897fb354 = () => interopDefault(import('../pages/pwa-share-new/components/ai-ad-guide-card/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/ai-ad-guide-card/index" */))
const _2e5e59e0 = () => interopDefault(import('../pages/pwa-share-new/components/ai-chat-card/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/ai-chat-card/index" */))
const _2c4376a5 = () => interopDefault(import('../pages/pwa-share-new/components/comment/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/comment/index" */))
const _3e67011a = () => interopDefault(import('../pages/pwa-share-new/components/feed-list/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/feed-list/index" */))
const _57f24bbd = () => interopDefault(import('../pages/pwa-share-new/components/google-ad/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/google-ad/index" */))
const _7f2a9a00 = () => interopDefault(import('../pages/pwa-share-new/components/incentive-pendant/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/incentive-pendant/index" */))
const _339816b7 = () => interopDefault(import('../pages/pwa-share-new/components/loading-dark/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/loading-dark/index" */))
const _45c12efe = () => interopDefault(import('../pages/pwa-share-new/components/lumina-ad/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/lumina-ad/index" */))
const _1765c5c0 = () => interopDefault(import('../pages/pwa-share-new/components/slider-guide/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/slider-guide/index" */))
const _59134930 = () => interopDefault(import('../pages/pwa-share-new/components/slider-guide2/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/slider-guide2/index" */))
const _26f3fa24 = () => interopDefault(import('../pages/pwa-share-new/components/topbar/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/topbar/index" */))
const _2496a610 = () => interopDefault(import('../pages/pwa-share-new/components/video-info/index.vue' /* webpackChunkName: "pages/pwa-share-new/components/video-info/index" */))
const _202c5c74 = () => interopDefault(import('../pages/topic/components/load/index.vue' /* webpackChunkName: "pages/topic/components/load/index" */))
const _3acc3b1f = () => interopDefault(import('../pages/topic/components/picture-card/index.vue' /* webpackChunkName: "pages/topic/components/picture-card/index" */))
const _026fbe0f = () => interopDefault(import('../pages/topic/components/tyml-card/index.vue' /* webpackChunkName: "pages/topic/components/tyml-card/index" */))
const _c149743e = () => interopDefault(import('../pages/topic/components/utils.ts' /* webpackChunkName: "pages/topic/components/utils" */))
const _013c4742 = () => interopDefault(import('../pages/topic/components/video-card/index.vue' /* webpackChunkName: "pages/topic/components/video-card/index" */))
const _f018445e = () => interopDefault(import('../pages/topic/components/water-fall/index.vue' /* webpackChunkName: "pages/topic/components/water-fall/index" */))
const _915aed32 = () => interopDefault(import('../pages/user/components/collection-swipper/index.vue' /* webpackChunkName: "pages/user/components/collection-swipper/index" */))
const _11f9e582 = () => interopDefault(import('../pages/user/components/list/index.vue' /* webpackChunkName: "pages/user/components/list/index" */))
const _1fbab815 = () => interopDefault(import('../pages/user/components/nav/index.vue' /* webpackChunkName: "pages/user/components/nav/index" */))
const _39cc394f = () => interopDefault(import('../pages/user/components/post-card/index.vue' /* webpackChunkName: "pages/user/components/post-card/index" */))
const _217aa2a8 = () => interopDefault(import('../pages/user/components/user-profile-base-info/index.vue' /* webpackChunkName: "pages/user/components/user-profile-base-info/index" */))
const _8990c272 = () => interopDefault(import('../pages/user/hooks/useInfo.ts' /* webpackChunkName: "pages/user/hooks/useInfo" */))
const _521b8cd7 = () => interopDefault(import('../pages/user/hooks/useList.ts' /* webpackChunkName: "pages/user/hooks/useList" */))
const _2452141e = () => interopDefault(import('../pages/user/hooks/useNoticePopup.ts' /* webpackChunkName: "pages/user/hooks/useNoticePopup" */))
const _36a6552c = () => interopDefault(import('../pages/aichat/components/ai-audio-player/audio-text.vue' /* webpackChunkName: "pages/aichat/components/ai-audio-player/audio-text" */))
const _31e8cd83 = () => interopDefault(import('../pages/aichat/components/ai-chat/type.ts' /* webpackChunkName: "pages/aichat/components/ai-chat/type" */))
const _5efe2ce2 = () => interopDefault(import('../pages/aichat/components/ai-discover/tab-card/index.vue' /* webpackChunkName: "pages/aichat/components/ai-discover/tab-card/index" */))
const _178401a1 = () => interopDefault(import('../pages/aichat/components/ai-discover/tab-page/index.vue' /* webpackChunkName: "pages/aichat/components/ai-discover/tab-page/index" */))
const _0fe39251 = () => interopDefault(import('../pages/aichat/components/ai-feed/ai-feed.vue' /* webpackChunkName: "pages/aichat/components/ai-feed/ai-feed" */))
const _1c79dab0 = () => interopDefault(import('../pages/aichat/components/ai-profile/post-card.vue' /* webpackChunkName: "pages/aichat/components/ai-profile/post-card" */))
const _213f3e12 = () => interopDefault(import('../pages/aichat/components/bot-create/add-tag.vue' /* webpackChunkName: "pages/aichat/components/bot-create/add-tag" */))
const _6f3a708e = () => interopDefault(import('../pages/aichat/components/bot-create/add-tag-dialog.vue' /* webpackChunkName: "pages/aichat/components/bot-create/add-tag-dialog" */))
const _2a25493e = () => interopDefault(import('../pages/aichat/components/bot-create/avatar-upload.vue' /* webpackChunkName: "pages/aichat/components/bot-create/avatar-upload" */))
const _6a6a89b2 = () => interopDefault(import('../pages/aichat/components/bot-create/background-input.vue' /* webpackChunkName: "pages/aichat/components/bot-create/background-input" */))
const _2550258a = () => interopDefault(import('../pages/aichat/components/bot-create/draft-dialog.vue' /* webpackChunkName: "pages/aichat/components/bot-create/draft-dialog" */))
const _28e44f5e = () => interopDefault(import('../pages/aichat/components/bot-create/gender-select.vue' /* webpackChunkName: "pages/aichat/components/bot-create/gender-select" */))
const _0c7a62bd = () => interopDefault(import('../pages/aichat/components/bot-create/greeting-input.vue' /* webpackChunkName: "pages/aichat/components/bot-create/greeting-input" */))
const _e152f09c = () => interopDefault(import('../pages/aichat/components/bot-create/intro-input.vue' /* webpackChunkName: "pages/aichat/components/bot-create/intro-input" */))
const _5f226e0f = () => interopDefault(import('../pages/aichat/components/bot-create/name-input.vue' /* webpackChunkName: "pages/aichat/components/bot-create/name-input" */))
const _b6dc9ffa = () => interopDefault(import('../pages/aichat/components/bot-create/permission-select.vue' /* webpackChunkName: "pages/aichat/components/bot-create/permission-select" */))
const _6569bf84 = () => interopDefault(import('../pages/aichat/components/bot-create/protocol-agree.vue' /* webpackChunkName: "pages/aichat/components/bot-create/protocol-agree" */))
const _6fcd54fe = () => interopDefault(import('../pages/aichat/scripts/ai-im/utils/index.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/utils/index" */))
const _3a5f47c0 = () => interopDefault(import('../pages/discover/components/user-info-item/user-info-item.vue' /* webpackChunkName: "pages/discover/components/user-info-item/user-info-item" */))
const _3a307e55 = () => interopDefault(import('../pages/discover/components/video-action-item/template.config.ts' /* webpackChunkName: "pages/discover/components/video-action-item/template.config" */))
const _864e4c9c = () => interopDefault(import('../pages/discover/components/video-action-item/video-action-item.vue' /* webpackChunkName: "pages/discover/components/video-action-item/video-action-item" */))
const _596ae100 = () => interopDefault(import('../pages/discover/components/video-card/video-card.vue' /* webpackChunkName: "pages/discover/components/video-card/video-card" */))
const _06f3a7c0 = () => interopDefault(import('../pages/e-commerce-seo-search/components/header/topbar.vue' /* webpackChunkName: "pages/e-commerce-seo-search/components/header/topbar" */))
const _50065979 = () => interopDefault(import('../pages/pwa-share-new/components/ai-ad-guide-card/mark.vue' /* webpackChunkName: "pages/pwa-share-new/components/ai-ad-guide-card/mark" */))
const _34e8a40e = () => interopDefault(import('../pages/pwa-share-new/components/feed-list/feed-list.vue' /* webpackChunkName: "pages/pwa-share-new/components/feed-list/feed-list" */))
const _4693a5ce = () => interopDefault(import('../pages/pwa-share-new/components/feed-list/ios-feed-list.vue' /* webpackChunkName: "pages/pwa-share-new/components/feed-list/ios-feed-list" */))
const _dc4713a0 = () => interopDefault(import('../pages/pwa-share-new/components/video-info/unmuted.vue' /* webpackChunkName: "pages/pwa-share-new/components/video-info/unmuted" */))
const _2149b97b = () => interopDefault(import('../pages/user/components/user-profile-base-info/useProfileInfo.ts' /* webpackChunkName: "pages/user/components/user-profile-base-info/useProfileInfo" */))
const _ddd6ceae = () => interopDefault(import('../pages/aichat/components/ai-actions-bar/scripts/ai_app.ts' /* webpackChunkName: "pages/aichat/components/ai-actions-bar/scripts/ai_app" */))
const _8e00edf6 = () => interopDefault(import('../pages/aichat/components/ai-actions-bar/scripts/ai_common.ts' /* webpackChunkName: "pages/aichat/components/ai-actions-bar/scripts/ai_common" */))
const _a19f4626 = () => interopDefault(import('../pages/aichat/components/ai-actions-bar/scripts/ai_seo.ts' /* webpackChunkName: "pages/aichat/components/ai-actions-bar/scripts/ai_seo" */))
const _56df666b = () => interopDefault(import('../pages/aichat/components/ai-audio-player/scripts/audio_cache.ts' /* webpackChunkName: "pages/aichat/components/ai-audio-player/scripts/audio_cache" */))
const _f2c65d86 = () => interopDefault(import('../pages/aichat/components/ai-audio-player/scripts/audio_text_cache.ts' /* webpackChunkName: "pages/aichat/components/ai-audio-player/scripts/audio_text_cache" */))
const _224bd256 = () => interopDefault(import('../pages/aichat/components/ai-chat-inbox/components/conversation-item/index.vue' /* webpackChunkName: "pages/aichat/components/ai-chat-inbox/components/conversation-item/index" */))
const _65df7efe = () => interopDefault(import('../pages/aichat/components/ai-chat-inbox/components/likeBtn/index.vue' /* webpackChunkName: "pages/aichat/components/ai-chat-inbox/components/likeBtn/index" */))
const _292362fb = () => interopDefault(import('../pages/aichat/components/ai-chat-inbox/components/tabs/index.vue' /* webpackChunkName: "pages/aichat/components/ai-chat-inbox/components/tabs/index" */))
const _3a91d51a = () => interopDefault(import('../pages/aichat/components/ai-chat/messages-lists/message-intro.vue' /* webpackChunkName: "pages/aichat/components/ai-chat/messages-lists/message-intro" */))
const _1412fcf6 = () => interopDefault(import('../pages/aichat/components/ai-chat/messages-lists/message-text.vue' /* webpackChunkName: "pages/aichat/components/ai-chat/messages-lists/message-text" */))
const _0d7fbc90 = () => interopDefault(import('../pages/aichat/components/ai-input-box/components/recording-animation/index.vue' /* webpackChunkName: "pages/aichat/components/ai-input-box/components/recording-animation/index" */))
const _e5450498 = () => interopDefault(import('../pages/aichat/components/ai-input-box/script/ai_message_processor.ts' /* webpackChunkName: "pages/aichat/components/ai-input-box/script/ai_message_processor" */))
const _435aaefd = () => interopDefault(import('../pages/aichat/components/ai-input-box/script/speech_recognition.ts' /* webpackChunkName: "pages/aichat/components/ai-input-box/script/speech_recognition" */))
const _c6686898 = () => interopDefault(import('../pages/aichat/components/ai-input-box/script/vibrate.ts' /* webpackChunkName: "pages/aichat/components/ai-input-box/script/vibrate" */))
const _7631e86b = () => interopDefault(import('../pages/aichat/components/bot-create/scripts/bot-draft-handler.ts' /* webpackChunkName: "pages/aichat/components/bot-create/scripts/bot-draft-handler" */))
const _4cb0afb2 = () => interopDefault(import('../pages/aichat/components/bot-create/scripts/utils.ts' /* webpackChunkName: "pages/aichat/components/bot-create/scripts/utils" */))
const _4f2ee2ae = () => interopDefault(import('../pages/aichat/scripts/ai-im/models/AIModel.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/models/AIModel" */))
const _456c1520 = () => interopDefault(import('../pages/aichat/scripts/ai-im/models/UIAIConversationModel.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/models/UIAIConversationModel" */))
const _4ece80d2 = () => interopDefault(import('../pages/aichat/scripts/ai-im/models/UIAIMessageModel.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/models/UIAIMessageModel" */))
const _c8d8f530 = () => interopDefault(import('../pages/aichat/scripts/ai-im/models/UIAIUserModel.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/models/UIAIUserModel" */))
const _049c4835 = () => interopDefault(import('../pages/aichat/scripts/ai-im/services/UIAIChatService.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/services/UIAIChatService" */))
const _53e422a0 = () => interopDefault(import('../pages/aichat/scripts/ai-im/services/UIAIConversationService.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/services/UIAIConversationService" */))
const _c9758b98 = () => interopDefault(import('../pages/aichat/scripts/ai-im/services/UIUserServies.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/services/UIUserServies" */))
const _6b1798a1 = () => interopDefault(import('../pages/aichat/scripts/ai-im/stores/AIChatStore.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/stores/AIChatStore" */))
const _436e8c68 = () => interopDefault(import('../pages/aichat/scripts/ai-im/stores/AIConversationStore.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/stores/AIConversationStore" */))
const _5330afe2 = () => interopDefault(import('../pages/aichat/scripts/ai-im/stores/AIStoreType.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/stores/AIStoreType" */))
const _0405ce14 = () => interopDefault(import('../pages/aichat/scripts/ai-im/stores/AIUserStore.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/stores/AIUserStore" */))
const _3e9c7006 = () => interopDefault(import('../pages/aichat/scripts/ai-im/stores/UIAIStore.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/stores/UIAIStore" */))
const _267072a0 = () => interopDefault(import('../pages/aichat/scripts/ai-im/utils/aiTools.ts' /* webpackChunkName: "pages/aichat/scripts/ai-im/utils/aiTools" */))
const _174203e1 = () => interopDefault(import('../pages/discover/components/caption-item/scripts/app.ts' /* webpackChunkName: "pages/discover/components/caption-item/scripts/app" */))
const _355dc825 = () => interopDefault(import('../pages/discover/components/caption-item/scripts/seo.ts' /* webpackChunkName: "pages/discover/components/caption-item/scripts/seo" */))
const _21faf564 = () => interopDefault(import('../pages/discover/components/picture-action-item/scripts/app.ts' /* webpackChunkName: "pages/discover/components/picture-action-item/scripts/app" */))
const _63851e40 = () => interopDefault(import('../pages/discover/components/picture-action-item/scripts/common.ts' /* webpackChunkName: "pages/discover/components/picture-action-item/scripts/common" */))
const _0d1e4992 = () => interopDefault(import('../pages/discover/components/picture-action-item/scripts/seo.ts' /* webpackChunkName: "pages/discover/components/picture-action-item/scripts/seo" */))
const _531e7571 = () => interopDefault(import('../pages/discover/components/video-action-item/scripts/app.ts' /* webpackChunkName: "pages/discover/components/video-action-item/scripts/app" */))
const _32b3173d = () => interopDefault(import('../pages/discover/components/video-action-item/scripts/common.ts' /* webpackChunkName: "pages/discover/components/video-action-item/scripts/common" */))
const _713a39b5 = () => interopDefault(import('../pages/discover/components/video-action-item/scripts/seo.ts' /* webpackChunkName: "pages/discover/components/video-action-item/scripts/seo" */))
const _aef4909e = () => interopDefault(import('../pages/pwa-share-new/components/action-bar/scripts/app.ts' /* webpackChunkName: "pages/pwa-share-new/components/action-bar/scripts/app" */))
const _a3eca606 = () => interopDefault(import('../pages/pwa-share-new/components/action-bar/scripts/common.ts' /* webpackChunkName: "pages/pwa-share-new/components/action-bar/scripts/common" */))
const _72bd0816 = () => interopDefault(import('../pages/pwa-share-new/components/action-bar/scripts/seo.ts' /* webpackChunkName: "pages/pwa-share-new/components/action-bar/scripts/seo" */))
const _60ce8f87 = () => interopDefault(import('../pages/pwa-share-new/components/ai-chat-card/scripts/dataSource.ts' /* webpackChunkName: "pages/pwa-share-new/components/ai-chat-card/scripts/dataSource" */))
const _31f6dabf = () => interopDefault(import('../pages/article/_content.vue' /* webpackChunkName: "pages/article/_content" */))
const _35d2d644 = () => interopDefault(import('../pages/discover/_content.vue' /* webpackChunkName: "pages/discover/_content" */))
const _31508c2b = () => interopDefault(import('../pages/picture/_photoId.vue' /* webpackChunkName: "pages/picture/_photoId" */))
const _0efb41ec = () => interopDefault(import('../pages/playlist/_albumId.vue' /* webpackChunkName: "pages/playlist/_albumId" */))
const _3b398c26 = () => interopDefault(import('../pages/topic/_content.vue' /* webpackChunkName: "pages/topic/_content" */))
const _e5817aa6 = () => interopDefault(import('../pages/user/_userId.vue' /* webpackChunkName: "pages/user/_userId" */))
const _79471664 = () => interopDefault(import('../pages/video/_photoId.vue' /* webpackChunkName: "pages/video/_photoId" */))
const _5b2f2d76 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-management",
    component: _c34c169c,
    name: "account-management"
  }, {
    path: "/aichat",
    component: _6a56820f,
    name: "aichat"
  }, {
    path: "/channel",
    component: _4a33092c,
    name: "channel"
  }, {
    path: "/direct",
    component: _e235b990,
    name: "direct"
  }, {
    path: "/discover-search",
    component: _828fe99a,
    name: "discover-search"
  }, {
    path: "/e-commerce-seo-search",
    component: _2e8b9489,
    name: "e-commerce-seo-search"
  }, {
    path: "/foryou",
    component: _00a251c5,
    name: "foryou"
  }, {
    path: "/message",
    component: _0fdded24,
    name: "message"
  }, {
    path: "/node-service-stability",
    component: _b78fbad6,
    name: "node-service-stability"
  }, {
    path: "/not-found",
    component: _966e55a2,
    name: "not-found"
  }, {
    path: "/old-foryou",
    component: _3e7b02ab,
    name: "old-foryou"
  }, {
    path: "/profile",
    component: _19567090,
    name: "profile"
  }, {
    path: "/pwa-share-new",
    component: _34af4374,
    name: "pwa-share-new"
  }, {
    path: "/aichat/chat",
    component: _104571ab,
    name: "aichat-chat"
  }, {
    path: "/aichat/chatlist",
    component: _1de6e1c9,
    name: "aichat-chatlist"
  }, {
    path: "/aichat/create",
    component: _082e0a8f,
    name: "aichat-create"
  }, {
    path: "/aichat/create-voice",
    component: _5737cff4,
    name: "aichat-create-voice"
  }, {
    path: "/aichat/discover",
    component: _431f5f88,
    name: "aichat-discover"
  }, {
    path: "/aichat/history",
    component: _329a3791,
    name: "aichat-history"
  }, {
    path: "/aichat/inbox",
    component: _119a81c3,
    name: "aichat-inbox"
  }, {
    path: "/aichat/message",
    component: _2cd73cf8,
    name: "aichat-message"
  }, {
    path: "/aichat/more-voice",
    component: _3eb901cd,
    name: "aichat-more-voice"
  }, {
    path: "/aichat/myprofile",
    component: _a5c3988c,
    name: "aichat-myprofile"
  }, {
    path: "/aichat/policy",
    component: _158d03c5,
    name: "aichat-policy"
  }, {
    path: "/aichat/report",
    component: _29afe2c7,
    name: "aichat-report"
  }, {
    path: "/discover/discoverContent",
    component: _06278594,
    name: "discover-discoverContent"
  }, {
    path: "/pwa-share-new/type",
    component: _1d16ac8f,
    name: "pwa-share-new-type"
  }, {
    path: "/pwa-share-new/utils",
    component: _4ca00d00,
    name: "pwa-share-new-utils"
  }, {
    path: "/aichat/components/ai-actions-bar",
    component: _c2fe8212,
    name: "aichat-components-ai-actions-bar"
  }, {
    path: "/aichat/components/ai-ads-popup",
    component: _85bfaf7a,
    name: "aichat-components-ai-ads-popup"
  }, {
    path: "/aichat/components/ai-age-confirm",
    component: _3f0d5e34,
    name: "aichat-components-ai-age-confirm"
  }, {
    path: "/aichat/components/ai-audio-player",
    component: _25151c9a,
    name: "aichat-components-ai-audio-player"
  }, {
    path: "/aichat/components/ai-base-layout",
    component: _09b12d9a,
    name: "aichat-components-ai-base-layout"
  }, {
    path: "/aichat/components/ai-chat",
    component: _0e76123a,
    name: "aichat-components-ai-chat"
  }, {
    path: "/aichat/components/ai-discover",
    component: _4005fa0b,
    name: "aichat-components-ai-discover"
  }, {
    path: "/aichat/components/ai-feed-list",
    component: _27a6a0e1,
    name: "aichat-components-ai-feed-list"
  }, {
    path: "/aichat/components/ai-full-screen",
    component: _7d1ce05e,
    name: "aichat-components-ai-full-screen"
  }, {
    path: "/aichat/components/ai-gender-popup",
    component: _41d3f082,
    name: "aichat-components-ai-gender-popup"
  }, {
    path: "/aichat/components/ai-info",
    component: _2ebbb010,
    name: "aichat-components-ai-info"
  }, {
    path: "/aichat/components/ai-info-popup",
    component: _373aee2f,
    name: "aichat-components-ai-info-popup"
  }, {
    path: "/aichat/components/ai-input-box",
    component: _1d803e08,
    name: "aichat-components-ai-input-box"
  }, {
    path: "/aichat/components/ai-lead-chat",
    component: _4d30b59d,
    name: "aichat-components-ai-lead-chat"
  }, {
    path: "/aichat/components/ai-tabbar",
    component: _52563200,
    name: "aichat-components-ai-tabbar"
  }, {
    path: "/aichat/components/ai-to-desktop-popup",
    component: _aaf831ea,
    name: "aichat-components-ai-to-desktop-popup"
  }, {
    path: "/aichat/components/ai-top-bar",
    component: _5882d1cf,
    name: "aichat-components-ai-top-bar"
  }, {
    path: "/aichat/components/ai-top-tabs",
    component: _2a8676d8,
    name: "aichat-components-ai-top-tabs"
  }, {
    path: "/aichat/components/ai-watermark",
    component: _893a7dd0,
    name: "aichat-components-ai-watermark"
  }, {
    path: "/aichat/components/left-menu",
    component: _6e65f142,
    name: "aichat-components-left-menu"
  }, {
    path: "/aichat/components/load",
    component: _a1852b82,
    name: "aichat-components-load"
  }, {
    path: "/aichat/components/message-action-popup",
    component: _f102f518,
    name: "aichat-components-message-action-popup"
  }, {
    path: "/aichat/components/message-feedback-popup",
    component: _142ef3c3,
    name: "aichat-components-message-feedback-popup"
  }, {
    path: "/aichat/components/side-bar",
    component: _fbc32f94,
    name: "aichat-components-side-bar"
  }, {
    path: "/aichat/hooks/useAppEnvironment",
    component: _7447e424,
    name: "aichat-hooks-useAppEnvironment"
  }, {
    path: "/aichat/hooks/useInstallPWA",
    component: _638875e1,
    name: "aichat-hooks-useInstallPWA"
  }, {
    path: "/aichat/scripts/ai-chat",
    component: _2ecd0d92,
    name: "aichat-scripts-ai-chat"
  }, {
    path: "/aichat/scripts/ai-chat-handler",
    component: _9fdd9e16,
    name: "aichat-scripts-ai-chat-handler"
  }, {
    path: "/aichat/scripts/discoverLogger",
    component: _910eabdc,
    name: "aichat-scripts-discoverLogger"
  }, {
    path: "/aichat/scripts/inboxLogger",
    component: _64c6e7ce,
    name: "aichat-scripts-inboxLogger"
  }, {
    path: "/aichat/scripts/logger",
    component: _3dee69fb,
    name: "aichat-scripts-logger"
  }, {
    path: "/article/components/article-action",
    component: _dfbf23d8,
    name: "article-components-article-action"
  }, {
    path: "/article/components/article-detail",
    component: _1c81626f,
    name: "article-components-article-detail"
  }, {
    path: "/article/models/main",
    component: _544cfedc,
    name: "article-models-main"
  }, {
    path: "/direct/components/direct-open-app",
    component: _967c2254,
    name: "direct-components-direct-open-app"
  }, {
    path: "/discover/components/ai-content-item",
    component: _555dab40,
    name: "discover-components-ai-content-item"
  }, {
    path: "/discover/components/app-whatsapp-status",
    component: _7fde13f5,
    name: "discover-components-app-whatsapp-status"
  }, {
    path: "/discover/components/caption-item",
    component: _c95703c8,
    name: "discover-components-caption-item"
  }, {
    path: "/discover/components/expand-words",
    component: _7d403a38,
    name: "discover-components-expand-words"
  }, {
    path: "/discover/components/list",
    component: _e825e3c0,
    name: "discover-components-list"
  }, {
    path: "/discover/components/picture-action-item",
    component: _29cbe6e2,
    name: "discover-components-picture-action-item"
  }, {
    path: "/discover/components/picture-card",
    component: _75710fc1,
    name: "discover-components-picture-card"
  }, {
    path: "/discover/components/user-info-item",
    component: _086bf392,
    name: "discover-components-user-info-item"
  }, {
    path: "/discover/components/video-action-item",
    component: _1c895ae8,
    name: "discover-components-video-action-item"
  }, {
    path: "/discover/components/video-card",
    component: _5a1c4738,
    name: "discover-components-video-card"
  }, {
    path: "/e-commerce-seo-search/components/header",
    component: _514493a0,
    name: "e-commerce-seo-search-components-header"
  }, {
    path: "/foryou/components/swiperable-tabs",
    component: _18fbaf00,
    name: "foryou-components-swiperable-tabs"
  }, {
    path: "/picture/scripts/app",
    component: _0c741c10,
    name: "picture-scripts-app"
  }, {
    path: "/picture/scripts/seo",
    component: _17e1b63c,
    name: "picture-scripts-seo"
  }, {
    path: "/playlist/components/card",
    component: _38ae0a8a,
    name: "playlist-components-card"
  }, {
    path: "/playlist/components/list",
    component: _c2ebd26e,
    name: "playlist-components-list"
  }, {
    path: "/playlist/hooks/useList",
    component: _8e8700a0,
    name: "playlist-hooks-useList"
  }, {
    path: "/pwa-share-new/components/action-bar",
    component: _28e3a84c,
    name: "pwa-share-new-components-action-bar"
  }, {
    path: "/pwa-share-new/components/action-list-popup",
    component: _c029e20c,
    name: "pwa-share-new-components-action-list-popup"
  }, {
    path: "/pwa-share-new/components/ai-ad-guide-card",
    component: _897fb354,
    name: "pwa-share-new-components-ai-ad-guide-card"
  }, {
    path: "/pwa-share-new/components/ai-chat-card",
    component: _2e5e59e0,
    name: "pwa-share-new-components-ai-chat-card"
  }, {
    path: "/pwa-share-new/components/comment",
    component: _2c4376a5,
    name: "pwa-share-new-components-comment"
  }, {
    path: "/pwa-share-new/components/feed-list",
    component: _3e67011a,
    name: "pwa-share-new-components-feed-list"
  }, {
    path: "/pwa-share-new/components/google-ad",
    component: _57f24bbd,
    name: "pwa-share-new-components-google-ad"
  }, {
    path: "/pwa-share-new/components/incentive-pendant",
    component: _7f2a9a00,
    name: "pwa-share-new-components-incentive-pendant"
  }, {
    path: "/pwa-share-new/components/loading-dark",
    component: _339816b7,
    name: "pwa-share-new-components-loading-dark"
  }, {
    path: "/pwa-share-new/components/lumina-ad",
    component: _45c12efe,
    name: "pwa-share-new-components-lumina-ad"
  }, {
    path: "/pwa-share-new/components/slider-guide",
    component: _1765c5c0,
    name: "pwa-share-new-components-slider-guide"
  }, {
    path: "/pwa-share-new/components/slider-guide2",
    component: _59134930,
    name: "pwa-share-new-components-slider-guide2"
  }, {
    path: "/pwa-share-new/components/topbar",
    component: _26f3fa24,
    name: "pwa-share-new-components-topbar"
  }, {
    path: "/pwa-share-new/components/video-info",
    component: _2496a610,
    name: "pwa-share-new-components-video-info"
  }, {
    path: "/topic/components/load",
    component: _202c5c74,
    name: "topic-components-load"
  }, {
    path: "/topic/components/picture-card",
    component: _3acc3b1f,
    name: "topic-components-picture-card"
  }, {
    path: "/topic/components/tyml-card",
    component: _026fbe0f,
    name: "topic-components-tyml-card"
  }, {
    path: "/topic/components/utils",
    component: _c149743e,
    name: "topic-components-utils"
  }, {
    path: "/topic/components/video-card",
    component: _013c4742,
    name: "topic-components-video-card"
  }, {
    path: "/topic/components/water-fall",
    component: _f018445e,
    name: "topic-components-water-fall"
  }, {
    path: "/user/components/collection-swipper",
    component: _915aed32,
    name: "user-components-collection-swipper"
  }, {
    path: "/user/components/list",
    component: _11f9e582,
    name: "user-components-list"
  }, {
    path: "/user/components/nav",
    component: _1fbab815,
    name: "user-components-nav"
  }, {
    path: "/user/components/post-card",
    component: _39cc394f,
    name: "user-components-post-card"
  }, {
    path: "/user/components/user-profile-base-info",
    component: _217aa2a8,
    name: "user-components-user-profile-base-info"
  }, {
    path: "/user/hooks/useInfo",
    component: _8990c272,
    name: "user-hooks-useInfo"
  }, {
    path: "/user/hooks/useList",
    component: _521b8cd7,
    name: "user-hooks-useList"
  }, {
    path: "/user/hooks/useNoticePopup",
    component: _2452141e,
    name: "user-hooks-useNoticePopup"
  }, {
    path: "/aichat/components/ai-audio-player/audio-text",
    component: _36a6552c,
    name: "aichat-components-ai-audio-player-audio-text"
  }, {
    path: "/aichat/components/ai-chat/type",
    component: _31e8cd83,
    name: "aichat-components-ai-chat-type"
  }, {
    path: "/aichat/components/ai-discover/tab-card",
    component: _5efe2ce2,
    name: "aichat-components-ai-discover-tab-card"
  }, {
    path: "/aichat/components/ai-discover/tab-page",
    component: _178401a1,
    name: "aichat-components-ai-discover-tab-page"
  }, {
    path: "/aichat/components/ai-feed/ai-feed",
    component: _0fe39251,
    name: "aichat-components-ai-feed-ai-feed"
  }, {
    path: "/aichat/components/ai-profile/post-card",
    component: _1c79dab0,
    name: "aichat-components-ai-profile-post-card"
  }, {
    path: "/aichat/components/bot-create/add-tag",
    component: _213f3e12,
    name: "aichat-components-bot-create-add-tag"
  }, {
    path: "/aichat/components/bot-create/add-tag-dialog",
    component: _6f3a708e,
    name: "aichat-components-bot-create-add-tag-dialog"
  }, {
    path: "/aichat/components/bot-create/avatar-upload",
    component: _2a25493e,
    name: "aichat-components-bot-create-avatar-upload"
  }, {
    path: "/aichat/components/bot-create/background-input",
    component: _6a6a89b2,
    name: "aichat-components-bot-create-background-input"
  }, {
    path: "/aichat/components/bot-create/draft-dialog",
    component: _2550258a,
    name: "aichat-components-bot-create-draft-dialog"
  }, {
    path: "/aichat/components/bot-create/gender-select",
    component: _28e44f5e,
    name: "aichat-components-bot-create-gender-select"
  }, {
    path: "/aichat/components/bot-create/greeting-input",
    component: _0c7a62bd,
    name: "aichat-components-bot-create-greeting-input"
  }, {
    path: "/aichat/components/bot-create/intro-input",
    component: _e152f09c,
    name: "aichat-components-bot-create-intro-input"
  }, {
    path: "/aichat/components/bot-create/name-input",
    component: _5f226e0f,
    name: "aichat-components-bot-create-name-input"
  }, {
    path: "/aichat/components/bot-create/permission-select",
    component: _b6dc9ffa,
    name: "aichat-components-bot-create-permission-select"
  }, {
    path: "/aichat/components/bot-create/protocol-agree",
    component: _6569bf84,
    name: "aichat-components-bot-create-protocol-agree"
  }, {
    path: "/aichat/scripts/ai-im/utils",
    component: _6fcd54fe,
    name: "aichat-scripts-ai-im-utils"
  }, {
    path: "/discover/components/user-info-item/user-info-item",
    component: _3a5f47c0,
    name: "discover-components-user-info-item-user-info-item"
  }, {
    path: "/discover/components/video-action-item/template.config",
    component: _3a307e55,
    name: "discover-components-video-action-item-template.config"
  }, {
    path: "/discover/components/video-action-item/video-action-item",
    component: _864e4c9c,
    name: "discover-components-video-action-item-video-action-item"
  }, {
    path: "/discover/components/video-card/video-card",
    component: _596ae100,
    name: "discover-components-video-card-video-card"
  }, {
    path: "/e-commerce-seo-search/components/header/topbar",
    component: _06f3a7c0,
    name: "e-commerce-seo-search-components-header-topbar"
  }, {
    path: "/pwa-share-new/components/ai-ad-guide-card/mark",
    component: _50065979,
    name: "pwa-share-new-components-ai-ad-guide-card-mark"
  }, {
    path: "/pwa-share-new/components/feed-list/feed-list",
    component: _34e8a40e,
    name: "pwa-share-new-components-feed-list-feed-list"
  }, {
    path: "/pwa-share-new/components/feed-list/ios-feed-list",
    component: _4693a5ce,
    name: "pwa-share-new-components-feed-list-ios-feed-list"
  }, {
    path: "/pwa-share-new/components/video-info/unmuted",
    component: _dc4713a0,
    name: "pwa-share-new-components-video-info-unmuted"
  }, {
    path: "/user/components/user-profile-base-info/useProfileInfo",
    component: _2149b97b,
    name: "user-components-user-profile-base-info-useProfileInfo"
  }, {
    path: "/aichat/components/ai-actions-bar/scripts/ai_app",
    component: _ddd6ceae,
    name: "aichat-components-ai-actions-bar-scripts-ai_app"
  }, {
    path: "/aichat/components/ai-actions-bar/scripts/ai_common",
    component: _8e00edf6,
    name: "aichat-components-ai-actions-bar-scripts-ai_common"
  }, {
    path: "/aichat/components/ai-actions-bar/scripts/ai_seo",
    component: _a19f4626,
    name: "aichat-components-ai-actions-bar-scripts-ai_seo"
  }, {
    path: "/aichat/components/ai-audio-player/scripts/audio_cache",
    component: _56df666b,
    name: "aichat-components-ai-audio-player-scripts-audio_cache"
  }, {
    path: "/aichat/components/ai-audio-player/scripts/audio_text_cache",
    component: _f2c65d86,
    name: "aichat-components-ai-audio-player-scripts-audio_text_cache"
  }, {
    path: "/aichat/components/ai-chat-inbox/components/conversation-item",
    component: _224bd256,
    name: "aichat-components-ai-chat-inbox-components-conversation-item"
  }, {
    path: "/aichat/components/ai-chat-inbox/components/likeBtn",
    component: _65df7efe,
    name: "aichat-components-ai-chat-inbox-components-likeBtn"
  }, {
    path: "/aichat/components/ai-chat-inbox/components/tabs",
    component: _292362fb,
    name: "aichat-components-ai-chat-inbox-components-tabs"
  }, {
    path: "/aichat/components/ai-chat/messages-lists/message-intro",
    component: _3a91d51a,
    name: "aichat-components-ai-chat-messages-lists-message-intro"
  }, {
    path: "/aichat/components/ai-chat/messages-lists/message-text",
    component: _1412fcf6,
    name: "aichat-components-ai-chat-messages-lists-message-text"
  }, {
    path: "/aichat/components/ai-input-box/components/recording-animation",
    component: _0d7fbc90,
    name: "aichat-components-ai-input-box-components-recording-animation"
  }, {
    path: "/aichat/components/ai-input-box/script/ai_message_processor",
    component: _e5450498,
    name: "aichat-components-ai-input-box-script-ai_message_processor"
  }, {
    path: "/aichat/components/ai-input-box/script/speech_recognition",
    component: _435aaefd,
    name: "aichat-components-ai-input-box-script-speech_recognition"
  }, {
    path: "/aichat/components/ai-input-box/script/vibrate",
    component: _c6686898,
    name: "aichat-components-ai-input-box-script-vibrate"
  }, {
    path: "/aichat/components/bot-create/scripts/bot-draft-handler",
    component: _7631e86b,
    name: "aichat-components-bot-create-scripts-bot-draft-handler"
  }, {
    path: "/aichat/components/bot-create/scripts/utils",
    component: _4cb0afb2,
    name: "aichat-components-bot-create-scripts-utils"
  }, {
    path: "/aichat/scripts/ai-im/models/AIModel",
    component: _4f2ee2ae,
    name: "aichat-scripts-ai-im-models-AIModel"
  }, {
    path: "/aichat/scripts/ai-im/models/UIAIConversationModel",
    component: _456c1520,
    name: "aichat-scripts-ai-im-models-UIAIConversationModel"
  }, {
    path: "/aichat/scripts/ai-im/models/UIAIMessageModel",
    component: _4ece80d2,
    name: "aichat-scripts-ai-im-models-UIAIMessageModel"
  }, {
    path: "/aichat/scripts/ai-im/models/UIAIUserModel",
    component: _c8d8f530,
    name: "aichat-scripts-ai-im-models-UIAIUserModel"
  }, {
    path: "/aichat/scripts/ai-im/services/UIAIChatService",
    component: _049c4835,
    name: "aichat-scripts-ai-im-services-UIAIChatService"
  }, {
    path: "/aichat/scripts/ai-im/services/UIAIConversationService",
    component: _53e422a0,
    name: "aichat-scripts-ai-im-services-UIAIConversationService"
  }, {
    path: "/aichat/scripts/ai-im/services/UIUserServies",
    component: _c9758b98,
    name: "aichat-scripts-ai-im-services-UIUserServies"
  }, {
    path: "/aichat/scripts/ai-im/stores/AIChatStore",
    component: _6b1798a1,
    name: "aichat-scripts-ai-im-stores-AIChatStore"
  }, {
    path: "/aichat/scripts/ai-im/stores/AIConversationStore",
    component: _436e8c68,
    name: "aichat-scripts-ai-im-stores-AIConversationStore"
  }, {
    path: "/aichat/scripts/ai-im/stores/AIStoreType",
    component: _5330afe2,
    name: "aichat-scripts-ai-im-stores-AIStoreType"
  }, {
    path: "/aichat/scripts/ai-im/stores/AIUserStore",
    component: _0405ce14,
    name: "aichat-scripts-ai-im-stores-AIUserStore"
  }, {
    path: "/aichat/scripts/ai-im/stores/UIAIStore",
    component: _3e9c7006,
    name: "aichat-scripts-ai-im-stores-UIAIStore"
  }, {
    path: "/aichat/scripts/ai-im/utils/aiTools",
    component: _267072a0,
    name: "aichat-scripts-ai-im-utils-aiTools"
  }, {
    path: "/discover/components/caption-item/scripts/app",
    component: _174203e1,
    name: "discover-components-caption-item-scripts-app"
  }, {
    path: "/discover/components/caption-item/scripts/seo",
    component: _355dc825,
    name: "discover-components-caption-item-scripts-seo"
  }, {
    path: "/discover/components/picture-action-item/scripts/app",
    component: _21faf564,
    name: "discover-components-picture-action-item-scripts-app"
  }, {
    path: "/discover/components/picture-action-item/scripts/common",
    component: _63851e40,
    name: "discover-components-picture-action-item-scripts-common"
  }, {
    path: "/discover/components/picture-action-item/scripts/seo",
    component: _0d1e4992,
    name: "discover-components-picture-action-item-scripts-seo"
  }, {
    path: "/discover/components/video-action-item/scripts/app",
    component: _531e7571,
    name: "discover-components-video-action-item-scripts-app"
  }, {
    path: "/discover/components/video-action-item/scripts/common",
    component: _32b3173d,
    name: "discover-components-video-action-item-scripts-common"
  }, {
    path: "/discover/components/video-action-item/scripts/seo",
    component: _713a39b5,
    name: "discover-components-video-action-item-scripts-seo"
  }, {
    path: "/pwa-share-new/components/action-bar/scripts/app",
    component: _aef4909e,
    name: "pwa-share-new-components-action-bar-scripts-app"
  }, {
    path: "/pwa-share-new/components/action-bar/scripts/common",
    component: _a3eca606,
    name: "pwa-share-new-components-action-bar-scripts-common"
  }, {
    path: "/pwa-share-new/components/action-bar/scripts/seo",
    component: _72bd0816,
    name: "pwa-share-new-components-action-bar-scripts-seo"
  }, {
    path: "/pwa-share-new/components/ai-chat-card/scripts/dataSource",
    component: _60ce8f87,
    name: "pwa-share-new-components-ai-chat-card-scripts-dataSource"
  }, {
    path: "/article/:content?",
    component: _31f6dabf,
    name: "article-content"
  }, {
    path: "/discover/:content?",
    component: _35d2d644,
    name: "discover-content"
  }, {
    path: "/picture/:photoId?",
    component: _31508c2b,
    name: "picture-photoId"
  }, {
    path: "/playlist/:albumId?",
    component: _0efb41ec,
    name: "playlist-albumId"
  }, {
    path: "/topic/:content?",
    component: _3b398c26,
    name: "topic-content"
  }, {
    path: "/user/:userId?",
    component: _e5817aa6,
    name: "user-userId"
  }, {
    path: "/video/:photoId?",
    component: _79471664,
    name: "video-photoId"
  }, {
    path: "/",
    component: _5b2f2d76,
    name: "index"
  }, {
    path: "/@*/playlist/:albumId",
    component: _0efb41ec,
    name: "playlist-detail"
  }, {
    path: "/@*/video/:photoId",
    component: _79471664,
    meta: {"replacePhotoId":true},
    name: "video-detail"
  }, {
    path: "/@*/picture/:photoId",
    component: _31508c2b,
    name: "picture-detail"
  }, {
    path: "/@*",
    component: _e5817aa6,
    name: "profile"
  }, {
    path: "/pwa-share-new/:photoId",
    component: _34af4374,
    name: "video-detail-share"
  }, {
    path: "/search/:searchWord/video/:photoId",
    component: _828fe99a,
    meta: {"replacePhotoId":true},
    name: "discover-search"
  }, {
    path: "/search/video/:photoId",
    component: _828fe99a,
    meta: {"replacePhotoId":true},
    name: "discover-search"
  }, {
    path: "/channel/:content",
    component: _4a33092c,
    name: "channel"
  }, {
    path: "/aichat/bot/:botId/:tagName?",
    component: _6a56820f,
    name: "aichat-detail"
  }, {
    path: "/aichat/room/:botId",
    component: _104571ab,
    name: "aichat-room"
  }, {
    path: "/aichat/discover",
    component: _431f5f88,
    name: "aichat-discover"
  }, {
    path: "/aichat/inbox",
    component: _119a81c3,
    name: "aichat-inbox"
  }, {
    path: "/aichat/report",
    component: _29afe2c7,
    name: "aichat-report"
  }, {
    path: "/aichat/history",
    component: _329a3791,
    name: "aichat-history"
  }, {
    path: "/aichat/chatlist",
    component: _1de6e1c9,
    name: "aichat-chatlist"
  }, {
    path: "/aichat/create",
    component: _082e0a8f,
    name: "aichat-create"
  }, {
    path: "/aichat/create-voice",
    component: _5737cff4,
    name: "aichat-create-voice"
  }, {
    path: "/aichat/more-voice",
    component: _3eb901cd,
    name: "aichat-more-voice"
  }, {
    path: "/aichat/myprofile",
    component: _a5c3988c,
    name: "aichat-myprofile"
  }, {
    path: "/node-service-stability/timeout",
    component: _b78fbad6,
    name: "node-service-stability-timeout"
  }, {
    path: "/node-service-stability/fallback",
    component: _b78fbad6,
    name: "node-service-stability-fallback"
  }, {
    path: "/eshop",
    component: _2e8b9489,
    name: "e-commerce-seo-search-default"
  }, {
    path: "/eshop/:searchWord",
    component: _2e8b9489,
    name: "e-commerce-seo-search-keyword"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
