import { openDB, IDBPDatabase, IDBPObjectStore, IDBPIndex, DBSchema } from 'idb';
import { AIMessageModel, AIConversationModel, AIUserModel } from '../models/AIModel';

const DBVersion = 1;
const DBAIIMName = 'social-db';

export interface AISocialDB extends DBSchema {
    conversations: {
        value: AIConversationModel;
        key: string;
        indexes: {
            participants: string[];
        };
    };
    messages: {
        value: AIMessageModel;
        key: string;
        indexes: {
            conversationId: string;
            senderId: string;
        };
    };
    users: {
        value: AIUserModel;
        key: string;
        indexes: {
            name: string;
        };
    };
}

let db: IDBDatabase | null = null;
const getDB = (): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DBAIIMName, DBVersion);
        request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
            // const { newVersion, oldVersion } = event;
            db = (event?.target as IDBOpenDBRequest)?.result;
            if (!db.objectStoreNames.contains('conversations')) {
                const store = db.createObjectStore('conversations', { keyPath: 'id' });
                store.createIndex('participants', 'participants', { unique: false });
            }
            if (!db.objectStoreNames.contains('messages')) {
                const store = db.createObjectStore('messages', { keyPath: 'id' });
                store.createIndex('conversationId', 'conversationId', { unique: false });
                store.createIndex('senderId', 'senderId', { unique: false });
            }
            if (!db.objectStoreNames.contains('users')) {
                const store = db.createObjectStore('users', { keyPath: 'id' });
                store.createIndex('name', 'name', { unique: false });
            }
        };

        request.onsuccess = event => {
            const dataBase = (event?.target as IDBOpenDBRequest)?.result;
            db = dataBase;
            console.log('social-ai', 'open audio db sucessfully', dataBase);
            resolve(db);
        };
        request.onerror = event => {
            const error = (event?.target as IDBOpenDBRequest)?.error;
            console.log('social-ai', 'open audio db failed', error);
            reject(db);
        };

        console.log('social-ai', 'openAudioDatabase');
    });
};
export default getDB;
