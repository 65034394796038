const SnapShotCdnUrl = 'https://cdn-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/common/api-snapshot.json';
class BackupApiController {
    private apiDataSnapshot: Record<string, any> = {};

    constructor() {
        this.apiDataSnapshot = {};
    }

    private getUrlPathName(url?: string) {
        const urlWithoutQuery = (url || '')?.replace(/(\?.*)/, '');
        try {
            if (/^http(s)?:/.test(urlWithoutQuery || '')) {
                return new URL(urlWithoutQuery || '').pathname;
            }

            return urlWithoutQuery;
        } catch {
            return urlWithoutQuery;
        }
    }

    private async getSnapshotFromCDN() {
        try {
            const fetchPromise = await fetch(SnapShotCdnUrl, {
                headers: {},
                referrerPolicy: 'strict-origin-when-cross-origin',
                body: null,
                method: 'GET',
                mode: 'cors',
                credentials: 'omit',
            });
            const fetchRes = await fetchPromise.json();
            this.apiDataSnapshot = {
                ...this.apiDataSnapshot,
                ...fetchRes,
            };
            return fetchRes;
        } catch {
            this.apiDataSnapshot = {};
            return {};
        }
    }

    getSnapshot() {
        return this.apiDataSnapshot;
    }

    async getSnapshotByApiUrl(url: string) {
        const api = this.getUrlPathName(url);
        const apiSnapshot = this.apiDataSnapshot[api];
        if (!apiSnapshot) {
            const snapShotData = await this.getSnapshotFromCDN();

            if (!snapShotData[api]) {
                // 避免 snapshot 未配置时重复请求;
                this.apiDataSnapshot = {
                    ...this.apiDataSnapshot,
                    [api]: {},
                };
                return {};
            }

            return snapShotData[api];
        }
        return apiSnapshot;
    }
}

// eslint-disable-next-line import/prefer-default-export
export const BackupApiControllerInstance = new BackupApiController();
