import { NuxtAxiosInstance } from 'vue/types/vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import cookies from 'js-cookie';
import cookie from 'cookie';
import { serverHosts, isProduction } from '../utils/constants';
import { getKpn, paramsMap } from '@/common/common-params';
import { objectToCookieString } from '@/common/utils';
import { BackupApiControllerInstance } from '@/common/backup-api-controller';
import { ResponseBaseResult } from './base';

interface RequestConfig extends AxiosRequestConfig {
    myStartTime?: number;
}

export interface Instance extends AxiosInstance, Partial<NuxtAxiosInstance> {}

const request: Instance = axios.create({
    baseURL: '/',
    timeout: 4000,
    withCredentials: true,
});

let apiLogger: any;
if (process.server && isProduction) {
    apiLogger = require('../server/log').api;
    request.interceptors.response.use(
        response => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            apiLogger((globalThis as any)?.__PWA_NUXT_CTX__?.req, response.config, response);
            return response;
        },
        error => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            apiLogger((globalThis as any)?.__PWA_NUXT_CTX__?.req, error.config, error.response);
            return Promise.reject(error);
        }
    );
}

// 服务端发请求
// Add a request interceptor
request.interceptors.request.use(
    async config => {
        if (process.server) {
            const kpn = getKpn({
                bucket: (globalThis as any)?.__PWA_NUXT_CTX__?.store?.state?.startup?.bucket,
                host: (globalThis as any)?.__PWA_NUXT_CTX__?.req?.headers?.host,
            });
            const commonParams = paramsMap[kpn];

            const reqConfig: Partial<RequestConfig> = config;

            reqConfig.myStartTime = Date.now();
            reqConfig.withCredentials = true;
            let matched = false;

            config.url = config?.url?.replace(/^(\w+)::/, (all, hostName) => {
                const host = serverHosts[hostName as keyof typeof serverHosts];
                if (host) {
                    matched = true;
                    return host;
                }
                return '';
            });
            if (!matched) {
                throw new Error(`axios url error${config.url}`);
            }

            const reqHeaders = (globalThis as any)?.__PWA_NUXT_CTX__?.req.headers;
            const serverHeaders: Record<string, any> = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const key in reqHeaders) {
                if (key.startsWith('x-') || key.startsWith('ks-') || key === 'user-agent' || key === 'accept-language' || key === 'trace-context') {
                    serverHeaders[key] = reqHeaders[key];
                }
            }

            config.headers = {
                ...serverHeaders,
                ...config.headers,
            };

            const cookieFromRequest = cookie.parse((globalThis as Record<string, any>)?.__PWA_NUXT_CTX__?.req.headers.cookie || '');
            const configCookie = { ...cookieFromRequest, ...commonParams };
            config.headers.common.cookie = objectToCookieString(configCookie);
        } else {
            config.url = config.url?.split('::').slice(-1)[0];
            cookies.get('kwaipro.pwa_ph') &&
                (config.url =
                    config?.url?.indexOf('?') === -1
                        ? `${config.url}?kwaipro.pwa_ph=${cookies.get('kwaipro.pwa_ph')}`
                        : `${config.url}&kwaipro.pwa_ph=${cookies.get('kwaipro.pwa_ph')}`);

            if (process.env.IS_BACKUP_ENV) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    /**
                     * @en if `snapshot`, return api data from CDN
                     */
                    snapshot: true,
                    config,
                });
            }
        }

        return config;
    },
    error => {
        if (process.server && isProduction) {
            apiLogger((globalThis as any)?.__PWA_NUXT_CTX__?.req, error.config);
        }
        // Do something with request error
        return Promise.reject(error);
    }
);

request.interceptors.response.use(
    async response => {
        /**
         * @zh 针对核心接口的熔断与限流
         */
        if (!process.server && response.data?.result === ResponseBaseResult.SERVER_BUSY) {
            const apiSnapshot = await BackupApiControllerInstance.getSnapshotByApiUrl(response?.config?.url || '');
            return {
                ...response,
                data: {
                    ...response.data,
                    ...apiSnapshot,
                    snapshot: true,
                },
            };
        }

        return response;
    },
    async (error: { snapshot?: boolean; config?: AxiosRequestConfig }) => {
        if (!process.server && error?.snapshot) {
            const apiSnapshot = await BackupApiControllerInstance.getSnapshotByApiUrl(error?.config?.url || '');

            return Promise.resolve({
                data: {
                    ...apiSnapshot,
                    snapshot: true,
                },
                status: 200,
                statusText: 'OK',
                headers: {},
                config: error?.config,
                request: {},
            });
        }

        return Promise.reject(error);
    }
);

request.$$get = async (...args) => {
    try {
        console.log('get args', args, 'isServerSide', process.server);
        const res = await request.get(...args);
        return res.data;
    } catch (e: any) {
        return {
            result: 0 - (e.response?.status || 1),
            error_msg: '404',
        };
    }
};
request.$$post = async (...args) => {
    try {
        console.log('post args', args, 'isServerSide', process.server);
        const res = await request.post(...args);
        return res.data;
    } catch (e: any) {
        console.log('错误args', args);
        console.log('post 错误', e, 'isServerSide', process.server);
        return {
            result: 0 - (e.response?.status || 1),
            error_msg: '404',
        };
    }
};

export default request as NuxtAxiosInstance;
