import { IKwaiPlayer, KwaiPlayerEvent, PluginModule } from '@ks-video/kwai-player-web/vue2-mini';
import Vue from 'vue';
import PlcInfo from '@/components/plc-info/index.vue';

// 新插件替换已有的logger插件
// export default class ExamplePlugin extends LoggerPluginModule {

// 新插件与已有插件共存
export default class PlcPlugin extends PluginModule {
    private _player: IKwaiPlayer | undefined;

    public container: HTMLDivElement | undefined;

    public static label = 'PlcPlugin';

    public vueComponent = null;

    private options: any;

    private canplay = false;

    /**
     * 插件id，获取插件，传入参数等操作的标识
     * 同名插件只会同时存在一个
     */
    public getLabel(): string {
        // LoggerPluginLabel
        return PlcPlugin.label;
    }

    private onCanplay = () => {
        this.canplay = true;
        this.showPlc();
    };

    private showPlc = () => {
        if (this.canplay) {
            this.options.showPlc = true;
        }
    };

    private hidePlc = () => {
        this.options.showPlc = false;
    };

    /**
     * 播放器实例化前钩子
     * @param player 播放器实例
     * @param options 实例化播放器时指定的当前插件的参数
     */
    public beforeInstall(player: IKwaiPlayer, options?: any): void {
        this._player = player;
        this.container = player.createSubContainer();
        this.options = options;
        this.options.showPlc = false;

        this.vueComponent = new Vue({
            el: this.container,
            render: h =>
                h(PlcInfo, {
                    props: this.options,
                    style: {
                        position: 'absolute',
                        bottom: '30px',
                        left: '6px',
                    },
                }),
        });

        this._player.on(KwaiPlayerEvent.CANPLAY, this.onCanplay);
        this._player.on(KwaiPlayerEvent.PLAY, this.showPlc);
        this._player.on(KwaiPlayerEvent.PAUSE, this.hidePlc);
    }

    /**
     * 播放器实例化后钩子
     * @param player 播放器实例
     * @param options 实例化播放器时指定的当前插件的参数
     */
    public afterInstall(_player: IKwaiPlayer, _options?: any): void {
        // console.log('debug afterInstall');
    }

    /**
     * 播放器销毁前钩子
     * @param player 播放器实例
     */
    public beforeUninstall(_player: IKwaiPlayer): void {
        // console.log('debug beforeUninstall');
    }

    /**
     * 播放器销毁后钩子
     * @param player 播放器实例
     */
    public afterUninstall(_player: IKwaiPlayer): void {
        // console.log('debug afterUninstall');
    }
}
