import { getCurrentInstance, onMounted, onUnmounted, ref } from 'vue';
import { isTiny, isPwa, getQuery } from '~/common/utils';
import device from '@/common/device-info';
import { sendLog } from '~/common/radar';
import { SafetyLocalStorage } from '@/common/storage';

enum OpenStatus {
    Close = 0,
    Opend = 1,
    Opening = 2,
}
export enum OpenPageStatus {
    pwa = 1, // pwa打开
    hasPwaInWeb = 2, // web打开 已安装pwa
    noPwaInWeb = 3, // web打开 未安装pwa
}
const pageBlackList = ['account-management', 'direct'];
const hostWhiteList = ['kwai.com', 'test.gifshow.com', 'localhost'];

function delay(seconds = 0) {
    const delayTime = Math.max(0, seconds * 1000);
    return new Promise(resolve => setTimeout(resolve, delayTime));
}

const NO_TRIGGER_POPUP_DELAY = -1;

const getWebToAppPopupTriggerDelay = () => {
    const { proxy } = getCurrentInstance() || {};

    const defaultStrategy = { daygap: 7, atime: NO_TRIGGER_POPUP_DELAY };
    let webToAppStrategy = defaultStrategy;

    if (proxy?.$store?.state?.startup?.addapp_switch) {
        try {
            webToAppStrategy = JSON.parse(proxy.$store.state.startup.addapp_switch);
        } catch (error) {
            console.error('Failed to parse webToAppStrategy:', error);
        }
    }

    const delayTime = webToAppStrategy?.atime ?? -1;

    // 实验下发不命中，不触发弹窗
    if (delayTime < 0) {
        console.log('webToAppStrategy', 'not hit exp');
        return NO_TRIGGER_POPUP_DELAY;
    }

    // 投放过来的，不触发弹窗
    const { pwa_source } = getQuery();
    if (pwa_source === 'web_roas' || pwa_source === 'web_dsp') {
        console.log('webToAppStrategy', 'web_roas or web_dsp source');
        return NO_TRIGGER_POPUP_DELAY;
    }

    // 非br桶，不触发弹窗
    if (proxy?.$store?.state?.startup?.bucket !== 'br') {
        console.log('webToAppStrategy', 'not br');
        return NO_TRIGGER_POPUP_DELAY;
    }

    // App环境或者iOS设备，不触发弹窗
    const { isIOS } = device() || {};
    if (proxy?.$isAppEnvironment || isTiny() || isIOS) {
        console.log('webToAppStrategy', 'app or ios');
        return NO_TRIGGER_POPUP_DELAY;
    }

    // path在黑名单中，不触发
    if (pageBlackList.includes(proxy?.$route?.name || '')) {
        console.log('webToAppStrategy', proxy?.$route?.name);
        return NO_TRIGGER_POPUP_DELAY;
    }

    // host不在白名单中，不触发
    const host = window?.location?.host.toLowerCase();
    if (!hostWhiteList.some(allowedHost => host.includes(allowedHost))) {
        console.log('webToAppStrategy', host);
        return NO_TRIGGER_POPUP_DELAY;
    }

    // 两次之间的频控不满足dayGap天，不触发
    const dayGap = webToAppStrategy?.daygap ?? 7;
    let lastPopupShowTime = 0;
    try {
        lastPopupShowTime = parseInt(SafetyLocalStorage.getItem('web-app-log') || '0', 10);
    } catch (error) {
        console.log('Error accessing SafetyLocalStorage:', error);
        return NO_TRIGGER_POPUP_DELAY;
    }
    const timeDifference = Date.now() - lastPopupShowTime;
    const sevenDaysInMilliseconds = dayGap * 24 * 60 * 60 * 1000;
    if (timeDifference <= sevenDaysInMilliseconds) {
        console.log('webToAppStrategy', 'day gap');
        return NO_TRIGGER_POPUP_DELAY;
    }

    return delayTime;
};

const tryShowPromptPopup = async () => {
    try {
        const deferredPrompt = window?.deferredPrompt;
        if (!deferredPrompt) return;

        await deferredPrompt.prompt?.();
        const res = await deferredPrompt.userChoice;

        sendLog({
            type: 'click',
            name: 'INSTALLED_PWA',
            value: {
                type: 'BACKGROUND_TASK_EVENT',
                is_install: res.outcome === 'accepted' ? OpenStatus.Opening : OpenStatus.Close,
            },
        });
    } catch (error) {
        console.log(error, 'error');
    }
};

export const useWebToApp = () => {
    const webToAppPopupVisible = ref(false);

    // 多访问几次才会触发
    const handleInstallPromptCallback = (event: Event) => {
        const { isIOS } = device() || {};
        if (!event || isIOS) {
            return;
        }

        event.preventDefault();
        // eslint-disable-next-line no-undef
        window.deferredPrompt = event as BeforeInstallPromptEvent;

        // 未安装
        SafetyLocalStorage.setItem('uninstallApp', 'true');
    };

    // 检测当前打开状态
    /**
     *
     * @returns
     * 1: pwa打开
     * 2: 已安装 web 打开
     * 3: 未安装
     *
     */
    const checkIfInstalled = () => {
        const uninstallApp = SafetyLocalStorage.getItem('uninstallApp');
        console.log('locale', uninstallApp);
        if (isPwa()) {
            SafetyLocalStorage.setItem('uninstallApp', 'false');
            return OpenPageStatus.pwa;
        }
        // 已安装pwa web打开
        if (uninstallApp === 'false') {
            return OpenPageStatus.hasPwaInWeb;
        }
        return OpenPageStatus.noPwaInWeb;
    };

    // 点击安装app
    const installPwa = () => {
        return new Promise((resolve, reject) => {
            if (window.deferredPrompt) {
                try {
                    // 触发PWA安装
                    window.deferredPrompt?.prompt?.();
                    // 监听安装结果
                    window.deferredPrompt?.userChoice?.then?.(choiceResult => {
                        if (choiceResult.outcome === 'accepted') {
                            // 已安装
                            SafetyLocalStorage.setItem('uninstallApp', 'false');
                            sessionStorage.setItem('untempInstallStatus', 'false');
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
                } catch (error) {
                    reject(error);
                }
            } else {
                resolve(false);
            }
        });
    };

    // 检查浏览器是否支持 PWA
    const isPwaSupported = () => {
        // 检查浏览器是否支持 Service Worker
        const isServiceWorkerSupported = 'serviceWorker' in navigator;

        // 检查浏览器是否支持 Web App Manifest
        const isManifestSupported = 'getManifest' in document;

        // 检查浏览器是否支持 beforeinstallprompt 事件 (即是否支持安装提示)
        const isBeforeInstallPromptSupported = 'BeforeInstallPromptEvent' in window;

        return isServiceWorkerSupported && isManifestSupported && isBeforeInstallPromptSupported;
    };

    const handleWebToAppPopupConfirm = () => {
        webToAppPopupVisible.value = false;
        window.location.href = 'market://details?id=ai.questionai.chat.bot';
    };

    const handleWebToAppPopupClose = () => {
        webToAppPopupVisible.value = false;
    };

    onMounted(async () => {
        window.addEventListener('beforeinstallprompt', handleInstallPromptCallback);

        const delayTime = getWebToAppPopupTriggerDelay();
        if (delayTime >= 0) {
            await delay(delayTime);
            webToAppPopupVisible.value = true;
            try {
                SafetyLocalStorage.setItem('web-app-log', Date.now().toString());
            } catch (error) {
                console.log(error, 'error');
            }
        }
    });

    onUnmounted(() => {
        window.removeEventListener('beforeinstallprompt', handleInstallPromptCallback);
    });

    return {
        handleWebToAppPopupConfirm,
        handleWebToAppPopupClose,

        webToAppPopupVisible,
        checkIfInstalled,
        installPwa,
        isPwaSupported,
    };
};
