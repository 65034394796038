/* eslint-disable import/no-extraneous-dependencies */
import { Context } from '@nuxt/types';

export const checkSnack = (ctx?: Context) => {
    let host;
    if (process.server) {
        host = ctx?.req?.headers?.host || '';
    } else {
        host = window.location.host || '';
    }
    const isSnack = host.includes('snack');
    return {
        isSnack,
    };
};

interface MetaInfo {
    property?: string;
    name?: string;
    hid?: string;
    content?: string;
}

interface AppInfo {
    title?: string;
    appIcon?: string;
    appName?: string;
    packageName?: string;
}

/**
 * @zh 仅对 ldjson 里的 <> 标签
 */
function escapeSeoLdJsonHTML(str?: string) {
    if (typeof str !== 'string') {
        return str; // 如果不是字符串，直接返回
    }

    return str.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

export function header(that: any) {
    const KwaiAppInfo: AppInfo = {
        title: that?.$t?.('Make_Everyone_Shine') || 'Kwai',
        appIcon: 'https://g-static.ap4r.com/kos/s101/nlav11312/icon/kwai/2/favicon.ico',
        appName: 'Kwai',
        packageName: 'com.kwai.video',
    };

    const SnackAppInfo: AppInfo = {
        title: that?.$t?.('Make_Everyone_Shine') || 'Snack',
        appIcon: 'https://cdn-static.kwai.net/kos/s101/nlav11312/icon/snack/2/favicon.ico',
        appName: 'SnackVideo',
        packageName: 'com.kwai.bulldog',
    };

    const WeeeyeAppInfo: AppInfo = {
        title: 'Weeeye',
        appIcon: 'https://cdn-static.ap4r.com/kos/s101/nlav11312/pwa-images/ai_chat_logo.png',
        appName: 'Weeeye',
        packageName: 'com.kwai.video',
    };
    const isSnack = that?.$isSnack;
    const isWeeeye = that?.$isWeeeye;

    let appInfo;
    let content;
    switch (true) {
        case isWeeeye:
            appInfo = WeeeyeAppInfo;
            content = 'Weeeye';
            break;
        case isSnack:
            appInfo = SnackAppInfo;
            content = 'SnackVideo is the newest party for short funny videos!';
            break;
        default:
            appInfo = KwaiAppInfo;
            content = that.$t('seo_description');
            break;
    }

    const { title, appIcon, appName, packageName } = appInfo;
    const defaultTdkInfo: Array<MetaInfo> = [
        { property: 'og:title', hid: 'og:title', content },
        { property: 'og:description', hid: 'og:description', content },
        { property: 'og:site_name', hid: 'og:site_name', content },
        { name: 'keywords', hid: 'keywords', content },
        { name: 'description', hid: 'description', content },
    ];

    const meta: Array<MetaInfo> = [
        // 服务端下发
        // /* twitter */
        // {
        //   hid: 'twitter:image',
        //   name: 'twitter:image',
        //   content: appIcon,
        // },
        // /* facebook, vk使用的header */
        // {
        //   hid: 'og:image',
        //   name: 'og:image',
        //   content: appIcon,
        // },
        { property: 'al:ios:app_name', content: appName },
        { property: 'al:android:app_name', content: appName },
        { property: 'al:android:package', content: packageName },
    ];

    // 拼接scriptArr前需要先过滤innerHTML后端下发空的case，防止拼接的innerHTML为undefined，优化GSC（google search console）爬虫
    const scriptArr =
        that.seoData
            ?.filter((item: { innerHTML: string }) => item.innerHTML)
            .map((item: { innerHTML: string }) => {
                return {
                    ...item,
                    innerHTML: `${escapeSeoLdJsonHTML(JSON.stringify(item.innerHTML))}`,
                };
            }) || [];

    return {
        htmlAttrs: {
            pc: that.$platform ? 'no' : 'yes',
            dir: that.$isRtl ? 'rtl' : 'ltr',
        },
        title: that.title || title,
        meta: [
            ...meta,
            ...(that?.metaInfoData || []),
            ...defaultTdkInfo.filter(
                info => !(that?.metaInfoData || []).find((item: MetaInfo) => item.name !== info.name && item.property !== info.property)
            ),
        ],
        link: [
            {
                rel: 'image_src',
                href: appIcon,
            },
            {
                rel: 'shortcut icon',
                href: appIcon,
                type: 'image/x-icon',
            },
            {
                rel: 'apple-touch-icon-precomposed',
                href: appIcon,
            },
        ],
        script: scriptArr,
    };
}

export function adSenseHeader(head: any) {
    head.script.push({
        src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9623430102442126',
        crossorigin: 'anonymous',
        async: true,
    });
}
// https://docs.corp.kuaishou.com/d/home/fcAAMdFdz7E2fZBp4EFnUEV_a

// Meta标签查询
export function getMetaInfo({ ctx, params }: Record<string, any>) {
    return ctx.$axios.$$post(`overseaAPI::/rest/o/w/seo/metaInfo/getByType`, params);
}

// Script标签查询
export function getLdJson({ ctx, params }: Record<string, any>) {
    return ctx.$axios.$$post(`overseaAPI::/rest/o/w/seo/ldJson/getByType`, params);
}

/**
 * @deprecated Use `getMetaInfo` instead.
 */
export function getTitle({ ctx, params }: Record<string, any>) {
    return ctx.$axios.$$get(`overseaAPI::/rest/o/w/seo/title/getByType`, { params });
}

// 从服务端的MetaInfo接口数据中提取Title信息
export function extractTitleFromMetaInfo({ ctx, metaInfoData }: Record<string, any>) {
    const titleItem = metaInfoData?.length && metaInfoData?.find((item: { name: string }) => item?.name === 'title');
    let title = titleItem?.content;

    if (!title) {
        const { isSnack } = checkSnack(ctx);
        title = ctx?.i18n?.t?.('Make_Everyone_Shine') ? ctx?.i18n?.t?.('Make_Everyone_Shine') : isSnack ? 'Snack' : 'Kwai';
    }

    return title;
}

// 获取兜底的页面Title
export function getDefaultTitle(ctx?: Context) {
    const { isSnack } = checkSnack(ctx);
    return ctx?.i18n?.t?.('Make_Everyone_Shine') ? ctx?.i18n?.t?.('Make_Everyone_Shine') : isSnack ? 'Snack' : 'Kwai';
}
