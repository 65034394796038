import Cookie from 'cookie';

// Test
function getNotExecuteRedirectQuey(req, key) {
    const { url } = req; // 获取url中"?"符后的字串
    const theRequest = Object.create(null);
    if (url.includes('?')) {
        const index = url.indexOf('?') + 1;
        const str = url.substr(index);
        const strs = str.split('&');
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
        }
    }
    return theRequest[key];
}

// get deviceID
async function getDeviceIDByWebDid(store, req, webDid, ckid) {
    if (!webDid && !ckid) {
        // console.log('redirect_web', 'node_server', 'webdid or ckid is null', webDid, ckid);
        return;
    }

    const baseWebdidPath = 'overseaAPI::/rest/o/w/pwa/v2/getDidByWebDid?';
    const baseCkidPath = 'overseaAPI::/rest/o/w/pwa/v2/getDidByCookieId?';
    let appendedPath;
    if (webDid) {
        appendedPath = `${baseWebdidPath}webDid=${webDid}`;
    }
    if (ckid) {
        appendedPath = `${baseCkidPath}&cookieId=${ckid}`;
    }
    const { result, data } = await store.$axios.$$get(appendedPath);
    // console.log('redirect_web', 'node_server', 'result & data & webDid & ckid', result, data, webDid, ckid);
    let deviceId;
    let redirectAB;
    if (result === 1) {
        // const data = { redirectAB: '', white: 'true', deviceId: 'ANDROID_f89df9ed896e511f' };
        deviceId = data.deviceId;
        redirectAB = data.redirectAB;
        const testDevideId = getNotExecuteRedirectQuey(req, 'test_deviceId');
        const testRedirectab = getNotExecuteRedirectQuey(req, 'test_redirectab');
        if (testDevideId) {
            deviceId = testDevideId;
        }
        if (testRedirectab) {
            redirectAB = testRedirectab;
        }
    }
    return { deviceId, redirectAB };
    // return { redirectAB: 'show', white: 'true', deviceId: 'ANDROID_f89df9ed896e511f' };
}

// get pwaSource
function getPwaSource(req) {
    const { url } = req; // 获取url中"?"符后的字串
    const theRequest = Object.create(null);
    if (url.includes('?')) {
        const index = url.indexOf('?') + 1;
        const str = url.substr(index);
        const strs = str.split('&');
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
        }
    }
    return theRequest.pwa_source;
}

// Node Side Cookie set & get
function nodeSetCookie(res, key, value) {
    let headers = res.getHeader('Set-Cookie') || [];
    if (!Array.isArray(headers)) {
        headers = [String(headers)];
    }
    const date = new Date();
    const cookieOptions = {
        expires: new Date(date.setDate(date.getDate() + 7)),
        path: '/',
        sameSite: 'Lax',
        secure: false,
    };

    const redirectCookie = Cookie.serialize(key, value, cookieOptions);
    headers.push(redirectCookie);
    res.setHeader('Set-Cookie', headers);
}

function nodeGetCookie(req, key) {
    const { cookie } = req.headers;
    const cookies = Cookie.parse(cookie);
    return cookies[key];
}

// tools
function nodeIsAppEnvironment(req) {
    const { cookie } = req.headers;
    const cookies = Cookie.parse(cookie);
    return !!cookies?.pkg && !!cookies?.appver;
}

function isTiny(route) {
    return /^\/tiny\/.*/.test(route.path || '');
}

function isAIChatHost(req) {
    return req.headers.host === 'www.weeeye.com';
}

const checkWebDidCollect = req => {
    const _webDid_collect = nodeGetCookie(req, '_webDid_collect');
    if (!_webDid_collect) {
        return false;
    }
    return true;
};

const checkRedirectToAIChat = (req, redirect, route) => {
    if (isAIChatHost(req) && route.path === '/') {
        redirect('/aichat');
        return true;
    }
    return false;
};
// middleware hook
export default async ({ store, route, redirect, req, res }) => {
    if (process.client || nodeIsAppEnvironment(req) || isTiny(route)) {
        return;
    }
    if (checkRedirectToAIChat(req, redirect, route)) {
        return;
    }

    const collect = checkWebDidCollect(req);
    if (!collect) {
        // 未种下webDid，到client端执行302逻辑
        return;
    }
    const webDid = nodeGetCookie(req, 'webDid');
    let canRedirect = req.headers.host === 'www.kwai.film';
    const ignoreHost = getNotExecuteRedirectQuey(req, 'ignore_host');
    canRedirect = canRedirect || ignoreHost === 'true';
    if (!canRedirect) {
        return;
    }
    const appDeviceIdStr = nodeGetCookie(req, 'app_device_id');
    let parsedAppDeviceId;
    if (appDeviceIdStr && typeof appDeviceIdStr === 'string') {
        try {
            parsedAppDeviceId = JSON.parse(appDeviceIdStr);
        } catch (error) {
            console.warn('Parse app devideID error: ', error);
        }
    }
    if (!parsedAppDeviceId && webDid) {
        parsedAppDeviceId = await getDeviceIDByWebDid(store, req, webDid);
        // console.log('redirect_web', 'node_server', 'getFromWebdid', `app-device-id:${JSON.stringify(parsedAppDeviceId)}`);
    }

    const { deviceId, redirectAB } = parsedAppDeviceId || {};
    let containSource = true;
    if (deviceId && redirectAB) {
        nodeSetCookie(res, 'app_device_id', JSON.stringify({ deviceId, redirectAB }));
        const pwaSource = getPwaSource(req);
        const webRedircctArr = redirectAB.split(',');
        containSource = webRedircctArr.includes(pwaSource); // 不包含跳转
    }

    // console.log(
    //     'redirect_web',
    //     'node_server',
    //     'can-redirect',
    //     `redirect:${!containSource}`,
    //     `app-device-id:${JSON.stringify({ deviceId, redirectAB })}`
    // );
    if (!containSource) {
        const currentUrl = req.url;
        const redirectUrl = new URL(`https://www.kwai.com${currentUrl}`);
        if (webDid) {
            redirectUrl?.searchParams?.set('webDid', webDid);
        }
        if (deviceId) {
            redirectUrl?.searchParams?.set('deviceId', deviceId);
        }
        if (redirectUrl?.searchParams.has('ignore_host')) {
            redirectUrl?.searchParams?.delete('ignore_host');
        }
        const redirectUrlStr = redirectUrl?.toString();
        // eslint-disable-next-line no-undef
        const notRedirectV = getNotExecuteRedirectQuey(req, 'not_redirect');
        if (notRedirectV !== 'true') {
            redirect(redirectUrlStr);
        }
        // console.log('redirect_web', 'node_server', 'redirect-path: ', redirectUrlStr);
    }
};
