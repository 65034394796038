// development staging production
const MY_NODE_ENV = process.env.MY_NODE_ENV || process.env.NODE_ENV || 'development';

exports.MY_NODE_ENV = MY_NODE_ENV;

const isProduction = MY_NODE_ENV === 'production' || MY_NODE_ENV === 'production_csr';
exports.isProduction = isProduction;

const envPath = {
    production: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kwai-nuxt-pwa-online/',
    test: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kwai-nuxt-pwa-test/',
    production_csr: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kwai-nuxt-pwa-csr/',
    test_csr: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kwai-nuxt-pwa-csr-test/',
    backup_test: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kwai-nuxt-pwa-backup-test/',
    backup_production: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kwai-nuxt-pwa-backup-online/',
};

const isCsr = MY_NODE_ENV.includes('csr');
exports.isLocal = process.env.NODE_ENV === 'local';
exports.isCsr = isCsr; // 这个是用给小包的
exports.IS_BACKUP_ENV = process.env.IS_BACKUP_ENV; // 这个东西是用在兜底的

function getPublicPath() {
    if (isCsr) {
        return envPath[MY_NODE_ENV];
    }
    if (process.env.IS_BACKUP_ENV) {
        return envPath[isProduction ? 'backup_production' : 'backup_test'];
    }
    return envPath[isProduction ? 'production' : 'test'];
}

exports.publicPath = getPublicPath();

exports.swConfig = isCsr
    ? {
          swFileName: 'sw-tiny.js',
          swScope: '/tiny',
      }
    : {
          swFileName: 'sw.js',
          swScope: '/',
      };

const serverHosts =
    MY_NODE_ENV === 'production'
        ? {
              overseaAPI: 'http://sgp.kwaipro-api.internal',
              // overseaAPI: 'https://kwai-pwa-hzs.test.gifshow.com',
              abGroup: 'http://sgp.kwaipro-api.internal',
              kconfAPI: 'http://sgp.kwaipro-api.internal',
          }
        : {
              //   overseaAPI: 'https://kwai-pwa-mobile.test.gifshow.com',
              // overseaAPI: 'https://kwai-pwa-pc.test.gifshow.com',
              overseaAPI: 'https://kwai-pwa-hzs.test.gifshow.com',
              abGroup: 'https://kwai-common-service.test.gifshow.com',
              kconfAPI: 'https://kwai-h5.test.gifshow.com',
          };

exports.serverHosts = serverHosts;
