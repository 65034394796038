import * as yoda from '@yoda/bridge';
import cookies from 'js-cookie';

import Vue from 'vue';
import CommonPlugin from '../common/capacitor-plugins';

window.$yoda = { ...yoda };

window.parseIkwaiProtocol = ikwaiString => {
    // eslint-disable-next-line no-unused-expressions
    const oldKwaiStringVersion = compareVersion(cookies.get('appver'), '10.0.30') < 0;
    console.log('oldKwaiStringVersion', oldKwaiStringVersion, ikwaiString);
    // 这是兼容最新版本的
    if (!oldKwaiStringVersion) {
        const uriString = JSON.parse(ikwaiString).uri;
        console.log('uriString', uriString);
        if (uriString.startsWith('ikwai://')) {
            const { origin } = window.location;
            const isInTinyForyou = window.location.href.match('/tiny/foryou');
            const noIkwaiStr = uriString.replace('ikwai://', '');
            const id = noIkwaiStr.split('/')[1]?.split('?')[0];

            if (noIkwaiStr.startsWith('home') && !isInTinyForyou) {
                window.location.href = `${origin}/tiny/foryou?hyId=tinyPackage`;
                return;
            }

            if (noIkwaiStr.startsWith('work')) {
                window.location.href = `${origin}/tiny/video?photoId=${id}&&hyId=tinyPackage`;
                return;
            }

            if (noIkwaiStr.startsWith('profile')) {
                window.location.href = `${origin}/tiny/user?kwaiId=${id}&&hyId=tinyPackage`;
                return;
            }
            // 如果不在foryou页面 都拉到foryou页面上
            if (!isInTinyForyou) {
                window.location.href = `${origin}/tiny/foryou?hyId=tinyPackage`;
            }
        }

        if (uriString.startsWith('kwai://')) {
            const { origin } = window.location;
            const isInTinyForyou = window.location.href.match('/tiny/foryou');
            const noIkwaiStr = uriString.replace('kwai://', '');
            const id = noIkwaiStr.split('/')[1]?.split('?')[0];

            if (noIkwaiStr.startsWith('home') && !isInTinyForyou) {
                window.location.href = `${origin}/tiny/foryou?hyId=tinyPackage`;
                return;
            }

            if (noIkwaiStr.startsWith('work')) {
                window.location.href = `${origin}/tiny/video?photoId=${id}&&hyId=tinyPackage`;
                return;
            }

            if (noIkwaiStr.startsWith('profile')) {
                window.location.href = `${origin}/tiny/user?kwaiId=${id}&&hyId=tinyPackage`;
                return;
            }
            // 如果不在foryou页面 都拉到foryou页面上
            if (!isInTinyForyou) {
                window.location.href = `${origin}/tiny/foryou?hyId=tinyPackage`;
            }
        }
    }
    // cookie中 appver 10.0.30
    if (oldKwaiStringVersion) {
        console.log('ikwaiString', ikwaiString);
        if (ikwaiString.startsWith('ikwai://')) {
            const { origin } = window.location;
            const isInTinyForyou = window.location.href.match('/tiny/foryou');
            const noIkwaiStr = ikwaiString.replace('ikwai://', '');
            const id = noIkwaiStr.split('/')[1]?.split('?')[0];

            if (noIkwaiStr.startsWith('home') && !isInTinyForyou) {
                window.location.href = `${origin}/tiny/foryou?hyId=tinyPackage`;
                return;
            }

            if (noIkwaiStr.startsWith('work')) {
                window.location.href = `${origin}/tiny/video?photoId=${id}&&hyId=tinyPackage`;
                return;
            }

            if (noIkwaiStr.startsWith('profile')) {
                window.location.href = `${origin}/tiny/user?kwaiId=${id}&&hyId=tinyPackage`;
                return;
            }
            // 如果不在foryou页面 都拉到foryou页面上
            if (!isInTinyForyou) {
                window.location.href = `${origin}/tiny/foryou?hyId=tinyPackage`;
            }
        }

        if (ikwaiString.startsWith('kwai://')) {
            const { origin } = window.location;
            const isInTinyForyou = window.location.href.match('/tiny/foryou');
            const noIkwaiStr = ikwaiString.replace('kwai://', '');
            const id = noIkwaiStr.split('/')[1]?.split('?')[0];

            if (noIkwaiStr.startsWith('home') && !isInTinyForyou) {
                window.location.href = `${origin}/tiny/foryou?hyId=tinyPackage`;
                return;
            }

            if (noIkwaiStr.startsWith('work')) {
                window.location.href = `${origin}/tiny/video?photoId=${id}&&hyId=tinyPackage`;
                return;
            }

            if (noIkwaiStr.startsWith('profile')) {
                window.location.href = `${origin}/tiny/user?kwaiId=${id}&&hyId=tinyPackage`;
                return;
            }
            // 如果不在foryou页面 都拉到foryou页面上
            if (!isInTinyForyou) {
                window.location.href = `${origin}/tiny/foryou?hyId=tinyPackage`;
            }
        }
    }
};

// 版本对比
// vStr1 > vStr2 return 1
// vStr1 < vStr2 return -1
// vStr1 = vStr2 return 0
function compareVersion(vStr1, vStr2) {
    const v1 = vStr1.split('.');
    const v2 = vStr2.split('.');
    const len = Math.max(v1.length, v2.length);

    while (v1.length < len) {
        v1.push('0');
    }
    while (v2.length < len) {
        v2.push('0');
    }

    for (let i = 0; i < len; i++) {
        const num1 = parseInt(v1[i], 10);
        const num2 = parseInt(v2[i], 10);

        if (num1 > num2) {
            return 1;
        }

        if (num1 < num2) {
            return -1;
        }
    }
    return 0;
}
Vue.use(CommonPlugin);
