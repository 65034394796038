import { onMounted, ref, Ref, getCurrentInstance } from 'vue';
import { getStatusHT } from '~/common/utils';

enum PackageName {
    BOMDIA_APP = 'bom.dia.video.imagen.frases.download',
    AI_CHAT_APP = 'ai.questionai.chat.bot',
    AI_CHAT_APP_IOS = 'ai.questionai.chat.bot',
}

export const isIOS_CSR = ref(false);

export const useAppEnvironment = () => {
    const { proxy } = getCurrentInstance() || {};

    const isAppEnvironment = proxy?.$isAppEnvironment ?? false;

    const isBomdiaApp = proxy?.$isAppEnvironment && proxy?.$appConfig?.pkg === PackageName.BOMDIA_APP;

    const isAiChatApp =
        proxy?.$isAppEnvironment && (proxy?.$appConfig?.pkg === PackageName.AI_CHAT_APP || proxy?.$appConfig?.pkg === PackageName.AI_CHAT_APP_IOS);

    // const isIOS = ref(proxy?.$appConfig?.isIOS === 'true'); // 以后用
    const isIOS = ref(false);
    const isKwaiApp = isAppEnvironment && !isBomdiaApp && !isAiChatApp;

    const isKwaiAppCSR = ref(false);

    const statusHT: Ref<number> = ref(0);

    onMounted(() => {
        statusHT.value = getStatusHT();
        isKwaiAppCSR.value = isAppEnvironment && !isBomdiaApp && !isAiChatApp;
        // isIOS.value = proxy?.$appConfig?.isIOS === 'true';
        isIOS_CSR.value = isIOS_CSR.value || proxy?.$appConfig?.isIOS === 'true';
    });

    return {
        isAppEnvironment,
        isBomdiaApp,
        isAiChatApp,
        isKwaiApp,
        statusHT,
        isKwaiAppCSR,
        isIOS,
    };
};

export default useAppEnvironment;
