import { invoke, InvocationOptions } from '@yoda/bridge';
import { Capacitor } from '@capacitor/core';
import commonPlugin from './capacitor-plugins';
import { isIOS_CSR } from '~/pages/aichat/hooks/useAppEnvironment';

/** ========= common start ========= */

enum CommonBridgeResultCode {
    Failed = 0,
    Success = 1,
}

interface CommonBridgeRes {
    result?: CommonBridgeResultCode;
    message?: string;
    businessCode?: number;
}

/** ========= common end ========= */

/** ========= business start ========= */

interface OpenWebviewParams {
    url: string;
}

interface SaveMediaToAlbumParams {
    mediaType: 'video' | 'image';
    url: string;
}

interface SetWebConfigParams {
    key: string;
    value: string;
}

/** ========= business end ========= */

class Bridge {
    private invokeBridge<T extends Record<string, any>>(bridgeName: string, params?: Record<string, any>, options?: InvocationOptions) {
        return invoke(...([bridgeName, params, options] as Parameters<typeof invoke>)) as unknown as CommonBridgeRes & T;
    }

    async openWebview(params: OpenWebviewParams) {
        const res = await this.invokeBridge('webview.open', params);

        return res;
    }

    async closeWebview() {
        const res = await this.invokeBridge('component.backOrCloseWeb', {
            closeWeb: true,
        });

        return res;
    }

    async saveMediaToAlbum(params: SaveMediaToAlbumParams) {
        const res = await this.invokeBridge('component.saveMediaToAlbum', params);

        return res;
    }

    async openWhatsAppStatusSaver() {
        const res = await this.invokeBridge('ug.openWhatsAppStatusSaver');

        return res;
    }

    async setWebConfig(params: SetWebConfigParams) {
        const res = await this.invokeBridge('component.setWebConfig', params);

        return res;
    }

    async openPrivatePrivacyPage() {
        if (isIOS_CSR.value && Capacitor.isNativePlatform()) {
            const res = await commonPlugin.openPrivatePrivacyPage();
            return res;
        }

        const res = await this.invokeBridge('ug.openPrivatePrivacyPage');
        return res;
    }
}

export const PwaBridge = new Bridge();
