// eslint-disable-next-line import/no-extraneous-dependencies
import { Module } from 'vuex';
import { UserInfoByLogin } from '@/api/user';

interface UserModuleType {
    userInfo?: UserInfoByLogin;
}

const defaultState: UserModuleType = {
    userInfo: undefined,
};

export const userModule: Module<UserModuleType, any> = {
    namespaced: true,
    state: defaultState,
    mutations: {
        setUserInfo(state, userInfo?: UserInfoByLogin) {
            state.userInfo = {
                ...(state.userInfo || {}),
                ...(userInfo || {}),
            };
        },
    },
    actions: {},
};
