import Vue from 'vue';
import VueGtag from 'vue-gtag';
import cookies from 'js-cookie';

const getTagId = () => {
    switch (location.host) {
        case 'www.kwai.com':
            return 'G-Y5KRX0YK45'
        case 'www.snackvideo.com':
            return 'G-H7QQTWR2RN'
        case 'wwwt.kwai.com':
            return 'G-415K3R9MZQ'
        default:
            return ''
    }
};
const id = getTagId()

Vue.use(VueGtag, {
    config: {
        id,
        params: {
            send_page_view: true,
            webDid: cookies.get('webDid') || ''
        }
    }
})
