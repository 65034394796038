// eslint-disable-next-line import/no-extraneous-dependencies
import { Module } from 'vuex';
import { AIFeed, AIFeedLiked } from '@/api/ai-feed';
import { AIFeedPageEnum } from './ai-feed';

/*
 * foryou 单列 AI 引导
 */
export type AIChatBigCardType = string;
export type AIChatBigCardListOriginType = Partial<Record<AIFeedPageEnum, Array<AIFeed>>>;

interface AIChatBigCardMoudleType {
    feedListOrigin?: AIChatBigCardListOriginType;
    hasServerFetchedData?: boolean;
}

const defaultState: () => AIChatBigCardMoudleType = () => ({
    feedListOrigin: {
        [AIFeedPageEnum.AIChatBigCard]: [],
    },
    hasServerFetchedData: false,
});

export const aiChatBigCardMoudle: Module<AIChatBigCardMoudleType, any> = {
    namespaced: true,
    state: defaultState,
    mutations: {
        setFeedList(state, newFeedOrigin: AIChatBigCardListOriginType) {
            const newFeedListOrigin = Object.keys(newFeedOrigin || {}).reduce<AIChatBigCardListOriginType>((res, currentKey) => {
                const existingFeeds = state?.feedListOrigin?.[currentKey as AIFeedPageEnum] || [];
                const newFeeds = (newFeedOrigin[currentKey as AIFeedPageEnum] || []).map(feed => ({
                    ...feed,
                    liked: AIFeedLiked.UnLike, // 添加 like 属性，默认值为 false
                    index: 0,
                }));
                return {
                    ...res,
                    [currentKey]: [...existingFeeds, ...newFeeds],
                };
            }, {});

            state.feedListOrigin = {
                ...state.feedListOrigin,
                ...newFeedListOrigin,
            };
        },

        resetFeedList(state, feedPage?: AIChatBigCardType) {
            if (!feedPage) {
                const _state = defaultState();
                state.feedListOrigin = _state.feedListOrigin;
                return;
            }
            state.feedListOrigin = {
                ...state.feedListOrigin,
                [feedPage]: [],
            };
        },
    },
    actions: {},
};
