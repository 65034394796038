import Vue from 'vue';

function getSchema(compName, env) {
    env = env || this?.$getRuntimeEnv?.();
    return this?.$store?.state?.schemaModule?.currentSchema?.[compName]?.[env] || {};
}

Vue.prototype.$getSchema = getSchema;

export default (ctx, inject) => {
    inject('$getSchema', getSchema);
};
