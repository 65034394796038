
import { defineComponent, onMounted } from 'vue';
import { genWebDidAndSessionId } from '@/common/utils';

export default defineComponent({
    components: {},
    setup() {
        onMounted(async () => {
            genWebDidAndSessionId();
        });

        return {};
    },
});
