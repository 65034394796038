/* eslint-disable import/no-extraneous-dependencies */
import { NuxtAxiosInstance } from 'vue/types/vue';
import { AxiosRequestConfig } from 'axios';
import { ResponseBase } from './base';

import axios from './request';

type GetKConfigRequest = {
    /**
     * @zh kconf 配置空间
     */
    paths?: Array<string>;
};

interface GetKConfigResponse extends ResponseBase {
    data?: {
        [key: string]: string;
    };
}

export class KConfApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        this.$axios = axios;
    }

    async getKConfByKey(data: GetKConfigRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<GetKConfigResponse>('kconfAPI::/rest/o/w/kconf/loggedOut', data, config);

        return res;
    }
}

export const KConfApi = new KConfApiService();
