
import { defineComponent, ref, SetupContext, getCurrentInstance, onMounted, PropType, computed } from 'vue';
import chatIcon from '../../images/tab-chat-inactive.png';
import chatIconActive from '../../images/tab-chat-active.png';
import exploreIcon from '../../images/tab-discover-inactive.png';
import exploreIconActive from '../../images/tab-discover-active.png';
import aiIcon from '../../images/tab-create-inactive.png';
import aiIconActive from '../../images/tab-create-active.png';
import notifyIcon from '../../images/tab-inbox-inactive.png';
import notifyIconActive from '../../images/tab-inbox-active.png';
import profileIcon from '../../images/tab-profile-inactive.png';
import profileIconActive from '../../images/tab-profile-active.png';

const AITTabBar = defineComponent({
    components: {},
    props: {},
    emits: ['onClickSearch', 'onClickMore', 'onClickHistory', 'onClickMute'],
    setup(props, ctx: SetupContext<Array<'onClickSearch' | 'onClickHistory' | 'onClickMute'>>) {
        const { proxy } = getCurrentInstance() as { proxy: Record<string, any> };
        // 当前激活的标签页索引
        const active = ref(0);

        // 图标资源

        // 标签切换事件处理
        const onChange = (index: any) => {
            console.log('当前标签页索引:', index);
        };
        // Life circles
        onMounted(() => {});

        return {
            active,
            chatIcon,
            chatIconActive,
            exploreIcon,
            exploreIconActive,
            aiIcon,
            aiIconActive,
            notifyIcon,
            notifyIconActive,
            profileIcon,
            profileIconActive,
            onChange,
        };
    },
});

export default AITTabBar;
