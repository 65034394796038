import type { Plugin, PluginListenerHandle } from '@capacitor/core';

import { registerPlugin, WebPlugin } from '@capacitor/core';

export interface CommonPlugin extends Plugin {
    openPrivatePrivacyPage(options?: { value: string }): Promise<{ value: string }>;
}
class CommonWeb extends WebPlugin implements CommonPlugin {
    constructor() {
        super();
    }

    async install(): Promise<void> {
        console.log('Common 插件在浏览器中不可用');
    }

    async openPrivatePrivacyPage(): Promise<{ value: string }> {
        console.log('Common 插件在浏览器中不可用');
        return { value: 'Common 插件在浏览器中不可用' };
    }
}
const commonPlugin = registerPlugin<CommonPlugin>('Common', { web: new CommonWeb() });

export default commonPlugin;
