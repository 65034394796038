import { immediateSendLog, sendLog } from '~/common/radar';
import { AIFeed } from '~/api/ai-feed';
import { AIActionType } from '../components/ai-actions-bar/scripts/ai_common';
import { commonPvParams, getQuery } from '~/common/utils';

/* PV */
export const logForBotPageShow = (bot_index: number, user_id: string, country: string, botId: string) => {
    const params = commonPvParams({ defaultPageSource: 'aichat' });
    immediateSendLog({
        type: 'pv',
        name: 'AI_FRIENDS',
        value: {
            bot_id: botId || '',
            country,
            user_id,
            ...params,
            bot_index,
        },
    });
};

/* Click */
export const logForClickTopbarAction = (type: 'search' | 'history') => {
    sendLog({
        type: 'click',
        name: 'AI_CLICK_TOPBAR',
        value: {
            type,
        },
    });
};

// 引导对话点击消息
export const logForClickGuideChatAction = (feed: AIFeed, reply: string, reply_index: number) => {
    sendLog({
        type: 'click',
        name: 'GUIDE_DIALOG',
        value: {
            bot_id: feed.bot_id_str,
            reply_content: reply,
            reply_index,
        },
    });
};

// Input Box
export const logForInputBox = (bot_id: string = '', input_type: 'voice' | 'text', click_area: 'chat_input' | 'type_to_vocie' | 'type_to_text') => {
    sendLog({
        type: 'click',
        name: 'CHAT_BOX_BUTTON',
        value: {
            bot_id,
            input_type,
            click_area,
        },
    });
};

export const logForInputBoxSwitchMode = (
    bot_id: string = '',
    input_type: 'text' | 'voice',
    click_area: 'chat_input' | 'type_to_vocie' | 'type_to_text'
) => {
    logForInputBox(bot_id, input_type, click_area);
};

export const logForInputBoxTextClick = (bot_id: string = '') => {
    logForInputBox(bot_id, 'text', 'chat_input');
};

export const logForInputBoxVoiceClick = (bot_id: string = '') => {
    logForInputBox(bot_id, 'voice', 'chat_input');
};

// 功能区
export const logForClickAction = (type: AIActionType, feed?: AIFeed) => {
    let typeStr = '';
    if (type === AIActionType.Like) {
        typeStr = 'like';
    } else if (type === AIActionType.UnLike) {
        typeStr = 'unlike';
    } else if (type === AIActionType.Share) {
        typeStr = 'share';
    } else {
        typeStr = 'view_history';
    }
    sendLog({
        type: 'click',
        name: 'INTERACT_BUTTON',
        value: {
            click_type: typeStr,
            bot_id: feed?.bot_id_str || '',
        },
    });
};

// bot信息弹窗内部点击
export const logForClickInfoPopupAction = (type: 'share' | 'report' | 'add_to_desktop', feed?: AIFeed) => {
    sendLog({
        type: 'click',
        name: 'CHARACTER_INFO_POPUP',
        value: {
            click_type: type,
            bot_id: feed?.bot_id_str || '',
        },
    });
};

// 安装pwa弹窗
export const logForClickInstallAction = (type: 'ok' | 'cancel', feed?: AIFeed) => {
    sendLog({
        type: 'click',
        name: 'ADD_TO_DESKTOP_POPUP',
        value: {
            type,
            bot_id: feed?.bot_id_str || '',
        },
    });
};

/* Show */
// bot信息弹窗展示
export const logForInfoShowAction = (feed?: AIFeed) => {
    sendLog({
        type: 'show',
        name: 'CHARACTER_INFO_POPUP',
        value: {
            bot_id: feed?.bot_id_str || '',
        },
    });
};

// bot展示埋点
export const logForBotShow = (bot_id: string, index: number, userId: string, country: string) => {
    logForBotPageShow(index + 1, userId, country, bot_id);
};

/* Task(show/click) */

// bot 发送、回复消息
export const logForChatWithBot = (
    botId?: string,
    conversationId?: string,
    index?: number,
    senderContent?: string,
    replierContent?: string,
    inputType?: 'text' | 'vioce',
    result?: 'none' | 'success' | 'failed',
    responseTime?: number,
    isMyself?: boolean,
    replyType?: 'auto_send' | undefined
) => {
    const newIndex = index ? index + 1 : 1;
    sendLog({
        type: 'show',
        name: 'AI_BOT_CHAT',
        value: {
            conversation_id: conversationId || '',
            bot_id: botId || '',
            index: newIndex,
            send_content: senderContent || '',
            reply_content: replierContent || '',
            send_content_type: inputType,
            result,
            response_time: responseTime,
            is_myself: isMyself,
            reply_type: replyType,
        },
    });
};

// bot发送事件
export const logForChatWithBotSend = (
    botId?: string,
    conversationId?: string,
    index?: number,
    senderContent?: string,
    inputType?: 'text' | 'vioce',
    isMyself?: boolean,
    reply_type?: 'auto_send' | undefined
) => {
    logForChatWithBot(botId, conversationId, index, senderContent, '', inputType, 'none', 0, isMyself, reply_type);
};

// bot回复事件
export const logForChatWithBotResponse = (
    botId?: string,
    conversationId?: string,
    index?: number,
    senderContent?: string,
    replierContent?: string,
    inputType?: 'text' | 'vioce',
    result?: 'success' | 'failed',
    responseTime?: number,
    isMyself?: boolean,
    replyType?: 'auto_send' | undefined
) => {
    logForChatWithBot(botId, conversationId, index, senderContent, replierContent, inputType, result, responseTime, isMyself, replyType);
};

// bot回复task失败
export const logForChatWithBotFailed = (
    botId?: string,
    conversationId?: string,
    index?: number,
    sender?: string,
    replier?: string,
    inputType?: 'text' | 'vioce',
    responseTime?: number
) => {
    logForChatWithBotResponse(botId, conversationId, index, sender, replier, inputType, 'failed', responseTime, false);
};

// 顶部Top Tab
export const logForTopTabsSwitchShow = (tab_name: 'discovers' | 'ai_friends') => {
    sendLog({
        type: 'show',
        name: 'AIFRIEND_TAB',
        value: {
            tab_name,
        },
    });
};

export const logForTopTabsSwitchClick = (tab_name: 'discovers' | 'ai_friends' | 'messages' | 'history' | 'chat_list') => {
    sendLog({
        type: 'click',
        name: 'AIFRIEND_TAB',
        value: {
            tab_name,
        },
    });
};

// 顶部Top Bar
export const logForTopBarInboxBtnClick = (bot_id?: string, bot_index?: string) => {
    sendLog({
        type: 'click',
        name: 'MESSAGE_BUTTON',
        value: {
            bot_id,
            bot_index,
        },
    });
};

// 顶部Top Bar性别选择按钮
export const logForTopBarGenderSelectBtnClick = (bot_id?: string, bot_index?: string) => {
    sendLog({
        type: 'click',
        name: 'GENDER_SELECT_BUTTON',
        value: {
            bot_id,
            bot_index,
        },
    });
};

// 顶部Top Bar UGC入口
export const logForTopBarUGCEntranceBtnClick = (bot_id?: string, bot_index?: string) => {
    sendLog({
        type: 'click',
        name: 'ADD_NEWBOT_BUTTON',
        value: {
            bot_id,
            bot_index,
        },
    });
};

// 点击语聊声音播放
export const logPlayAudioMsgBtnClick = (bot_id?: string, button_type?: string, index?: Number, message_index?: Number) => {
    console.log('ai_text_audio', `index = ${index}, message_index = ${message_index}`);
    sendLog({
        type: 'click',
        name: 'PLAY_SOUND_BUTTON',
        value: {
            bot_id,
            button_type,
            index: index || 0 + 1,
            message_index: message_index || 0 + 1,
        },
    });
};
