
import { defineComponent, computed, getCurrentInstance, ref, onMounted, onActivated, watch } from 'vue';
import Tabs from 'vant/lib/tabs';
import Tab from 'vant/lib/tab';
import { radarLog, sendLog } from '~/common/radar';
import { isProduction } from '~/common/env';
import { logForTopTabsSwitchClick, logForTopTabsSwitchShow } from '../../scripts/logger';
import useAppEnvironment from '../../hooks/useAppEnvironment';
import { AIChat } from '~/store/modules/ai-conversation';
import { checkIfAppEnvironment } from '@/common/app';

enum AITopTabsEnum {
    AI_FOR_YOU = 0,
    FOR_YOU = 1,
    DISCOVER = 2,
    MESSAGES = 3,
    HISTORY = 4,
    CHATLIST = 5,
}

const TopTabs = defineComponent({
    components: {
        vanTabs: Tabs,
        vanTab: Tab,
    },
    props: {
        currentIndex: {
            type: Number,
            default: 0,
        },
    },

    setup(props) {
        const { proxy } = getCurrentInstance() as { proxy: Record<string, any> };
        const { isIOS } = useAppEnvironment();
        const activeTab = computed<AITopTabsEnum>(() => {
            const TabRegexpList = [
                {
                    reg: /^\/(foryou)$|@[\w-]+\/video(\/[\w-]+)?$/,
                    tab: AITopTabsEnum.FOR_YOU,
                },
                {
                    reg: /\/(aichat(\/bot\/[\w-]+)?|aichat)$|@[\w-]+\/video(\/[\w-]+)?$/,
                    tab: AITopTabsEnum.AI_FOR_YOU,
                },
                {
                    reg: /^\/((aichat\/discover?)$|@[\w-]+\/video(\/[\w-]+)?)$/,
                    tab: AITopTabsEnum.DISCOVER,
                },
                {
                    reg: /^\/((aichat\/inbox?)$|@[\w-]+\/video(\/[\w-]+)?)$/,
                    tab: AITopTabsEnum.MESSAGES,
                },
                {
                    reg: /^\/((aichat\/history?)$|@[\w-]+\/video(\/[\w-]+)?)$/,
                    tab: AITopTabsEnum.HISTORY,
                },
                {
                    reg: /^\/((aichat\/chatlist?)$|@[\w-]+\/video(\/[\w-]+)?)$/,
                    tab: AITopTabsEnum.CHATLIST,
                },
            ];
            const currentPath = proxy?.$route?.path;

            return TabRegexpList.find(item => item.reg.test(currentPath))?.tab || AITopTabsEnum.AI_FOR_YOU;
        });

        const showHistoryTab = ref(false);
        const canShowHistoryTab = () => {
            const { conversations } = proxy?.$store?.state?.conversationModule;
            if (!conversations) return false;
            const aichats = (Object.values(conversations) as AIChat[])
                ?.filter(item => {
                    return item?.cached === true;
                })
                ?.sort((a, b) => {
                    return b.timestamp?.getTime() - a.timestamp?.getTime();
                });

            return aichats.length > 0;
        };

        const showChatListDot = computed(() => {
            const { hasAutomaticMessage } = proxy?.$store?.state?.conversationModule;
            return !!hasAutomaticMessage && !proxy?.$route?.path?.includes?.('/aichat/chatlist');
        });

        const isAIchat = computed(() => {
            return [AITopTabsEnum.AI_FOR_YOU, AITopTabsEnum.DISCOVER, AITopTabsEnum.MESSAGES, AITopTabsEnum.HISTORY, AITopTabsEnum.CHATLIST].includes(
                activeTab.value
            );
        });

        const { statusHT } = useAppEnvironment();

        const sendRadarLog = (title?: string) => {
            radarLog({
                name: 'PWA_WEB_CLICK_TOP_BAR',
                event_type: 'click',
                category: 'KWAI_SHARE',
                message: `click tab: ${title}`,
                src: window.location.href,
            });
        };

        const TabList = computed<
            Array<{
                key?: string | number;
                title?: string;
                dot?: boolean;
                visible?: boolean;
                onClick?: () => Promise<void> | void;
            }>
        >(() => {
            return [
                {
                    key: AITopTabsEnum.HISTORY,
                    title: proxy?.$root?.$t?.('k_1322549') || 'History',
                    dot: false,
                    visible: isAIchat.value && showHistoryTab.value,
                    onClick: () => {
                        logForTopTabsSwitchClick('history');
                        proxy.$router.replace({ path: '/aichat/history', query: { ...(proxy?.$route?.query || {}) } });
                        sendRadarLog(proxy?.$root?.$t?.('k_1322549') || 'History');
                    },
                },
                {
                    key: AITopTabsEnum.AI_FOR_YOU,
                    title:
                        (isAIchat.value ? proxy?.$root?.$t?.('k_1310875') : proxy?.$root?.$t?.(proxy?.$store?.state?.startup?.changetab)) ||
                        'AI Friends',
                    dot: false,
                    visible: true,
                    onClick: () => {
                        if (activeTab.value === AITopTabsEnum.FOR_YOU) {
                            const currentUrl = new URL(window.location.href);
                            currentUrl.pathname = '/aichat';
                            if (isProduction) {
                                currentUrl.host = 'www.weeeye.com';
                            }
                            window.open(currentUrl.toString());
                        } else {
                            logForTopTabsSwitchClick('ai_friends');
                            proxy.$router.replace({ path: '/aichat', query: { ...(proxy?.$route?.query || {}) } });
                        }
                        sendRadarLog(proxy?.$root?.$t?.('k_1310875') || 'For You');
                    },
                },
                {
                    key: AITopTabsEnum.DISCOVER,
                    title: proxy?.$root?.$t?.('k_1310876') || 'Discover',
                    dot: false,
                    visible: isAIchat.value && !isIOS.value,
                    onClick: () => {
                        logForTopTabsSwitchClick('discovers');
                        proxy.$router.replace({ path: '/aichat/discover', query: { ...(proxy?.$route?.query || {}) } });
                        sendRadarLog(proxy?.$root?.$t?.('k_1310876') || 'Discover');
                    },
                },
                {
                    key: AITopTabsEnum.CHATLIST,
                    title: proxy?.$root?.$t?.('Chat') || 'Chat',
                    dot: showChatListDot.value,
                    visible:
                        isAIchat.value &&
                        proxy?.$store?.state?.abTestModule?.abTestParams?.a_chat_tab_entry &&
                        !checkIfAppEnvironment() &&
                        !isIOS.value,
                    onClick: () => {
                        logForTopTabsSwitchClick('chat_list');
                        proxy?.$store?.commit('conversationModule/setHasAutomaticMessage', false);
                        proxy.$router.replace({ path: '/aichat/chatlist', query: { ...(proxy?.$route?.query || {}) } });
                        sendRadarLog(proxy?.$root?.$t?.('Chat') || 'Chat');
                    },
                },
                {
                    key: AITopTabsEnum.FOR_YOU,
                    title: proxy?.$root?.$t?.('k_1310875') || 'For You',
                    dot: false,
                    visible: !isAIchat.value && !isIOS.value,
                    onClick: () => {
                        const title =
                            (isAIchat.value ? proxy?.$root?.$t?.('k_1310875') : proxy?.$root?.$t?.(proxy?.$store?.state?.startup?.changetab)) ||
                            'AI Friends';
                        proxy.$router.push({ path: '/foryou', query: { ...(proxy?.$route?.query || {}) } });
                        sendRadarLog(title);
                    },
                },
            ];
        });

        const handleTabClick = (key?: string | number) => {
            const clickTab = TabList?.value?.find?.(item => item.key === key);
            clickTab?.onClick?.();
        };

        const ai_follow_tab = computed<boolean>(() => {
            return proxy?.$store?.state?.abTestModule?.abTestParams?.ai_follow_tab;
        });

        onMounted(() => {
            setTimeout(() => {
                if (activeTab.value === AITopTabsEnum.AI_FOR_YOU || activeTab.value === AITopTabsEnum.DISCOVER) {
                    logForTopTabsSwitchShow(activeTab.value === AITopTabsEnum.AI_FOR_YOU ? 'ai_friends' : 'discovers');
                }
            }, 2000);
            showHistoryTab.value = canShowHistoryTab() && ai_follow_tab.value;

            const isShowChatList = isAIchat.value && proxy?.$store?.state?.abTestModule?.abTestParams?.a_chat_tab_entry && !checkIfAppEnvironment();
            if (isShowChatList) {
                sendLog({
                    type: 'show',
                    name: 'AIFRIEND_TAB',
                    value: {
                        tab_name: 'chat_list',
                    },
                });
            }
        });

        onActivated(() => {});

        watch(
            () => showChatListDot.value,
            newVal => {
                if (newVal) {
                    sendLog({
                        type: 'show',
                        name: 'PROACTIVE_MESSAGE',
                        value: {},
                    });
                }
            }
        );

        return {
            handleTabClick,

            isAIchat,
            activeTab,
            statusHT,
            showHistoryTab,

            TabList,
        };
    },
});

export default TopTabs;
