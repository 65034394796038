import { PwaBridge } from '@/common/bridge';

export default function routerPlugin({ app, $isAppEnvironment = false, $appConfig, ...rest }) {
    app.router.beforeEach((to, from, next) => {
        try {
            /**
             * @zh 兼容旧版本 bomdia 打开 webview 的形式
             */
            const isBomdia = $appConfig?.pkg === 'bom.dia.video.imagen.frases.download';
            if (isBomdia) {
                if (!from.name && from.fullPath === '/') {
                    next();
                    return;
                }
                PwaBridge.openWebview({
                    url: `${window?.location?.origin}${to?.fullPath || ''}`,
                });
                return;
            }

            next();
        } catch {
            next();
        }
    });
}
