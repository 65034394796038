import { IncomingMessage } from 'http'

export interface DeviceInfo {
  isIOS: boolean;
  isAndroid: boolean;
}

const device = {
  isIOS(ua: string) {
    return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  },
  isAndroid(ua: string) {
    return ua.includes('Android') || ua.includes('Adr')
  },
}

export default (req?: IncomingMessage) => {
  let ua = null
  if (process.server) {
    ua = req?.headers['user-agent'] || ''
  } else {
    ua = navigator.userAgent;
  }

  return {
    isIOS: device.isIOS(ua),
    isAndroid: device.isAndroid(ua),
  }
}
