import { AIConversationStore } from './AIConversationStore';
import { AIChatStore } from './AIChatStore';
import { AIUserStore } from './AIUserStore';
import { AIStoreType, AIActionType } from './AIStoreType';
import { AIConversationID, AIMessageID, AIUserID } from '../models/AIModel';

type AIStoreListlist = string;
export class AIStore {
    public conversationStore: AIConversationStore;

    public chatStore: AIChatStore;

    public userStore: AIUserStore;

    constructor() {
        this.conversationStore = new AIConversationStore();
        this.chatStore = new AIChatStore();
        this.userStore = new AIUserStore();
    }

    async initialize() {
        await this.conversationStore.initialize();
        await this.chatStore.initialize();
        await this.userStore.initialize();
    }

    getConversationById(id: AIConversationID): any {
        return this.conversationStore.getConversationById(id);
    }

    getMessageById(id: AIMessageID): any {
        return this.chatStore.getMessageById(id);
    }

    getUserById(id: AIUserID): any {
        return this.userStore.getUserById(id);
    }

    watch(storeType: AIStoreType, options: { [key: AIStoreListlist]: (list: any[]) => void }) {
        switch (storeType) {
            case AIStoreType.Conversation:
                this.conversationStore.watch(storeType, event => {
                    if (event.action === AIActionType.Add) {
                        options.conversationList?.(this.conversationStore.getConversations());
                    }
                });
                break;
            case AIStoreType.Chat:
                this.chatStore.watch(storeType, event => {
                    if (event.action === AIActionType.Add) {
                        options.messageList?.(this.chatStore.getMessageById(event.message.conversationId));
                    }
                });
                break;
            case AIStoreType.User:
                this.userStore.watch(storeType, event => {
                    if (event.action === AIActionType.Add) {
                        options.userList?.(this.userStore.getUsers());
                    }
                });
                break;
            default:
                break;
        }
    }

    unwatch(storeType: AIStoreType, options: { [key: string]: (list: any[]) => void }) {
        switch (storeType) {
            case AIStoreType.Conversation:
                this.conversationStore.unwatch(storeType, event => {
                    if (event.action === AIActionType.Add) {
                        options.conversationList?.(this.conversationStore.getConversations());
                    }
                });
                break;
            case AIStoreType.Chat:
                this.chatStore.unwatch(storeType, event => {
                    if (event.action === AIActionType.Add) {
                        options.messageList?.(this.chatStore.getMessageById(event.message.conversationId));
                    }
                });
                break;
            case AIStoreType.User:
                this.userStore.unwatch(storeType, event => {
                    if (event.action === AIActionType.Add) {
                        options.userList?.(this.userStore.getUsers());
                    }
                });
                break;
            default:
                break;
        }
    }
}
export const UIAIStore = new AIStore();
