// eslint-disable-next-line import/no-extraneous-dependencies
import { Module } from 'vuex';
import { computed, getCurrentInstance } from 'vue';
import { UIAIConversationService } from '@/pages/aichat/scripts/ai-im/services/UIAIConversationService';
import { UIAIChatService } from '@/pages/aichat/scripts/ai-im/services/UIAIChatService';
import { AIFeed } from '~/api/ai-feed';
import { AIConversationModel, AIMessageModel, AIUserModel } from '~/pages/aichat/scripts/ai-im/models/AIModel';
import { UIAIStore } from '@/pages/aichat/scripts/ai-im/stores/UIAIStore';
import { UIAIUserService } from '~/pages/aichat/scripts/ai-im/services/UIUserServies';
import { AIFeedPageEnum } from './ai-feed';

export enum AIMessageType {
    me = 0,
    other = 1,
    introducation = 2,
    hi = 3,
    hi_mock_message = 4,
}

export enum AIMessageStatus {
    done = 0, // 默认
    loading = 1,
    progressing = 2,
    error = 3,
}

/**
 * @zh 消息是否已读
 */
export enum AIMessageReadStatus {
    Unknown = 0, // 用于非常规对话，如 introduce 等
    Unread = 1,
    Read = 2,
}

type BotId = string;
export interface AIMessage {
    botId: string;
    messageId: string;
    messageType: AIMessageType;
    content: string;
    messageStatus: AIMessageStatus;

    messageReadStatus?: AIMessageReadStatus;
}

export interface AIChat {
    botId?: string;
    bot?: AIFeed;
    messages: AIMessage[];
    cached: boolean;
    timestamp: Date;
}

export type AIConversationOriginalType = Partial<Record<BotId, AIChat>>;

export type AISelectedMessageInfo = AIMessage & {
    messageIndex?: number;
};

export type AIFeedbackMessageInfo = AIMessage & {
    type?: 'agree' | 'disagree';
    showFeedbackPopup?: boolean;
    messageIndex?: number;
};

export interface AIConversationModuleType {
    conversations?: AIConversationOriginalType;
    loadingMessages?: AIMessage[];
    likesUsers: AIUserModel[];
    selectedMessageInfo?: AISelectedMessageInfo;
    feedbackMessageInfoMap?: Record<string, AIFeedbackMessageInfo>;
    reportedBotIds?: Array<string>;
    isMockMessageInserted?: boolean;
    conversationModuleInitialized?: boolean;
    audioPlayMessageId: String;
    hasAutomaticMessage?: boolean;
}

// 数据库初始化
const initConversations = computed<AIConversationModel<AIFeed>[]>(() => {
    return UIAIConversationService.getConversations();
});

const defaultConversations = computed<AIConversationOriginalType>(() => {
    let result: AIConversationOriginalType = {};
    initConversations.value.forEach(element => {
        const aifeed = element.attachment;
        const conversationId = element.id;
        const messages: AIMessageModel<AIMessage>[] = UIAIChatService.getMessageListByConversationId(conversationId);
        const sortedMessages = messages.sort((a, b) => {
            return a.timestamp.getTime() - b.timestamp.getTime();
        });
        console.log('social-ai-conversation', 'default', messages, sortedMessages);
        const attachments = sortedMessages.map(message => message.attachment!).filter(attachment => attachment !== undefined && attachment !== null);
        if (aifeed?.bot_id_str && attachments) {
            const aichat: AIChat = {
                botId: aifeed.bot_id_str,
                bot: element.attachment,
                messages: attachments,
                cached: true,
                timestamp: element.lastMessage?.timestamp || new Date(),
            };
            result = { ...result, [aifeed.bot_id_str]: aichat };
        }
    });

    return result;
});

const defaultLikeUsers = () => {
    const users = UIAIUserService.getUsers();
    const filteredUsers = users.filter(user => user.like === true);
    return filteredUsers;
};

const defaultState: AIConversationModuleType = {
    conversations: {},
    loadingMessages: [],
    likesUsers: [],
    selectedMessageInfo: undefined,
    feedbackMessageInfoMap: undefined,
    reportedBotIds: [],
    isMockMessageInserted: false,
    conversationModuleInitialized: false,
    audioPlayMessageId: '',
    hasAutomaticMessage: false,
};

export const allBotsInfo = computed<AIFeed[]>(() => {
    const { proxy } = (getCurrentInstance() || {}) as { proxy: any };
    let feedListOrigin = proxy?.$store?.state?.aiFeedMoudle?.feedListOrigin;
    let feedList: AIFeed[] = feedListOrigin?.[AIFeedPageEnum.AIForyou] || [];
    const roomFeedList: AIFeed[] = feedListOrigin?.[AIFeedPageEnum.AIChatRoom] || [];
    feedList = [...feedList, ...roomFeedList];

    feedListOrigin = proxy?.$store?.state?.aiDiscoverMoudle?.feedListOrigin;

    Object.keys(feedListOrigin || {}).forEach(tabName => {
        const feedListDis = feedListOrigin[tabName] || [];
        feedList = [...feedList, ...feedListDis];
    });

    const likesUser: AIUserModel[] = proxy?.$store?.state?.conversationModule?.likesUsers;
    const likeBots = (likesUser || []).map(user => {
        return user.attachment;
    });

    feedList = [...feedList, ...likeBots];

    return feedList;
});

export const conversationModule: Module<AIConversationModuleType, any> = {
    namespaced: true,
    state: defaultState,
    mutations: {
        insertMockMessage(state) {
            state.isMockMessageInserted = true;
        },
        async initialize(state) {
            if (state.conversationModuleInitialized) {
                return;
            }
            await UIAIStore.initialize();
            state.conversations = defaultConversations.value;
            state.likesUsers = defaultLikeUsers();
            console.log('social-ai-conversation', 'initialize', defaultConversations.value);
            state.conversationModuleInitialized = true;
        },
        async storeMessage(
            state,
            payload: {
                botId?: BotId;
                message?: string;
                messageId?: string;
                type?: AIMessageType;
                messageStatus?: AIMessageStatus;
                messageReadStatus?: AIMessageReadStatus;
            }
        ) {
            if (!payload.botId || !payload?.messageId || payload.type === undefined) {
                return;
            }
            const { botId, messageId, messageStatus, type, message, messageReadStatus } = payload;

            let aiChat = state?.conversations?.[payload.botId];
            if (!aiChat) {
                // const { proxy } = getCurrentInstance() as { proxy: any };
                // const { feedListOrigin } = proxy?.$store?.state?.aiFeedMoudle || {};
                // const feedList: AIFeed[] = feedListOrigin?.[AIFeedPageEnum.AIForyou] || [];
                const feedList = allBotsInfo;
                const bot = feedList.value.find(feed => feed.bot_id_str === payload.botId);
                aiChat = { botId: payload.botId, bot, messages: [], cached: false, timestamp: new Date() };
            }

            if (messageStatus && messageStatus === AIMessageStatus.loading) {
                const loadingMessage = state.loadingMessages?.find(message => message.messageId === messageId);
                if (!loadingMessage) {
                    state.loadingMessages?.push({
                        botId,
                        messageId,
                        messageType: type,
                        content: '',
                        messageStatus: AIMessageStatus.loading,
                    });
                }
                const lastMessage = aiChat?.messages?.length > 0 ? aiChat?.messages[aiChat?.messages?.length - 1] : null;
                if (lastMessage?.messageId === messageId) {
                    // 如果已经存在，则不再添加（回复较快时，updateMessage先触发）
                    return;
                }
            }

            const messageObj: AIMessage = {
                botId,
                messageType: payload.type,
                messageId,
                content: message || '',
                messageStatus: messageStatus || AIMessageStatus.done,
                messageReadStatus,
            };

            if (type === AIMessageType.introducation || type === AIMessageType.hi) {
                const targetMessage = aiChat.messages.find(amessage => amessage.content === message);
                if (targetMessage) return;
                aiChat.messages.unshift(messageObj);
            } else {
                aiChat.cached = true;
                aiChat.messages.push(messageObj);
                aiChat.timestamp = new Date();
            }

            // 重复的hi mock消息不再添加
            if (
                type === AIMessageType.hi_mock_message &&
                aiChat?.messages?.map(message => message.messageType).includes(AIMessageType.hi_mock_message)
            ) {
                return;
            }

            if (messageStatus === AIMessageStatus.loading) {
                // 保证loading的message不入库
                const targetMessage = aiChat.messages.find(amessage => amessage.messageId === messageId);
                if (targetMessage) return;
            }

            state.conversations = { ...state.conversations, ...{ [payload.botId]: aiChat } };
            // 同步数据库
            if (
                (type === AIMessageType.other || type === AIMessageType.me || type === AIMessageType.hi_mock_message) &&
                (messageStatus === AIMessageStatus.done || messageStatus === undefined)
            ) {
                const userId = UIAIUserService.getUserIdByUserName(`${payload?.botId}-${aiChat?.bot?.bot_name}` || '');
                let user = UIAIUserService.getUserById(userId);
                if (!user) {
                    user = await UIAIUserService.addUser<AIFeed>(`${payload?.botId}-${aiChat?.bot?.bot_name}` || '', false, aiChat?.bot);
                }
                const conversationID = UIAIConversationService.getConversationIdByParticipants([userId || '']);
                let conversationModel = UIAIStore.getConversationById(conversationID || '');
                if (!conversationModel) {
                    conversationModel = await UIAIConversationService.addConversation<AIFeed>([user.id], new Date(), aiChat?.bot);
                }
                console.log('social-ai-conversation', 'sync to indexDB', userId, conversationID, type, conversationModel);
                UIAIChatService.sendTextMessage<AIMessage>(conversationModel?.id, '', messageObj?.content, messageObj);
            }

            // to-do 可以添加最多缓存多少条的控制
            console.log('social-ai-conversation', 'storeMessage', state.conversations, messageStatus);
        },

        async updateMessage(
            state,
            payload: { botId?: BotId; message?: string; messageId?: string; type?: AIMessageType; messageStatus?: AIMessageStatus }
        ) {
            if (!payload.botId || !payload?.messageId || payload.type === undefined) {
                return;
            }
            const aiChat = state?.conversations?.[payload.botId] || { botId: payload.botId, messages: [], cached: false, timestamp: new Date() };

            const { messageId, messageStatus } = payload;
            const targetMessage = aiChat.messages.find(message => message.messageId === messageId);
            console.log('social-ai-conversation', 'updateMessage', messageStatus, payload.message);
            if (!targetMessage || targetMessage.messageStatus === AIMessageStatus.done) {
                return;
            }
            const modifiedContent = `${targetMessage?.content}${payload.message ? payload.message : ''}`;
            targetMessage.content = modifiedContent;
            targetMessage.messageStatus = messageStatus !== undefined ? messageStatus : AIMessageStatus.done;
            state.conversations = { ...state.conversations, ...{ [payload.botId]: aiChat } };
            // 同步数据库
            if (messageStatus === AIMessageStatus.done) {
                const userId = UIAIUserService.getUserIdByUserName(`${payload?.botId}-${aiChat?.bot?.bot_name}` || '');
                const conversationID = UIAIConversationService.getConversationIdByParticipants([userId || '']);
                const conversationModel = UIAIStore.getConversationById(conversationID || '');
                UIAIChatService.sendTextMessage<AIMessage>(conversationModel?.id, '', targetMessage.content, targetMessage);
            }
            // to-do 可以添加最多缓存多少条的控制
        },
        /**
         * @zh 点开 bot 对话即认为所有信息已读
         */
        async updateMessageReadStatus(state, payload: { botId?: BotId }) {
            const aiChat = state?.conversations?.[payload?.botId || ''];
            if (!aiChat) {
                return;
            }
            const newMessageList = (aiChat?.messages || []).map(item => ({
                ...item,
                messageReadStatus: item.messageType === AIMessageType.other ? AIMessageReadStatus.Read : undefined,
            }));
            state.conversations = {
                ...state.conversations,
                ...{
                    [payload?.botId || '']: {
                        ...(aiChat || {}),
                        messages: newMessageList,
                    },
                },
            };

            // 同步数据库
            const userId = UIAIUserService.getUserIdByUserName(`${payload?.botId}-${aiChat?.bot?.bot_name}` || '');
            const conversationID = UIAIConversationService.getConversationIdByParticipants([userId || '']);
            const conversationModel = UIAIStore.getConversationById(conversationID || '');
            UIAIChatService.clearMessagesByConversationId(conversationModel?.id);
            // 加一个批量接口
            newMessageList.forEach(message => {
                UIAIChatService.sendTextMessage<AIMessage>(conversationModel?.id, '', message.content, message);
            });
        },
        deleteMessage(state, payload: { botId?: BotId; messageId?: string }) {
            if (!payload.botId || !payload?.messageId) {
                return;
            }
            const aiChat = state?.conversations?.[payload.botId];
            if (!aiChat) return;
            const { messageId } = payload;
            const targetMessage = aiChat.messages.find(message => message.messageId === messageId);
            console.log('social-ai-conversation', 'deleteMessage', targetMessage);
            if (!targetMessage) {
                console.log('social-ai-conversation', 'deleteMessage', 'no target message', targetMessage);
                return;
            }

            const indexToDelete = aiChat.messages.indexOf(targetMessage);
            if (indexToDelete > -1) {
                aiChat.messages.splice(indexToDelete, 1);
            }
            state.conversations = { ...state.conversations, ...{ [payload.botId]: aiChat } };
        },
        updateLikeUsers(state, payload: { botId?: BotId }) {
            state.likesUsers = defaultLikeUsers();
        },
        setSelectedMessageInfo(state, selectedMessageInfo?: AISelectedMessageInfo) {
            state.selectedMessageInfo = selectedMessageInfo;
        },
        setFeedbackMessageInfo(state, feedbackMessageInfoMap?: Record<string, AIFeedbackMessageInfo>) {
            state.feedbackMessageInfoMap = {
                ...(state.feedbackMessageInfoMap || {}),
                ...feedbackMessageInfoMap,
            };
        },
        setReportedBotIds(state, reportedBotIds?: string[]) {
            state.reportedBotIds = reportedBotIds || [];
        },
        setAudioPlayMessageId(state, audioPlayMessageId?: String) {
            state.audioPlayMessageId = audioPlayMessageId || '';
        },
        setHasAutomaticMessage(state, hasAutomaticMessage?: boolean) {
            state.hasAutomaticMessage = !!hasAutomaticMessage;
        },
    },
    actions: {
        storeMessage({ commit }, payload) {
            commit('storeMessage', payload);
        },
        updateMessage({ commit, state }, payload) {
            if (!payload.botId || !payload?.messageId || payload.type === undefined) {
                return;
            }
            const aiChat = state?.conversations?.[payload.botId];
            const { messageId } = payload;
            const targetMessage = aiChat?.messages.find(message => message.messageId === messageId);
            if (!targetMessage) {
                // commit('storeMessage', { ...payload, message: '' });
                return;
            }
            commit('updateMessage', payload);
        },
        deleteMessage({ commit }, payload) {
            commit('deleteMessage', payload);
        },
    },
};
