import cookies from 'js-cookie';
import queryString from 'query-string';
import { SystemApi } from '@/api/system';

// tools
export function nodeIsAppEnvironment() {
    return !!cookies?.get('pkg') && !!cookies?.get('appver');
}

export function isTiny() {
    return /^\/tiny\/.*/.test(window.location.pathname || '');
}

// Test
export function getNotExecuteRedirectQuey(key: string) {
    const parsed = queryString.parse(window.location.search);
    return parsed[key];
}

// get pwaSource
export function getPwaSource() {
    const parsed = queryString.parse(window.location.search);
    return parsed.pwa_source;
}

export function isFilmHost() {
    return window.location.host === 'www.kwai.film';
}

export function changeDidWithDeviceID(deviceID: string) {
    cookies.set('did', deviceID);
}

export async function getCkid(app: any) {
    const { status, data } = await app.$axios.$$get(`https://ads.mythad.com/rest/n/adintl/ad/getCkid`, { withCredentials: true });
    if (status === 200 && data) {
        return data;
    }
}

const getDeviceId = async ({ webDid, ckid }: { webDid?: string; ckid?: string }) => {
    if (ckid) {
        const res = await SystemApi.getDidByCookieId({ cookieId: ckid });
        return res;
    }
    const res = await SystemApi.getDidByWebDid({ webDid });
    return res;
};

export async function getDeviceIDByWebDid(app: any, webDid?: string | undefined, ckid?: string | undefined) {
    if (!webDid && !ckid) {
        // console.log('ids-utils', 'client', 'webDid and ckid is null', webDid, ckid);
        return;
    }
    const { result, data } = (await getDeviceId({ webDid, ckid })) || {};
    // console.log('ids-utils', 'client', 'result & data', result, data);
    let deviceId;
    let redirectAB;
    if (result === 1) {
        // const data = { redirectAB: 'share', white: 'true', deviceId: 'ANDROID_f89df9ed896e511f' };
        deviceId = data.deviceId;
        redirectAB = data.redirectAB;
        const testDevideId = getNotExecuteRedirectQuey('test_deviceId');
        const testRedirectab = getNotExecuteRedirectQuey('test_redirectab');
        if (testDevideId) {
            deviceId = testDevideId;
        }
        if (testRedirectab) {
            redirectAB = testRedirectab;
        }
    }
    return { deviceId, redirectAB };
}

export const checkWebDidCollect = () => {
    const _webDid_collect = cookies.get('_webDid_collect');
    if (!_webDid_collect) {
        cookies.set('_webDid_collect', '1');
        return false;
    }
    return true;
};
