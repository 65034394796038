import cookies from 'js-cookie';
import KwaiLog from '@kibt/weblogger/lib/log.browser';
import RiskMgt from '@ks/weblogger/lib/plugins/riskMgt';
import queryString from 'query-string';
import { BasePlugin } from '@ks/weblogger';
import { isProduction, isTest } from './env';
import { getQuery } from './utils';

const kpn = cookies.get('kpn') || 'KWAI';

let VUE_APP_LOG_TYPE = '';

if (isProduction) {
    VUE_APP_LOG_TYPE = 'oversea';
}

if (isTest) {
    VUE_APP_LOG_TYPE = 'test';
}

// 日志类型
export enum LogType {
    show = 'SHOW',
    click = 'CLICK',
    pv = 'PV',
    // 雷达事件
    custom = 'CUSTOM',
    STAY_LENGTH_STAT_EVENT = 'STAY_LENGTH_STAT_EVENT', // 时长
    BACKGROUND_TASK_EVENT = 'BACKGROUND_TASK_EVENT', // 任务事件
}

export interface LogData {
    type: keyof typeof LogType;
    name: string;
    value?: Record<string, any>;
    eventId?: string;
    pvType?: 'enter' | 'leave' | 'hidden' | 'visible';
}

export interface RadarLogData {
    // 事件名（必填）
    name: string;
    // 事件类型（可选）
    event_type?: string;
    // 业务分类（可选）
    category?: string;
    // 事件相关源文件（可选）
    src?: string;
    // 事件结果（可选）
    result_type?: string;
    // 消息（可选）
    message?: string;
    // 额外信息（建议使用json string - 可选）
    extra_info?: string;
    // 时长，可以计算分位数，不涉及可以不传（可选）
    duration?: number;
    videosrc?: string;
    event_count?: number;
}

interface KwaiLogConfig {
    env: string;
    retries: number;
    autoPV: boolean;
    fps: boolean;
    logger: boolean;
    radar: {
        projectId: string;
        sampling: number;
        lcp: boolean;
        cls: boolean;
        fid: boolean;
        fcp: boolean;
    };
    plugins?: BasePlugin[];
}

// WebLogger内核SDK配置参数
const logConfig: KwaiLogConfig = {
    env: VUE_APP_LOG_TYPE || ('development' as any),
    retries: isProduction ? 3 : 0,
    autoPV: false,
    fps: false,
    logger: !isProduction,
    radar: {
        projectId: '4fedaf2ec1', // 雷达平台上的唯一标识
        sampling: 1, // 上报采样率，1 为全部上报
        lcp: true,
        cls: true,
        fid: true,
        fcp: true,
    },
};

// 公参配置
const baseOptions = {
    product_name: kpn,
    package_name: kpn,
};

function generateLogParams(data: LogData) {
    const info = {
        params: data.value,
        eventId: data.eventId,
    } as any;

    const urlPage = data?.value?.urlPage;

    if (data.type === 'pv') {
        return {
            type: data.pvType,
            page: data.name,
            ...info,
        };
    }

    if (data.type === 'custom') {
        return {
            key: data.name,
            value: data.value,
            eventId: data.eventId,
        };
    }

    if (data.type === 'click' && data?.value?.type === 'STAY_LENGTH_STAT_EVENT') {
        info.type = 'STAY_LENGTH_STAT_EVENT';
    }

    if (data.type === 'click' && data?.value?.type === 'BACKGROUND_TASK_EVENT') {
        info.type = 'BACKGROUND_TASK_EVENT';
    }

    return {
        action: data.name,
        // urlPage: urlPage,
        ...info,
    };
}

let kwailog = null as any;

export function getKwaiLog() {
    if (!kwailog) {
        const { pwa_source } = getQuery() || {};
        logConfig.plugins = [
            new RiskMgt({
                env: isProduction ? 'production' : 'test',
                bussType: 'kwai-mobile',
                taskType: '11',
                subTaskType: '11-1',
                host: 'kwai',
                switchAsync: true,
            }),
        ];
        kwailog = new KwaiLog(logConfig, baseOptions).weblog;
    }
    return kwailog;
}

/**
 * @zh 页面上报 fmp
 */
export const reportFMP = () => {
    try {
        const timeStamp = Date.now();
        const webLogger = getKwaiLog();
        webLogger?.plugins?.radar?.fmp?.(timeStamp);
    } catch {
        // eslint-disable-next-line no-console
        console.log('fmp report error.');
    }
};

/**
 * 数据上报
 * @param data 上报数据
 * @param immediate 是否立即上报
 */
const logCollect = (data: LogData, immediate?: boolean) => {
    const { type } = data;

    // 不存在类型则不上报
    if (!type) {
        return;
    }

    // 点击事件增加click_index字段，相同的share_id为一个生命周期
    if (type === 'click') {
        data.value = data.value || {};
        try {
            const deviceId = (cookies.get('_did') || '') as string;
            const shareId = `CLICK_INDEX_${getQuery()?.share_id}_${deviceId}`;
            const clickIndex = sessionStorage.getItem(shareId);
            const nextIndex = clickIndex ? `${+clickIndex + 1}` : '1';
            sessionStorage.setItem(shareId, nextIndex);
            data.value.click_index = nextIndex;
        } catch (error) {
            data.value.click_index = '-1';
        }
    }
    if (process.client && type === 'pv') {
        data.value = data.value || {};
        const parsed = queryString.parse(window.location.search);
        const bid_id = parsed.bid_id;
        data.value.bid_id = bid_id;
    }
    const logParams = generateLogParams(data);
    const logType = LogType[type];

    const webLogger = getKwaiLog();

    if (immediate) {
        webLogger.sendImmediately(logType, logParams as any);
    } else {
        webLogger.collect(logType, logParams as any);
    }
};

export const sendLog = (data: LogData) => {
    logCollect(data);
};

/**
 * 立即上报埋点
 * @param data
 */
export const immediateSendLog = (data: LogData) => {
    logCollect(data, true);
};

/**
 * 雷达上报：自定义事件
 */
export const radarLog = (data: RadarLogData) => {
    // message可能包含分享优化实验参数，有几种情况,other：不是低端机，lowDeviceOptimize：是低端机且命中优化实验，lowDeviceNotOptimize：是低端机未命中优化实验
    const { duration, message, event_count } = data;
    try {
        const webLogger = getKwaiLog();
        if (message?.includes('lowDeviceOptimize')) {
            webLogger?.plugins?.radar &&
                webLogger.plugins.radar.setDimensions({
                    c_dimension1: 'lowDeviceOptimize', // 值可以为任意string，且只支持string类型
                });
        } else if (message?.includes('lowDeviceNotOptimize')) {
            webLogger?.plugins?.radar &&
                webLogger.plugins.radar.setDimensions({
                    c_dimension1: 'lowDeviceNotOptimize', // 值可以为任意string，且只支持string类型
                });
        }

        webLogger?.plugins?.radar &&
            webLogger.plugins.radar.event(data, {
                duration,
                event_count,
            });
    } catch (error) {
        console.error(error);
    }
};

const getReferPage = (pageName: string) => {
    try {
        const referPage = sessionStorage.getItem('SHARE_REFER_PAGE');
        const resRefer = referPage?.split(',') || [];

        // 路由面包屑，(当前页面刷新不计入次数)
        if (resRefer[resRefer.length - 1] !== pageName) {
            const nextRefer = referPage ? `${referPage},${pageName}` : pageName;
            sessionStorage.setItem('SHARE_REFER_PAGE', nextRefer);
            resRefer.push(pageName);
        }
        return resRefer;
    } catch (error) {
        return [pageName];
    }
};

const getReferSource = (query: any) => {
    try {
        const storageKey = `REFER_SOURCE_${query?.share_id}`;
        const referSource = sessionStorage.getItem(storageKey);
        if (referSource) {
            return referSource;
        }

        const referer = document.referrer;
        if (referer) {
            sessionStorage.setItem(storageKey, referer);
        }
        return referer || '';
    } catch (error) {
        return '';
    }
};
