import { AIUserModel } from '../models/AIModel';
import { AIUserStore } from '../stores/AIUserStore';
import generateUUID from '../utils/aiTools';
import { UIAIStore } from '../stores/UIAIStore';
import AIUserModelImpl from '../models/UIAIUserModel';

export class AIUserService {
    private userStore: AIUserStore;

    constructor(userStore: AIUserStore) {
        this.userStore = userStore;
    }

    async addUser<H>(name: string, like: boolean, attachment?: H): Promise<AIUserModel> {
        const userId = this._createC2CUserId(name);
        const user: AIUserModel = new AIUserModelImpl(userId, name, '', new Date(), like, attachment);
        console.log('social-ai-im', 'add user', user);

        await this.userStore.addUser(user);
        return user;
    }

    getUsers(): AIUserModel[] {
        return this.userStore.getUsers();
    }

    getUserById(userId: string): AIUserModel | undefined {
        return this.userStore.getUserById(userId);
    }

    async deleteUser(userId: string): Promise<boolean> {
        await this.userStore.deleteUser(userId);
        console.log('social-ai-im', `Deleted user with ID: ${userId}`);
        return true;
    }

    async updateUser(userId: string, name: string, like: boolean): Promise<AIUserModel | undefined> {
        const user = this.userStore.getUserById(userId);
        if (user) {
            user.name = name;
            user.like = like;
            await this.userStore.updateUser(user);
            console.log('social-ai-im', `Updated user with ID: ${userId}`, like);
            return user;
        }
        console.log('social-ai-im', `User with ID ${userId} not found`);
        return undefined;
    }

    getUserIdByUserName(name: string) {
        return this._createC2CUserId(name);
    }

    private _createC2CUserId(name?: string) {
        return `User${name || generateUUID()}`;
    }
}

export const UIAIUserService = new AIUserService(UIAIStore.userStore);
