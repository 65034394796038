import cookies from 'js-cookie';
import { nodeIsAppEnvironment, isTiny, getCkid, getDeviceIDByWebDid, changeDidWithDeviceID } from './ids-utils';
import { immediateSendLog, sendLog } from './radar';

const reportChangeDidLog = (ids_mapping: any) => {
    immediateSendLog({
        type: 'click',
        name: 'CHANGE_DID_WITH_DEVICE',
        value: ids_mapping,
    });
};

export const buildIDsSystem = async (app: any, retry: boolean) => {
    if (process.server || nodeIsAppEnvironment() || isTiny()) {
        return;
    }
    const idsMappingStr = cookies.get('ids-mapping');
    let parsedIdsMapping;
    if (idsMappingStr && typeof idsMappingStr === 'string') {
        try {
            parsedIdsMapping = JSON.parse(idsMappingStr);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('Parse app devideID error: ', error);
        }
        if (parsedIdsMapping) {
            console.log('ids-mapping', 'has ids-mapping return');
            return;
        }
    }

    const webDid = cookies?.get('webDid');
    let ckid;
    if (!parsedIdsMapping || !parsedIdsMapping.deviceId) {
        if (webDid) {
            parsedIdsMapping = await getDeviceIDByWebDid(app, webDid);
        }
        if (!parsedIdsMapping || !parsedIdsMapping.deviceId) {
            ckid = await getCkid(app);
            // ckid = '9e0d900c-ccbf-4e67-8c29-54ae1b6adfc0';
            parsedIdsMapping = await getDeviceIDByWebDid(app, webDid, ckid);
        }
        if (!ckid && retry) {
            setTimeout(() => {
                buildIDsSystem(app, false);
            }, 1000);
            return;
        }
    }

    // 匹配ckid后做上报
    if (ckid) {
        sendLog({
            type: 'click',
            name: 'WEBDID_CKID_RELATION',
            value: {
                ckid,
                web_did: webDid,
            },
        });
    }

    const { deviceId } = parsedIdsMapping || {};
    const did_change_time = Number(app?.$store.state.startup.did_change_time);
    if (deviceId && did_change_time > 0) {
        changeDidWithDeviceID(deviceId);
        const did = cookies.get('did');
        const time = new Date().getTime();
        const ids_mapping = JSON.stringify({ deviceId, webDid, ckid, did, time });
        cookies.set('ids-mapping', ids_mapping, { expires: did_change_time });
        reportChangeDidLog({ deviceId, webDid, ckid, did, time });
    }
};
