import cookies from 'js-cookie';
import queryString from 'query-string';
import {
    nodeIsAppEnvironment,
    isTiny,
    getNotExecuteRedirectQuey,
    getPwaSource,
    isFilmHost,
    getCkid,
    getDeviceIDByWebDid,
    checkWebDidCollect,
} from './ids-utils';

export const filmRedirect = async (app, retry) => {
    if (process.server || nodeIsAppEnvironment() || isTiny()) {
        return;
    }
    const reg = new RegExp(window.location.origin, 'g');
    const url = window.location.href?.replace(reg, '');
    const ignoreHost = getNotExecuteRedirectQuey('ignore_host');
    const canRedirect = isFilmHost() || ignoreHost === 'true';
    if (!canRedirect) {
        return;
    }

    const webDid = cookies?.get('webDid');
    const collect = checkWebDidCollect();
    let parsedAppDeviceId;
    if (webDid && !collect) {
        // 未种下webDid,client端执行webDid to deviceId
        parsedAppDeviceId = await getDeviceIDByWebDid(app, webDid);
        // console.log('redirect_web', 'client', 'getFromWebdid', `app-device-id:${JSON.stringify(parsedAppDeviceId)}`);
    }

    let ckid;
    if (!parsedAppDeviceId || !parsedAppDeviceId.deviceId) {
        ckid = await getCkid(app); // ckid = '9e0d900c-ccbf-4e67-8c29-54ae1b6adfc0';
        parsedAppDeviceId = await getDeviceIDByWebDid(app, webDid, ckid);
        // console.log('redirect_web', 'client', 'getFromCkid', `ckid:${ckid}`, `app-device-id:${JSON.stringify(parsedAppDeviceId)}`);
    }
    if (!ckid && retry) {
        setTimeout(() => {
            filmRedirect(app, false);
        }, 1000);
        // console.log('redirect_web', 'client', 'ckid is null, then retry');
        return;
    }
    const { deviceId, redirectAB } = parsedAppDeviceId || {};
    let containSource = true;
    if (deviceId && redirectAB) {
        cookies.set('app_device_id', JSON.stringify({ deviceId, redirectAB, webDid, ckid }));
        const pwaSource = getPwaSource();
        const webRedircctArr = redirectAB.split(',');
        containSource = webRedircctArr.includes(pwaSource); // 不包含跳转
    } else if (deviceId === undefined || deviceId === '' || redirectAB === undefined || redirectAB === '') {
        // 无deviceID 跳转
        containSource = false;
        // console.log('redirect_web', 'client', 'deviceID or abtest is null', deviceId, redirectAB);
    }

    // console.log(
    //     'redirect_web',
    //     'client',
    //     'can-redirect',
    //     `redirect:${!containSource}`,
    //     `app-device-id:${JSON.stringify({ deviceId, redirectAB, webDid, ckid })}`
    // );
    if (!containSource) {
        const redirectUrl = new URL(`https://www.kwai.com${url}`);
        if (webDid) {
            redirectUrl?.searchParams?.set('webDid', webDid);
        }
        if (ckid) {
            redirectUrl?.searchParams?.set('ckid', ckid);
        }
        if (deviceId) {
            redirectUrl?.searchParams?.set('deviceId', deviceId);
        }
        if (redirectUrl?.searchParams.has('ignore_host')) {
            redirectUrl?.searchParams?.delete('ignore_host');
        }
        const redirectUrlStr = redirectUrl?.toString();
        // eslint-disable-next-line no-undef
        const notRedirectV = getNotExecuteRedirectQuey('not_redirect');
        if (notRedirectV !== 'true') {
            // console.log('redirect_web', 'client', 'redirect-path: ', redirectUrlStr);
            window.location.href = redirectUrlStr;
        }
        // console.log('redirect_web', 'client', 'redirect-path: ', redirectUrlStr);
    }
};
