import { EventEmitter } from 'events';
import { AIMessageModel, AIConversationID } from '../models/AIModel';
import { AIStoreType, AIActionType } from './AIStoreType';
import getDB from '../utils/index';

const EXPIRATION_TIME = 7 * 24 * 60 * 60 * 1000;

export class AIChatStore extends EventEmitter {
    private messages: Record<AIConversationID, AIMessageModel[]> = {};

    async initialize() {
        await this.loadMessagesFromDB();
        setInterval(() => {
            this._removeExpiredMessages();
        }, 60 * 1000);
    }

    async loadMessagesFromDB(): Promise<boolean> {
        const db = await getDB();
        return new Promise((resolve, reject) => {
            const tx = db.transaction('messages', 'readonly');
            const store = tx.objectStore('messages');
            const request = store.getAll();
            request.onsuccess = () => {
                const messages = request.result as AIMessageModel[];
                messages.forEach(message => {
                    if (this.messages[message.conversationId]) {
                        this.messages[message.conversationId].push(message);
                    } else {
                        this.messages[message.conversationId] = [message];
                    }
                });
                resolve(true);
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }

    public async addMessage(message: AIMessageModel) {
        this._addMessageToMemory(message);
        await this._addMessageToDB(message);
    }

    public async updateMessageInDB(message: AIMessageModel): Promise<boolean> {
        const db = await getDB();
        return new Promise((resolve, reject) => {
            const tx = db.transaction('messages', 'readwrite');
            const store = tx.objectStore('messages');
            const request = store.put(message);
            request.onsuccess = () => {
                resolve(true);
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }

    public async deleteMessage(message: AIMessageModel) {
        this._deleteMessageFromMemory(message);
        await this._deleteMessageFromDB(message.id);
    }

    async clearMessagesByConversationId(conversationId: string) {
        const messages = this.messages[conversationId];
        if (messages) {
            messages.forEach(async message => {
                this._deleteMessageFromMemory(message);
                await this._deleteMessageFromDB(message.id);
            });
        }
    }

    private async _removeExpiredMessages() {
        const now = new Date().getTime();
        const expiredMessages: AIMessageModel[] = [];
        this.messages?.value?.forEach(message => {
            if (now - message.timestamp.getTime() > EXPIRATION_TIME) {
                expiredMessages.push(message);
                this._deleteMessageFromMemory(message);
            }
        });
        expiredMessages.forEach(async message => {
            await this._deleteMessageFromDB(message.id);
        });
    }

    private _addMessageToMemory(message: AIMessageModel) {
        if (this.messages[message.conversationId]) {
            this.messages[message.conversationId].push(message);
        } else {
            this.messages[message.conversationId] = [message];
        }
        this.emit('change', { type: AIStoreType.Chat, action: AIActionType.Add, message });
    }

    private async _addMessageToDB(message: AIMessageModel): Promise<boolean> {
        try {
            const db = await getDB();
            return new Promise((resolve, reject) => {
                const tx = db.transaction('messages', 'readwrite');
                const store = tx.objectStore('messages');
                const request = store.add(message);
                request.onsuccess = () => {
                    resolve(true);
                };
                request.onerror = () => {
                    reject(request.error);
                };
            });
        } catch (error) {
            console.log('add message error', error);
            return false;
        }
    }

    private _deleteMessageFromMemory(message: AIMessageModel) {
        if (this.messages[message.conversationId]) {
            this.messages[message.conversationId] = this.messages[message.conversationId].filter(m => m.id !== message.id);
            this.emit('change', { type: AIStoreType.Chat, action: AIActionType.Delete, message });
        }
    }

    private async _deleteMessageFromDB(messageId: string): Promise<boolean> {
        try {
            const db = await getDB();
            return new Promise((resolve, reject) => {
                const tx = db.transaction('messages', 'readwrite');
                const store = tx.objectStore('messages');
                const request = store.delete(messageId);
                request.onsuccess = () => {
                    resolve(true);
                };

                request.onerror = () => {
                    reject(request.error);
                };
            });
        } catch (error) {
            console.log('delete message error', error);
            return false;
        }
    }

    getMessageById(conversationId: string) {
        return this.messages[conversationId] || [];
    }

    watch(storeType: AIStoreType, callback: (event: { type: AIStoreType; action: AIActionType; message: AIMessageModel }) => void) {
        if (storeType === AIStoreType.Chat) {
            this.on('change', callback);
        }
    }

    unwatch(storeType: AIStoreType, callback: (event: { type: AIStoreType; action: AIActionType; message: AIMessageModel }) => void) {
        if (storeType === AIStoreType.Chat) {
            this.removeListener('change', callback);
        }
    }
}

export default AIChatStore;
