/* eslint-disable import/no-extraneous-dependencies */
import { NuxtAxiosInstance } from 'vue/types/vue';
import { AxiosRequestConfig } from 'axios';
import { ResponseBase } from './base';

import axiosInstance from './request';

interface GetDidByWebDidRequest {
    webDid?: string;
}

interface GetDidByWebDidResponse extends ResponseBase {
    /**
     * @zh 历史逻辑，先 Record<string, any>
     */
    [key: string]: any;
}

interface GetDidByCookieIdRequest {
    cookieId?: string;
}

interface GetDidByCookieIdResponse extends ResponseBase {
    /**
     * @zh 历史逻辑，先 Record<string, any>
     */
    [key: string]: any;
}

export class SystemApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        this.$axios = axiosInstance;
    }

    async getDidByWebDid(data: GetDidByWebDidRequest, config?: AxiosRequestConfig) {
        const path = `${process.server ? 'overseaAPI::' : ''}/rest/o/w/pwa/v2/getDidByWebDid`;
        const res = await this.$axios?.$$get?.<GetDidByWebDidResponse>(path, {
            ...config,
            params: data,
        });
        return res;
    }

    async getDidByCookieId(data: GetDidByCookieIdRequest, config?: AxiosRequestConfig) {
        const path = `${process.server ? 'overseaAPI::' : ''}/rest/o/w/pwa/v2/getDidByCookieId`;
        const res = await this.$axios?.$$get?.<GetDidByCookieIdResponse>(path, {
            ...config,
            params: data,
        });
        return res;
    }
}

export const SystemApi = new SystemApiService();
