// eslint-disable-next-line import/no-extraneous-dependencies
import { Module } from 'vuex';
import queryString from 'query-string';
import { Feed } from '@/api/feed';
import { getPwaSource, nodeIsAppEnvironment, isTiny } from '@/common/ids-utils';
import { checkSeoReferrer } from '@/common/utils';
import device from '@/common/device-info';
import { checkSnack } from '@/common/seo';

export enum FeedPageEnum {
    Foryou = 'foryou',
    VideoDetail = 'video-detail',
    VideoDetailShare = 'video-detail-share',
    /**
     * @zh 直接访问 /pwa-share-new, 线上测试 path
     */
    PwaShareNew = 'pwa-share-new',
    DiscoverSearch = 'discover-search',
}

export type FeedListOriginType = Partial<Record<FeedPageEnum, Array<Feed>>>;

interface FeedMoudleType {
    feedListOrigin?: FeedListOriginType;
    isMuted?: boolean;
    lastGoogleAD?: number;
    hasServerFetchedData?: boolean;
    currentFeed?: Feed;
}

const defaultState: () => FeedMoudleType = () => ({
    feedListOrigin: {
        [FeedPageEnum.Foryou]: [],
        [FeedPageEnum.VideoDetail]: [],
        [FeedPageEnum.PwaShareNew]: [],
        [FeedPageEnum.DiscoverSearch]: [],
    },
    isMuted: true,
    lastGoogleAD: 0,
    hasServerFetchedData: false, // 标识是否在 node 端请求过数据，如果请求过数据，则不在 feedList 组件的 onMounted 中重新请求数据
});

export const feedMoudle: Module<FeedMoudleType, any> = {
    namespaced: true,
    state: defaultState,
    mutations: {
        setFeedList(state, newFeedOrigin: FeedListOriginType) {
            try {
                const newFeedListOrigin = Object.keys(newFeedOrigin || {}).reduce<FeedListOriginType>((res, currentKey) => {
                    return {
                        ...res,
                        [currentKey]: [
                            ...(state?.feedListOrigin?.[currentKey as FeedPageEnum] || []),
                            ...(newFeedOrigin[currentKey as FeedPageEnum] || []),
                        ],
                    };
                }, {});
                state.feedListOrigin = {
                    ...state.feedListOrigin,
                    ...newFeedListOrigin,
                };
            } catch {
                // do nothing
            }
        },

        /**
         * @zh 包含给 AI 引流的上下滑视频
         */
        setFeedListNew(state, newFeedOrigin: FeedListOriginType) {
            state.feedListOrigin = newFeedOrigin;
        },

        insertGoogleADSenseFeed(state, params: { newFeedOrigin: FeedListOriginType; startup: any }) {
            const ads_open = params?.startup?.ads_open?.toLowerCase();
            if (ads_open !== 'true') {
                return;
            }
            let newFeedListOrigin: FeedListOriginType = {};
            Object.keys(params.newFeedOrigin || {}).forEach(key => {
                const pwa_share_origin = state.feedListOrigin?.[key as FeedPageEnum];
                if (!pwa_share_origin) return;
                let ads_load = 1;
                if (params?.startup?.ads_load) {
                    ads_load = Number(params.startup.ads_load); // number
                }
                let ads_start_pos = 1;
                if (params?.startup?.ads_start_pos) {
                    ads_start_pos = Number(params.startup.ads_start_pos); // number
                }
                const pwa_share_origin_inserted = pwa_share_origin
                    .map((feed: Feed, index: number) => {
                        if (index >= ads_start_pos - 1 && ads_load > 0 && (index - (ads_start_pos - 1)) % (ads_load + 1) === 0) {
                            if (feed.google_ad === undefined) {
                                feed.google_ad = true;
                            }
                        } else {
                            feed.google_ad = false;
                        }
                        return [feed];
                    })
                    .flat();
                newFeedListOrigin = {
                    ...newFeedListOrigin,
                    [key]: pwa_share_origin_inserted,
                };
            });

            state.feedListOrigin = {
                ...state.feedListOrigin,
                ...newFeedListOrigin,
            };
        },

        //

        insertLuminaADSenseFeed(state, params: { newFeedOrigin: FeedListOriginType; startup: any }) {
            /**
             * @zh 兜底页面排查广告
             */
            if (process.env.IS_BACKUP_ENV) {
                return;
            }
            if (nodeIsAppEnvironment() || isTiny()) {
                return;
            }
            let ads_time_control_str = params?.startup?.ads_time_control;
            console.log('lumina-ad', 'abtest', params?.startup, ads_time_control_str);
            const parsed = queryString.parse(window.location.search);
            if (parsed.skipAdAB === 'true') {
                ads_time_control_str =
                    '{"share":{"lumina_ads_load":"2","lumina_ads_start_pos":"2"},"seo":{"lumina_ads_load":"2","lumina_ads_start_pos":"3"}}';
            }
            if (!ads_time_control_str) return;
            let ads_time_control;
            try {
                ads_time_control = JSON.parse(ads_time_control_str);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.warn('Parse app ads_time_control error: ', error);
            }
            console.log('lumina-ad', 'abtest', params?.startup, ads_time_control);
            const pwa_source = getPwaSource();
            let config;
            if (checkSeoReferrer()) {
                // seo场景pwa_source无
                config = ads_time_control?.seo;
            } else if (!pwa_source) {
                // 无pwa_source 使用下发的no_pwa频控
                config = ads_time_control?.no_pwa;
            } else {
                config = ads_time_control[pwa_source];
            }
            console.log('lumina-ad', 'config', config);
            if (!config) return;
            const lumina_ads_start_pos = Number(config.lumina_ads_start_pos);
            const lumina_ads_load = Number(config.lumina_ads_load);
            let newFeedListOrigin: FeedListOriginType = {};
            Object.keys(params.newFeedOrigin || {}).forEach(key => {
                const pwa_share_origin = state.feedListOrigin?.[key as FeedPageEnum];
                if (!pwa_share_origin) return;

                const pwa_share_origin_inserted = pwa_share_origin
                    .map((feed: Feed, index: number) => {
                        if (
                            index >= lumina_ads_start_pos - 1 &&
                            lumina_ads_load > 0 &&
                            (index - (lumina_ads_start_pos - 1)) % (lumina_ads_load + 1) === 0
                        ) {
                            if (feed.lumina_ad === undefined) {
                                feed.lumina_ad = true;
                                console.log('lumina-ad', 'insert ad', index + 1);
                            }
                        } else {
                            feed.lumina_ad = false;
                        }
                        return [feed];
                    })
                    .flat();
                newFeedListOrigin = {
                    ...newFeedListOrigin,
                    [key]: pwa_share_origin_inserted,
                };
            });

            console.log('lumina-ad', 'feedlist', newFeedListOrigin);
            state.feedListOrigin = {
                ...state.feedListOrigin,
                ...newFeedListOrigin,
            };
        },

        insertAIChatCardToFeed(state, params: { newFeedOrigin: FeedListOriginType; startup: any }) {
            if (process.env.IS_BACKUP_ENV) {
                return;
            }
            const { isSnack } = checkSnack();
            if (nodeIsAppEnvironment() || isTiny() || isSnack) {
                return;
            }

            let ai_chat_card_test = params?.startup?.ai_chat_card_test;
            console.log('ai-chat-card', 'abtest', params?.startup, ai_chat_card_test);
            const parsed = queryString.parse(window.location.search);
            if (parsed.skipAIChatAB === 'true') {
                ai_chat_card_test = '{"ai_chat_card_load":"3","ai_chat_card_start_pos":"2"}';
            }
            if (!ai_chat_card_test) return;
            let ai_chat_card_test_config;
            try {
                ai_chat_card_test_config = JSON.parse(ai_chat_card_test);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.warn('Parse app ads_time_control error: ', error);
            }
            if (!ai_chat_card_test_config) return;
            const ai_chat_card_start_pos = Number(ai_chat_card_test_config.ai_chat_card_start_pos);
            const ai_chat_card_load = Number(ai_chat_card_test_config.ai_chat_card_load);

            const start_pos = ai_chat_card_start_pos > 0 ? Number(ai_chat_card_start_pos) : 1;
            const ads_load = ai_chat_card_load > 0 ? Number(ai_chat_card_load) : 3;

            let newFeedListOrigin: FeedListOriginType = {};
            Object.keys(params.newFeedOrigin || {}).forEach(key => {
                const pwa_share_origin = state.feedListOrigin?.[key as FeedPageEnum];
                if (!pwa_share_origin) return;

                const pwa_share_origin_inserted = pwa_share_origin
                    .map((feed: Feed, index: number) => {
                        if (index >= start_pos - 1 && ads_load > 0 && (index - (start_pos - 1)) % (ads_load + 1) === 0) {
                            if (feed.ai_chat_card === undefined) {
                                feed.ai_chat_card = true;
                                console.log('ai-chat-card', 'insert card', index + 1);
                            }
                        } else {
                            feed.ai_chat_card = false;
                        }
                        return [feed];
                    })
                    .flat();
                newFeedListOrigin = {
                    ...newFeedListOrigin,
                    [key]: pwa_share_origin_inserted,
                };
            });

            console.log('ai-chat-card', 'feedlist', newFeedListOrigin);
            state.feedListOrigin = {
                ...state.feedListOrigin,
                ...newFeedListOrigin,
            };
        },

        resetFeedList(state, feedPage?: FeedPageEnum) {
            if (!feedPage) {
                const _state = defaultState();
                state.feedListOrigin = _state.feedListOrigin;
                return;
            }
            state.feedListOrigin = {
                ...state.feedListOrigin,
                [feedPage]: [],
            };
        },

        setMuted(state, isMuted?: boolean) {
            state.isMuted = isMuted;
        },

        setHasServerFetchedData(state, isFetched?: boolean) {
            state.hasServerFetchedData = !!isFetched;
        },

        setCurrentFeed(state, currentFeed?: Feed) {
            state.currentFeed = currentFeed;
        },
    },
    actions: {
        dispatchSetFeedList({ rootState, commit, state }, newFeedOrigin: FeedListOriginType) {
            try {
                const { ai_guide_config } = rootState?.abTestModule?.abTestParams || {};
                const { first, gap, video_list = [], bucket = [] } = ai_guide_config || {};
                const { isIOS } = device();
                const isAiGuideAbTest =
                    !isIOS &&
                    typeof first !== 'undefined' &&
                    typeof gap !== 'undefined' &&
                    bucket.some((item: string) => item === rootState?.startup?.bucket);

                const newFeedListOrigin = Object.keys(newFeedOrigin || {}).reduce<FeedListOriginType>((res, currentKey) => {
                    const newFeedList = newFeedOrigin[currentKey as FeedPageEnum] || [];
                    const feedListLength = (state?.feedListOrigin?.[currentKey as FeedPageEnum] || []).length;
                    const newFeedListWithAiGuide = !isAiGuideAbTest
                        ? newFeedList
                        : newFeedList.map((feed, index) => {
                              /**
                               * @zh 出 AI 导流逻辑
                               * @zh first + gap * x = feedCurrentIndex
                               * @zh 即 (feedCurrentIndex - first) / gap 为整数时，且 feedCurrentIndex >= first
                               */
                              const feedCurrentIndex = feedListLength + index;
                              const isAiGuide = feedCurrentIndex >= first && Number.isInteger((feedCurrentIndex - first) / gap);

                              if (isAiGuide) {
                                  /**
                                   * @zh 随机选择下发的 cdn 作为播放源
                                   */
                                  const videoCdn = video_list[feedCurrentIndex % (video_list.length || 1)];
                                  return {
                                      ...feed,
                                      ai_ad_guide: true,
                                      /**
                                       * @zh 设置为 undefined，H265 播放器用的
                                       */
                                      transcode_manifest_info: undefined,
                                      cover_first_frame_urls: undefined,
                                      /**
                                       * @zh 从配置里取值并回填
                                       */
                                      main_mv_urls: videoCdn
                                          ? [
                                                {
                                                    url: videoCdn,
                                                    cdn: '',
                                                },
                                            ]
                                          : feed.main_mv_urls,
                                  };
                              }

                              return feed;
                          });

                    return {
                        ...res,
                        [currentKey]: [...(state?.feedListOrigin?.[currentKey as FeedPageEnum] || []), ...newFeedListWithAiGuide],
                    };
                }, {});

                commit('setFeedListNew', {
                    ...state.feedListOrigin,
                    ...newFeedListOrigin,
                });
            } catch {
                // do nothing
            }
        },
    },
};
