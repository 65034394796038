import { AIUserModel, AIUserID } from './AIModel';
import { UIAIStore } from '../stores/UIAIStore';

export class AIUserModelImpl<H = any> implements AIUserModel<H> {
    id: AIUserID;

    name: string;

    gender: string;

    createdAt: Date;

    like: boolean;

    attachment?: H;

    constructor(id: AIUserID, name: string, gender: string, createdAt: Date, like: boolean, attachment: H) {
        this.id = id;
        this.name = name;
        this.createdAt = createdAt;
        this.gender = gender;
        this.like = like;
        this.attachment = attachment;
    }

    getAttachment(): H {
        return this.attachment as H;
    }

    getDetails(): { id: AIUserID; name: string; createdAt: Date } {
        return {
            id: this.id,
            name: this.name,
            createdAt: this.createdAt,
        };
    }

    delete(): void {
        UIAIStore.userStore.deleteUser(this.id);
    }

    update(name: string): void {
        this.name = name;
        UIAIStore.userStore.updateUser(this);
    }
}

export default AIUserModelImpl;
