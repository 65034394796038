// eslint-disable-next-line import/no-extraneous-dependencies
import { Module } from 'vuex';
import { AIFeed, AIFeedLiked } from '@/api/ai-feed';

// 本地兜底
export enum AIDiscoverTabNameDefault {
    OC = 'OC',
    Anime = 'Anime',
    Realistic = 'Realistic',
}

export type AIDiscoverTabTag = string;
export type AIDiscoverListOriginType = Partial<Record<AIDiscoverTabTag, Array<AIFeed>>>;

export interface AIDiscoverTab {
    tag: AIDiscoverTabTag;
    key: string;
}
interface AIDiscoverMoudleType {
    feedListOrigin?: AIDiscoverListOriginType;
    hasServerFetchedData?: boolean;
    currentTab?: AIDiscoverTab;
    discoverTabs?: Array<AIDiscoverTab>;
}

const defaultState: () => AIDiscoverMoudleType = () => ({
    feedListOrigin: {
        [AIDiscoverTabNameDefault.OC]: [],
        [AIDiscoverTabNameDefault.Anime]: [],
        [AIDiscoverTabNameDefault.Realistic]: [],
    },
    hasServerFetchedData: false,
    currentTab: { tag: '', key: '' },
});

export const aiDiscoverMoudle: Module<AIDiscoverMoudleType, any> = {
    namespaced: true,
    state: defaultState,
    mutations: {
        setFeedList(state, newFeedOrigin: AIDiscoverListOriginType) {
            const newFeedListOrigin = Object.keys(newFeedOrigin || {}).reduce<AIDiscoverListOriginType>((res, currentKey) => {
                const existingFeeds = state?.feedListOrigin?.[currentKey as AIDiscoverTabTag] || [];
                const newFeeds = (newFeedOrigin[currentKey as AIDiscoverTabTag] || []).map(feed => ({
                    ...feed,
                    liked: AIFeedLiked.UnLike, // 添加 like 属性，默认值为 false
                    index: 0,
                }));
                return {
                    ...res,
                    [currentKey]: [...existingFeeds, ...newFeeds],
                };
            }, {});

            state.feedListOrigin = {
                ...state.feedListOrigin,
                ...newFeedListOrigin,
            };
        },

        resetFeedList(state, feedPage?: AIDiscoverTabTag) {
            if (!feedPage) {
                const _state = defaultState();
                state.feedListOrigin = _state.feedListOrigin;
                return;
            }
            state.feedListOrigin = {
                ...state.feedListOrigin,
                [feedPage]: [],
            };
        },

        setHasServerFetchedData(state, isFetched?: boolean) {
            state.hasServerFetchedData = !!isFetched;
        },

        setCurrentTab(state, currentTab?: AIDiscoverTab) {
            state.currentTab = currentTab;
        },

        setDiscoverTabs(state, discoverTabs?: Array<AIDiscoverTab>) {
            state.discoverTabs = discoverTabs;
        },
    },
    actions: {},
};
