import { EventEmitter } from 'events';
import { AIConversationModel, AIMessageID } from '../models/AIModel';
import { AIStoreType, AIActionType } from './AIStoreType';
import getDB from '../utils/index';
// import { UIAIChatService } from '../services/UIAIChatService';

export class AIConversationStore extends EventEmitter {
    private conversations: AIConversationModel[] = [];

    async initialize() {
        await this.loadConversationsFromDB();
    }

    async loadConversationsFromDB() {
        const db = await getDB();
        return new Promise((resolve, reject) => {
            const tx = db.transaction('conversations', 'readonly');
            const store = tx.objectStore('conversations');
            const request = store.getAll();
            request.onsuccess = () => {
                this.conversations = request.result;
                resolve(request.result);
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }

    async addConversation(conversation: AIConversationModel) {
        this._addConversationToMemory(conversation);
        await this._addConversationToDB(conversation);
    }

    async updateConversation(conversation: AIConversationModel) {
        this._updateconversationInMemory(conversation);
        await this._updateConversationInDB(conversation);
    }

    private _updateconversationInMemory(conversation: AIConversationModel) {
        const index = this.conversations.findIndex(c => c.id === conversation.id);
        if (index !== -1) {
            this.conversations[index] = conversation;
        }
    }

    private async _updateConversationInDB(conversation: AIConversationModel) {
        try {
            const db = await getDB();
            return new Promise((resolve, reject) => {
                const tx = db.transaction('conversations', 'readwrite');
                const store = tx.objectStore('conversations');
                const request = store.put(conversation);
                request.onsuccess = () => {
                    resolve(request.result);
                };
                request.onerror = () => {
                    reject(request.error);
                };
            });
        } catch (error) {
            console.log('update conversation error', error);
        }
    }

    public async deleteConversation(conversationId: string) {
        this._deleteConversationFromMemory(conversationId);
        await this._deleteConversationFromDB(conversationId);
    }

    private _addConversationToMemory(conversation: AIConversationModel) {
        console.log('social-ai-im', 'add conversation to memory');
        this.conversations.push(conversation);
        this.emit('change', { type: AIStoreType.Conversation, action: AIActionType.Add, conversation });
    }

    private async _addConversationToDB(conversation: AIConversationModel): Promise<boolean> {
        try {
            const db = await getDB();
            return new Promise((resolve, reject) => {
                const tx = db.transaction('conversations', 'readwrite');
                const store = tx.objectStore('conversations');
                const request = store.add(conversation);
                request.onsuccess = () => {
                    console.log('social-ai-im', 'add conversation to db success');
                    resolve(true);
                };
                request.onerror = () => {
                    console.log('social-ai-im', 'add conversation to db with error', request.error);
                    reject(request.error);
                };
            });
        } catch (error) {
            console.log('add conversation error', error);
            return false;
        }
    }

    private async _deleteConversationFromMemory(conversationId: string) {
        this.conversations = this.conversations.filter(c => c.id !== conversationId);
        this.emit('change', { type: AIStoreType.Conversation, action: AIActionType.Delete, conversation: this.getConversationById(conversationId) });
    }

    private async _deleteConversationFromDB(conversationId: string) {
        try {
            const db = await getDB();
            return new Promise((resolve, reject) => {
                const tx = db.transaction('conversations', 'readwrite');
                const store = tx.objectStore('conversations');
                const request = store.delete(conversationId);
                request.onsuccess = () => {
                    resolve(request.result);
                };
                request.onerror = () => {
                    reject(request.error);
                };
            });
        } catch (error) {
            console.log('delete conversation error', error);
        }
    }

    getConversations() {
        return this.conversations;
    }

    getConversationById(conversationId: string): AIConversationModel | undefined {
        return this.conversations.find(c => c.id === conversationId);
    }

    watch(storeType: AIStoreType, callback: (event: { type: AIStoreType; action: AIActionType; conversation: AIConversationModel }) => void) {
        if (storeType === AIStoreType.Conversation) {
            this.on('change', callback);
        }
    }

    unwatch(storeType: AIStoreType, callback: (event: { type: AIStoreType; action: AIActionType; conversation: AIConversationModel }) => void) {
        if (storeType === AIStoreType.Conversation) {
            this.removeListener('change', callback);
        }
    }
}

export default AIConversationStore;
