import { AIFeed } from '~/api/ai-feed';
import { AIFeedPageEnum } from '~/store/modules/ai-feed';
import PWALikeIcon from '@/pages/aichat/images/pwa_like.png';
import PWALikedIcon from '@/pages/aichat/images/liked.png';
import PWAShareIcon from '@/pages/aichat/images/pwa_share.png';
import AiChatModeIcon from '@/pages/aichat/images/ai_chat_mode.png';
import AiHistoryModeIcon from '@/pages/aichat/images/ai_history_mode.png';
import AiBotReportIcon from '@/pages/aichat/images/ai-bot-report-icon.png';
import AiExtraActionIcon from '@/pages/aichat/images/extra-action-icon.png';

export type AIActionBarProps = {
    aiFeed: AIFeed;
    currentIndex?: number;
    feedPage?: AIFeedPageEnum;
};

export enum AIActionType {
    Like = 1,
    UnLike = 2,
    Share = 3,
    ChatMode = 4,
    HistroyMode = 5,
    Report = 6,
    ExtraActionList = 99,
}

export const AIActionLogNameMap: Record<AIActionType, string | undefined> = {
    [AIActionType.Like]: 'PWA_LIKE_BOT',
    [AIActionType.UnLike]: undefined, // 这个不用报，只需要点赞的
    [AIActionType.Share]: 'PWA_SHARE_BOT',
    [AIActionType.ChatMode]: 'PWA_CHAT_MODE_BOT',
    [AIActionType.HistroyMode]: 'PWA_CHAT_HISTORY_BOT',
    [AIActionType.Report]: undefined, // 这个不用报
    [AIActionType.ExtraActionList]: undefined, // 这个不用报
};

export interface AIActionItem {
    type?: AIActionType;
    icon?: string;
    count?: string | number;
    hidden?: boolean;
    onClick?: () => Promise<void> | void;
    _tag?: string;
}

/**
 * @zh 避免 IOS 出现资源加载失败问题
 */
// export const AIIconMap: Record<AIActionType, string> = {
//     [AIActionType.Like]: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kwai-share/assets/pwa_like.png',
//     [AIActionType.UnLike]: 'https://aws-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/liked.png',
//     [AIActionType.Share]: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kwai-share/assets/pwa_share.png',
//     [AIActionType.ChatMode]: 'https://cdn-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/ai_chat_mode.png',
//     [AIActionType.HistroyMode]: 'https://cdn-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/ai_history_mode.png',
//     [AIActionType.Report]: 'https://cdn-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/aichat/ai-bot-report-icon.png',
//     [AIActionType.ExtraActionList]: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/extra-action-icon.png',
// };
export const AIIconMap: Record<AIActionType, string> = {
    [AIActionType.Like]: PWALikeIcon,
    [AIActionType.UnLike]: PWALikedIcon,
    [AIActionType.Share]: PWAShareIcon,
    [AIActionType.ChatMode]: AiChatModeIcon,
    [AIActionType.HistroyMode]: AiHistoryModeIcon,
    [AIActionType.Report]: AiBotReportIcon,
    [AIActionType.ExtraActionList]: AiExtraActionIcon,
};
