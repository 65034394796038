/* eslint-disable import/named */
import { NuxtAxiosInstance } from 'vue/types/vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AxiosRequestConfig } from 'axios';
import { CdnWithUrl, RequestSource, ResponseBase } from './base';

import axios from './request';
import { Feed } from './feed';

export enum AIFeedLiked {
    UnLike = 0,
    Like = 1,
}

export enum AIAuthorFollowing {
    Unfollowed = 0,
    Following = 1,
}

export enum AIFeedSlideScene {
    DiscoverScene = 0,
    ForyouScene = 1,
    BigCardScene = 2,
    MyProfileScene = 3,
}

interface AIForwardStatsParams {
    fid?: string;
    et?: string;
}

export interface AIFeed {
    bot_id_str?: string;
    bot_name?: string;
    bot_text?: string;
    bot_introduction?: string;
    head_url?: string;
    bg_url?: string;
    bot_first_line_url: string;
    bg_mp4_url?: string;
    cover_url?: string;
    feed?: Feed;
    ugc_uid?: string;
    compliance_mark_show?: boolean;
    // extra
    conversation_id?: string;
    index: number;
    liked?: AIFeedLiked;
    create_conversation_time_interval?: number;
    showTone?: boolean;

    /**
     * @zh 前端自定义记录，是否用户举报
     */
    is_bot_reported?: boolean;
}

/** *** Api  *** */
interface AIFeedListRequest {
    botId?: string;
    count?: number;
    request_source?: RequestSource;
    mobile?: boolean;
    tag?: string;
    offset?: number;
    scene?: number;
}

interface AIDiscoverFeedListRequest {
    tag?: string;
    request_source?: RequestSource;
    mobile?: boolean;
}

interface AIFeedListResponse extends ResponseBase {
    feeds?: Array<AIFeed>;
}

interface AIDicoverFeedListResponse extends ResponseBase {
    rawConfig: { feeds?: Array<AIFeed> };
}

interface ChangeAIFeedLikedStatusRequest {
    fromPage?: string;
    cancel?: boolean;
    botId?: string;
}

type ChangeAIFeedLikedStatusResponse = ResponseBase;

interface AIFollowActionRequest {
    fromPage?: string;
    toUid?: string | number; // 可以是 user_id, 也可以是 `@${kwai_id}`
    fType?: number;
    photoId?: string;
    noticeId?: string;
}

type AIFollowActionResponse = ResponseBase;

export class AIFeedApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        this.$axios = axios;
    }

    async getFeedList(data: AIFeedListRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<AIFeedListResponse>('/rest/o/w/pwa/aifeed/slides', data, config);

        return res;
    }

    async changeAIFeedLikedStatus(data: ChangeAIFeedLikedStatusRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<ChangeAIFeedLikedStatusResponse>('/rest/o/w/pwa/likeAction', data, config);

        return res;
    }

    async followAction(data: AIFollowActionRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<AIFollowActionResponse>('/rest/o/w/pwa/followAction', data, config);

        return res;
    }

    async getDiscoverFeedList(data: AIDiscoverFeedListRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<AIDicoverFeedListResponse>('/rest/o/w/pwa/aifeed/bots/config', data, config);
        return res;
    }
}

export const AIFeedApi = new AIFeedApiService();
