// eslint-disable-next-line import/no-extraneous-dependencies
import Vuex from 'vuex';
import { abTestModule, defaultFeedListPreLoadParams } from './modules/ab-test';
import { feedMoudle } from './modules/feed';
import { userModule } from './modules/user';
import { schemaModule } from './modules/schema';
import { isLogin } from '~/common/login';
import { conversationModule } from './modules/ai-conversation';
import { aiFeedMoudle } from './modules/ai-feed';
import { aiDiscoverMoudle } from './modules/ai-discover';
import { popupModule } from './modules/popup';
import { kConfModule } from './modules/kconf';
import { parseJsonSatefy } from '@/common/utils';
import { aiChatBigCardMoudle } from './modules/ai-chat-guide';
import { aiCreateMoudle } from './modules/ai-create';
import { KConfApi } from '@/api/kconf';
import { PWAKConfKey } from '@/constants/base';

const getKConf = async () => {
    try {
        const res = await KConfApi.getKConfByKey({
            paths: [PWAKConfKey],
        });
        const config = parseJsonSatefy<Record<string, any>>(res?.data?.[PWAKConfKey] || '{}');
        return config;
    } catch {
        return {};
    }
};

const createStore = () => {
    const store = new Vuex.Store({
        state: {
            startup: {},
            isLogin: false,
            videoList: [],
            clickedPicture: {},
            topicName: '',
            tgPraiseAmountList: [],
            host: '',
            /**
             * @zh 是否展示弹窗 - 引导用户打开通知的弹窗
             */
            showNotificationPopup: false,
            hasSwap: false,
            discoverAiContent: {},
            /**
             * @zh 是否完成桌面应用事件安装
             */
            deferredPromptInstalled: false,
        },
        mutations: {
            setStartup(state, data) {
                state.startup = data;
            },
            setIsLogin(state, data) {
                state.isLogin = data;
            },
            setVideoList(state, data) {
                state.videoList = data;
            },
            setClickedPicture(state, data) {
                state.clickedPicture = data;
            },
            setTopicName(state, data) {
                state.topicName = data;
            },
            setTgPraiseAmountList(state, data) {
                state.tgPraiseAmountList = data;
            },
            setHost(state, data) {
                state.host = data;
            },
            setShowNotificationPopup(state, data: boolean) {
                state.showNotificationPopup = data;
            },
            setHasSwap(state, data) {
                state.hasSwap = data;
            },
            setDiscoverAiContent(state, data) {
                state.discoverAiContent = data;
            },
            setDeferredPromptInstalled(state, data: boolean) {
                state.deferredPromptInstalled = data;
            },
        },
        actions: {
            // 当csr时 无法调用这个
            async nuxtServerInit({ commit }, ctx) {
                let redirectDuration = 0; // 重定向耗费时长
                if (!ctx.route?.fullPath.includes('/?standalone=true') && ctx.req?.headers?.['x-is-redirect'] === '1') {
                    // ?standalone = true 是在 sw 里面配置的请求，不需要重定向逻辑
                    const redirectStartTime = Number(ctx.req?.headers?.['x-redirect-start-time'] || 0);
                    const redirectEndTime = Number(ctx.req?.headers?.['x-redirect-end-time'] || 0);
                    redirectDuration = redirectStartTime ? redirectEndTime - redirectStartTime : 0;
                }

                const { $axios } = ctx;
                const [res, kconf] = await Promise.all([
                    $axios.$$post('overseaAPI::/rest/o/w/pwa/startup', {
                        shareDeviceId: ctx.route?.query?.share_device_id,
                    }),
                    getKConf(),
                ]);

                // 拦截国内ip
                if (res.result === 5002) {
                    ctx.redirect('/not-found');
                }

                if (res.result === 1) {
                    commit('setStartup', {
                        ...(res.data || {}),
                        redirectDuration,
                    });
                    const {
                        FetchFeedListDefaultCount,
                        HoldPreVideoCount,
                        NextFeedListMinCount,
                        NextPreLoadVideoCount,
                        isDropLaunchApp,
                        openPwaafterInstall,
                        if_open_guide_to_desktop,
                        install_pwa_no_limit,
                        install_pwa_touch,
                        open_guide_seconds,
                        cache_exp,
                        isNewIOSFeedStrategy,
                        web_fission_entry_2,
                        is_full_screen,
                        ai_ad_guide,
                        is_ai_full_screen,
                        ai_follow_tab_str,
                        ai_message_feedback_action,
                        a_chat_tab_entry,
                        auto_send_time_start,
                        auto_send_time_switch,
                    } = res.data || {};

                    commit('abTestModule/setABTestParams', {
                        FetchFeedListDefaultCount: Number(FetchFeedListDefaultCount || defaultFeedListPreLoadParams.FetchFeedListDefaultCount),
                        HoldPreVideoCount: Number(HoldPreVideoCount || defaultFeedListPreLoadParams.HoldPreVideoCount),
                        NextFeedListMinCount: Number(NextFeedListMinCount || defaultFeedListPreLoadParams.NextFeedListMinCount),
                        NextPreLoadVideoCount: Number(NextPreLoadVideoCount || defaultFeedListPreLoadParams.NextPreLoadVideoCount),
                        isDropLaunchApp: isDropLaunchApp === 'true',
                        openPwaafterInstall: Number(openPwaafterInstall || 5), // 秒
                        if_open_guide_to_desktop: if_open_guide_to_desktop === 'true',
                        install_pwa_no_limit: install_pwa_no_limit === 'true',
                        install_pwa_touch: install_pwa_touch === 'true',
                        open_guide_seconds: Number(open_guide_seconds || 5), // 秒
                        cache_exp: cache_exp === 'true',
                        isNewIOSFeedStrategy: isNewIOSFeedStrategy === '1',
                        web_fission_entry_2,
                        is_full_screen: is_full_screen === 'true',
                        is_ai_full_screen: is_ai_full_screen === 'true',
                        ai_guide_config: parseJsonSatefy(ai_ad_guide || '{}'),
                        ai_follow_tab: ai_follow_tab_str === 'true',
                        ai_message_feedback_action: ai_message_feedback_action === 'true',
                        a_chat_tab_entry: a_chat_tab_entry === 'true',
                        auto_send_time_start: Number(auto_send_time_start || 5),
                        auto_send_time_switch: auto_send_time_switch === 'true',
                    });
                    const { aiTabsConfig } = res.data || {};
                    commit('aiDiscoverMoudle/setDiscoverTabs', aiTabsConfig);
                    commit('kConfModule/setKConf', kconf || {});
                } else {
                    commit('setStartup', {
                        redirectDuration,
                    });
                }

                commit('setHost', ctx?.req?.headers?.host || '');
            },
            async getIsLogin({ commit }, ctx) {
                // const { $axios } = ctx
                const res = await isLogin();
                commit('setIsLogin', res);
            },
            // tg 打上金额列表
            async initTgPraiseAmounts({ commit, state }, ctx) {
                try {
                    const { $axios } = ctx;
                    const { result, errorMsg, data } = await $axios.$$post('https://m.kwai.com/rest/o/w/kconf/loggedOut', {
                        paths: ['overseaGrowth.newPushMessage.tgPayPraiseAmount'],
                    });
                    if (result === 1 && data?.['overseaGrowth.newPushMessage.tgPayPraiseAmount']) {
                        const tgPraiseAmountList = JSON.parse(data?.['overseaGrowth.newPushMessage.tgPayPraiseAmount']);
                        commit('setTgPraiseAmountList', tgPraiseAmountList);
                        console.log('tg miniApp: ', 'kconf fetch success');
                    }
                } catch (error) {
                    console.log(error);
                }
            },
        },
        modules: {
            abTestModule,
            feedMoudle,
            userModule,
            schemaModule,
            conversationModule,
            aiFeedMoudle,
            popupModule,
            kConfModule,
            aiDiscoverMoudle,
            aiChatBigCardMoudle,
            aiCreateMoudle,
        },
    });
    return store;
};
export default createStore;
