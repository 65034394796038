import { getCurrentInstance, onBeforeUnmount, onMounted } from 'vue';
import { AIChatApi } from '@/api/ai-chat';
import { generateUUID } from '@/common/utils';
import { AIMessageReadStatus, AIMessageStatus, AIMessageType } from '@/store/modules/ai-conversation';
import { checkIfAppEnvironment } from '@/common/app';

const PollingRequestInterval = 60 * 1000;

// eslint-disable-next-line import/prefer-default-export
export const useAiBotAutomaticMessage = () => {
    const { proxy } = getCurrentInstance() || {};
    // eslint-disable-next-line no-undef
    let timer: NodeJS.Timeout | null = null;

    const clearTimer = () => {
        if (timer) {
            clearInterval(timer);
        }
    };

    const fetchAiBotAutomaticMessage = async () => {
        try {
            const res = await AIChatApi.getBotAutomaticChat({});
            if (res?.data?.status !== 'ok') {
                return;
            }
            clearTimer();
            const botList = Object.keys(res?.data?.greetingList || {});
            botList.forEach(botId => {
                const botMessage = res?.data?.greetingList?.[botId] || {};
                if (!botMessage.gt) {
                    return;
                }
                proxy?.$store?.commit('conversationModule/storeMessage', {
                    botId,
                    message: `${botMessage.gt || ''}`,
                    messageId: generateUUID(),
                    type: AIMessageType.other,
                    messageReadStatus: AIMessageReadStatus.Unread,
                    messageStatus: AIMessageStatus.done,
                });
            });

            proxy?.$store?.commit('conversationModule/setHasAutomaticMessage', botList.length > 0);
        } catch {
            // do nothing
        }
    };

    const requestPolling = async () => {
        timer = setInterval(fetchAiBotAutomaticMessage, PollingRequestInterval);
    };

    onMounted(async () => {
        const isAppEnv = checkIfAppEnvironment();
        // 只对 ai 页面开启
        if (proxy?.$route?.path?.includes?.('/aichat') && proxy?.$store?.state?.abTestModule?.abTestParams?.a_chat_tab_entry && !isAppEnv) {
            requestPolling();
        }
    });

    onBeforeUnmount(() => {
        clearTimer();
    });
};
