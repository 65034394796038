
import {
    defineComponent,
    getCurrentInstance,
    reactive,
    toRefs,
    Ref,
    ref,
    computed,
    onMounted,
    onBeforeUnmount,
    onUnmounted,
    watch,
    nextTick,
    PropType,
} from 'vue';
import cookies from 'js-cookie';
import KwaiPlayerVue2, { KwaiPlayer } from '@ks-video/kwai-player-web/vue2-mini';
import { getQuery, generateUUID } from '@/common/utils';
import PlcPlugin from '@/common/videoPlcPlugin';
import { MobilePageSource, MobilePageSourceCodeMap } from '@/constants/base';
import { UserInfoByLogin } from '~/api/user';

KwaiPlayer.register(PlcPlugin);

type KwaiPlayerVue2Type = (typeof KwaiPlayerVue2)['methods'];

const uuid = generateUUID();
export default defineComponent({
    components: {
        KwaiPlayerVue2,
    },
    props: {
        src: {
            type: [Object, String],
            default: '',
        },
        preload: {
            type: String || undefined,
            default: undefined,
        },
        loop: {
            type: [Boolean],
            default: false,
        },
        playsinline: {
            type: [Boolean],
            default: false,
        },
        poster: {
            type: [String],
            default: '',
        },
        autoplay: {
            type: [Boolean],
            default: false,
        },
        muted: {
            type: [Boolean],
            default: false,
        },
        volume: {
            type: [Number],
            default: 1,
        },
        refKey: {
            type: [String, Number],
            default: '',
        },
        photoId: {
            type: [String, Number],
            default: '',
        },
        showInfoPanel: {
            type: [Boolean],
            default: getQuery()?.showInfoPanel === 'true' || false,
        },
        bizData: {
            type: Object as PropType<{ pageSource?: MobilePageSource; [key: string]: any }>,
            default: () => {},
        },
        index: {
            type: Number,
            default: 0,
        },
        controls: {
            type: Boolean,
            default: () => false,
        },
        plugins: {
            type: Object,
            default: () => {},
        },
    },
    setup(props, context) {
        // const propsData = useRef(props)
        const { proxy } = getCurrentInstance() as { proxy: any };
        const videoRef = ref(null);
        const key = ref(`render${props.index}`);
        const status = reactive({ enterTime: 0, leaveTime: 0, canPlay: false, shouldPlay: props.autoplay, hasResume: false });
        const pauseStart = ref(0);
        const pauseDuartion = ref(0);
        const playSrc = ref(props.src);
        const pluginsOption = ref({
            LoggerPlugin: {
                // 日志上报位置
                logUrl: 'https://logsdk.kwai-pro.com/rest/wd/common/log/collect/misc2?kpn=KWAI&v=3.10.16',
                productName: 'KWAI',
                env: 'oversea',
                // forceSendBeacon: false,
                forceSendBeacon: true,
            },
            PlcPlugin: false,
            PlayInfoPlugin: getQuery()?.showInfoPanel === 'true' || false,
            ...props.plugins,
        });

        const bizDataAndUserId = computed(() => {
            const { pageSource } = props?.bizData || {};
            const userInfo = (proxy?.$store?.state?.userModule?.userInfo || {}) as UserInfoByLogin;
            const { user, userId, user_id } = userInfo;
            const uid = userId || user_id || user?.user_id || cookies.get('userId');
            return {
                ...props.bizData,
                userId: uid,
                device_id: cookies.get('webDid') || `random_${uuid}`,
                page_code: pageSource ? MobilePageSourceCodeMap[pageSource] : undefined,
                photo_id: `${props.photoId || ''}`,
                url: window?.location?.href || '',
            };
        });
        const onEnter = () => {
            console.log('onEnter');
            status.enterTime = Date.now();
        };
        const onLeave = () => {
            console.log('onLeave');
            status.leaveTime = Date.now();
            sendVideoStatLog({});
        };
        // 业务主动终止网络请求
        const abortRequest = () => {
            playSrc.value = '';
        };
        const assignRequest = () => {
            playSrc.value = props.src;
        };

        const sendVideoStatLog = (logParams: any) => {
            const instance = videoRef.value as any;
            const videoStatus = instance?.getAudienceStat() ?? {};
            const playedDuration = status.leaveTime - status.enterTime - pauseDuartion.value;
            // 字段来源与含义：https://docs.corp.kuaishou.com/k/home/VCruMNt_APag/fcAC66-Y5rQIuqzzozDmyQGDI
            const {
                creation_time,
                stream_duration: durationTime,
                //  play_duration: playedDuration,
                metadata_time: prepareTime,
                playing_start_time: beginPlayTime,
            } = videoStatus;
            if (status.enterTime === 0) return;
            status.leaveTime = 0;
            status.enterTime = 0;
        };

        function resumePlayListener() {
            console.log('resumePlayListener: ');
            console.log('status.shouldPlay: ', status.shouldPlay);
            status.hasResume = true;
        }
        // window.addEventListener('visibilitychange', () => {
        //   console.log('visibilitychange: ');
        // });
        onMounted(() => {});
        // 实测这里终止请求无效
        // onBeforeUnmount(()=>{
        //   playSrc.value = ''
        // })
        const onClick = (e: Event) => {
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('click', e, props.refKey);
        };

        const onPlaying = (e: Event) => {
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('playing', e, props.refKey);
        };
        const onPlay = (e: Event) => {
            if (pauseStart.value) {
                pauseDuartion.value += Date.now() - pauseStart.value;
                pauseStart.value = 0;
            }
            console.log('play', e, props.refKey, props.index);
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('play', e, props.refKey);
        };
        const onWaiting = (e: Event) => {
            console.log('onWaiting', e, props.refKey, props.index);
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('waiting', e, props.refKey);
        };
        const onError = (e: any) => {
            console.log('player-error', e, props.refKey, props.index, props.bizData.pageSource, props.photoId);
            const playedDuration = status.leaveTime - status.enterTime - pauseDuartion.value;
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('error', e, props.refKey);
        };
        const onEnded = (e: Event) => {
            console.log('ended', e, props.refKey, props.index);
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('ended', e, props.refKey);
            onLeave();
        };

        const onPause = (e: Event) => {
            pauseStart.value = Date.now();
            console.log('pause', e, props.refKey);
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('pause', e, props.refKey);
            if (status.shouldPlay && status.hasResume) {
                retryPlay();
            }
        };

        /**
         * @en video event order
         * loadstart => durationchange => loadedmetadata => loadeddata => progress => canplay => canplaythrough
         */
        const onLoadStart = (e: Event) => {
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('loadstart', e, props.refKey);
        };
        const onDurationChange = (e: Event) => {
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('durationchange', e, props.refKey);
        };
        const onLoadedMetaData = (e: Event) => {
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('loadedmetadata', e, props.refKey);
        };
        const onLoadedData = (e: Event) => {
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('loadeddata', e, props.refKey);
        };
        const onProgress = (e: Event) => {
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('progress', e, props.refKey);
        };
        const onCanPlay = (e: Event) => {
            status.canPlay = true;
            status.enterTime = Date.now();
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('canplay', e, props.refKey);
        };
        const onCanPlayThrough = (e: Event) => {
            // eslint-disable-next-line vue/require-explicit-emits
            context.emit('canplaythrough', e, props.refKey);
        };

        const retryPlay = () => {
            status.hasResume = false;
            const video = videoRef.value! as KwaiPlayerVue2Type;
            video?.play();
        };
        const play = () => {
            status.shouldPlay = true;
            const video = videoRef.value! as KwaiPlayerVue2Type;
            video?.play();
        };
        const pause = () => {
            status.shouldPlay = false;
            status.hasResume = false;
            const video = videoRef.value! as KwaiPlayerVue2Type;
            video?.pause();
        };
        const getPaused = () => {
            const video = videoRef.value! as KwaiPlayerVue2Type;
            return video?.getPaused();
        };

        const getDuration = () => {
            const video = videoRef.value! as KwaiPlayerVue2Type;
            return video?.getDuration();
        };

        const getCurrentTime = () => {
            const video = videoRef.value! as KwaiPlayerVue2Type;
            return video?.getCurrentTime();
        };

        const setCurrentTime = (time: number) => {
            if (typeof time !== 'number') {
                return;
            }
            const video = videoRef.value! as KwaiPlayerVue2Type;
            video?.setCurrentTime?.(time);
        };

        return {
            status,
            pluginsOption,
            videoRef,
            playSrc,
            bizDataAndUserId,
            play,
            pause,
            onEnter,
            onLeave,
            sendVideoStatLog,
            onClick,
            onWaiting,
            onPlay,
            onPlaying,
            onError,
            onEnded,
            onPause,

            onLoadStart,
            onDurationChange,
            onLoadedMetaData,
            onLoadedData,
            onProgress,
            onCanPlay,
            onCanPlayThrough,

            getPaused,
            abortRequest,
            assignRequest,
            key,
            getDuration,
            getCurrentTime,
            setCurrentTime,
        };
    },
});
