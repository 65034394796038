/* eslint-disable import/no-extraneous-dependencies */
import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import { AxiosInstance } from 'axios';
import cookies from 'js-cookie';

interface AppConfigFromCookie {
    /**
     * @zh App 包名
     */
    pkg?: string;
    /**
     * @zh 设备ID
     */
    did?: string;
    /**
     * @zh app 版本号
     */
    appver?: string;
    /**
     * @zh 是否安装 kwai
     */
    installed_kwai?: string;
    /**
     * @zh 系统语言 language
     */
    language?: string;
    /**
     * @zh 手机状态栏高度
     */
    status_bar_height?: string;
    /**
     * @xq 是否是iOS
     */
    isIOS?: string;
}

declare module '@nuxt/types' {
    interface Context {
        readonly $languageCode: string;
        readonly $languageOnly: string;
        readonly $ua: string;
        readonly $platform: string;
        readonly $countryCode: string;
        readonly $isRtl: boolean;
        readonly $isSnack: boolean;
        readonly $isWeeeye: boolean;
        readonly $host: string;

        /**
         * @zh App Factory 相关
         */
        readonly $appConfig: AppConfigFromCookie;
        readonly $isAppEnvironment: boolean;
        readonly $pwaLayout: 'default' | 'app';
    }
    interface NuxtAxiosInstance {
        $$post: any;
        $$get: any;
    }
}
declare module 'vue/types/vue' {
    interface Vue {
        readonly $languageCode: string;
        readonly $languageOnly: string;
        readonly $ua: string;
        readonly $platform: string;
        readonly $countryCode: string;
        readonly $isInChina: boolean;
        readonly $i18n: any;
        readonly $isRtl: boolean;
        readonly $isSnack: boolean;

        /**
         * @zh App Factory 相关
         */
        readonly $appConfig: AppConfigFromCookie;
        readonly $isAppEnvironment: boolean;
        readonly $pwaLayout: 'default' | 'app';
    }
    interface NuxtAxiosInstance {
        $$post: <T extends { result?: number }>(...args: Parameters<AxiosInstance['post']>) => Promise<T>;
        $$get: <T extends { result?: number }>(...args: Parameters<AxiosInstance['get']>) => Promise<T>;
    }
}

function getLanguageCode({ i18n }: Context) {
    return i18n.localeProperties.code;
}

export function getIsRtl({ $languageCode }: Context) {
    return ['ar', 'ur', 'ps', 'he', 'fa'].includes($languageCode);
}

function getUserAgent({ req }: Context) {
    return (process.server ? req.headers['user-agent'] : navigator.userAgent) || '';
}

function getIsSnack(context: Context) {
    if (process.server) {
        return context?.req?.headers?.host?.includes('snack') || false;
    }
    return window.location.host.includes('snack');
}

function getIsWeeeye(context: Context) {
    if (process.server) {
        return context?.req?.headers?.host?.includes('weeeye') || false;
    }
    return window.location.host.includes('weeeye');
}

function getPlatform(ctx: Context) {
    // 获取平台
    const ua = getUserAgent(ctx);
    // 检测设备的类型，首先判断是否是移动端设备
    let platform = null;
    if (/mobile|android|iphone|ipad|phone/i.test(ua)) {
        if (/iphone|ipad/i.test(ua)) {
            platform = 'ios';
        } else {
            platform = 'android';
        }
    }
    return platform;
}

function parseCookiesInServer<T extends Record<string, any>>(cookieString?: string) {
    try {
        return (cookieString || '').split(';').reduce<T>((res, item) => {
            const [name, value] = item.split('=').map(c => c.trim());
            return {
                ...res,
                [name]: value,
            };
        }, {} as T);
    } catch {
        return {} as T;
    }
}

function getAppConfigFromCookie(context: Context) {
    try {
        if (process.server) {
            return parseCookiesInServer<AppConfigFromCookie>(context?.req?.headers?.cookie || '');
        }
        return cookies.get();
    } catch {
        return {};
    }
}

export default async (context: Context, inject: Inject) => {
    const languageCode = getLanguageCode(context);
    const isSnack = getIsSnack(context);
    const host = process.server ? context?.req?.headers?.host : window.location.host;
    const appConfig = getAppConfigFromCookie(context);
    const isAppEnvironment = !!appConfig?.pkg && !!appConfig?.appver;
    /**
     * @zh 小包不考虑在内
     */
    const pwaLayout = isAppEnvironment ? 'app' : 'default';

    globalThis && ((globalThis as any).__PWA_NUXT_CTX__ = context);

    inject('host', host || '');
    inject('languageCode', languageCode);
    inject('platform', getPlatform(context));
    inject('isRtl', getIsRtl(context));
    inject('isSnack', getIsSnack(context));
    inject('appName', isSnack ? 'Snack' : 'Kwai');
    inject('appConfig', appConfig);
    inject('isAppEnvironment', isAppEnvironment);
    inject('pwaLayout', pwaLayout);
    inject('isWeeeye', getIsWeeeye(context));
};
