import { AIConversationModelImpl } from '../models/UIAIConversationModel';
import { AIConversationStore } from '../stores/AIConversationStore';
import getDB from '../utils/index';
import { AIConversationModel, AIMessageModel, AIUserID } from '../models/AIModel';
import generateUUID from '../utils/aiTools';
import { UIAIStore } from '../stores/UIAIStore';

export class AIConversationService {
    private conversationStore: AIConversationStore;

    private currentConversationId?: string;

    constructor(conversationStore: AIConversationStore) {
        this.conversationStore = conversationStore;
    }

    async addConversation<T>(participants: AIUserID[], updatedAt: Date, attachment?: T) {
        const conversationId = this._createC2CConversationId(participants?.shift());
        const conversation: AIConversationModel = new AIConversationModelImpl(conversationId, participants, updatedAt, attachment);
        await this.conversationStore.addConversation(conversation);
        console.log('social-ai-im', `create new conversation with ID: ${conversationId}`);
        return conversation;
    }

    async switchConversation<T>(conversationId: string, participants: string[], updatedAt: Date, attachment: T): Promise<AIConversationModel> {
        let conversation = this.conversationStore.getConversationById(conversationId);
        if (conversation) {
            console.log('social-ai-im', `Switched to conversation with ID: ${conversationId}`);
            this.currentConversationId = conversation.id;
            return conversation;
        }
        // create new conversation
        conversation = await this.addConversation<T>(participants, updatedAt, attachment);
        this.currentConversationId = conversation.id;
        console.log('social-ai-im', `Created new conversation with ID: ${conversation.id}`);
        return conversation;
    }

    getConversations(): AIConversationModel[] {
        return this.conversationStore.getConversations();
    }

    getConversation(conversationId: string) {
        const conversation = this.conversationStore.getConversationById(conversationId);
        return conversation;
    }

    async updateLastMessage(conversationId: string, lastMessage: AIMessageModel) {
        const conversation = this.conversationStore.getConversationById(conversationId);
        if (conversation) {
            conversation.lastMessage = lastMessage;
            conversation.updatedAt = new Date();
            this.conversationStore.updateConversation(conversation);
        }
    }

    async clearHistoryMessage(conversationId: string): Promise<boolean> {
        const db = await getDB();
        return new Promise((resolve, reject) => {
            const tx = db.transaction(['messages'], 'readwrite');
            const store = tx.objectStore('messages');
            const index = store.index('conversationId');
            const request = index.getAll(conversationId);
            request.onsuccess = async event => {
                const { result } = event.target as IDBRequest;
                const messages = result as any[];
                if (messages.length > 0) {
                    messages.forEach(async message => {
                        store.delete(message.id);
                    });
                    console.log(`Cleared history messages for conversation with ID: ${conversationId}`);
                    resolve(true);
                }
            };
            request.onerror = event => {
                console.error(`Failed to clear history messages for conversation with ID: ${conversationId}`, event);
                reject(request.error);
            };
        });
    }

    getcurrentConversationId() {
        return this.currentConversationId;
    }

    getConversationIdByParticipants(participants: string[]) {
        return this._createC2CConversationId(participants?.shift());
    }

    private _createC2CConversationId(userId?: string) {
        return `C2C${userId || generateUUID()}`;
    }

    private _createGroupConversationId(userId?: string) {
        return `GROUP${userId || generateUUID()}`;
    }
}

export const UIAIConversationService = new AIConversationService(UIAIStore.conversationStore);
